@import 'styles/common/variables';

.theme_switcher {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	gap: 10px;
	font-weight: 600;
	font-size: 12px;
	font-family: 'Segoe UI', sans-serif;
	color: $colors-white;
	word-break: break-word;
	width: 130px;

	&:hover,
	&:focus {
		color: rgb(235, 232, 232);
	}
	&:active {
		color: rgb(219, 214, 214);
	}
}
