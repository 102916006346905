@import 'styles/common/variables';

.header {
	position: fixed;
	z-index: 21;
	width: 100%;
	top: 0;

	max-height: 38px;
	padding: 3px 0 3px;

	background-color: $colors-white;
	border: 1px solid #c4c4c4;
	display: flex;
	align-items: center;

	@media screen and (min-width: $tablet) {
		max-height: 52px;
	}
}
.content {
	margin-top: -14px;

	@media screen and (min-width: $tablet) {
		margin-top: 0;
	}
}

.dark {
	background-color: $colors-bg-dark;
	border: 1px solid $colors-bg-dark;
}
