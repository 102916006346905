@import 'styles/common/_breakpoints';

.card__wrapper {
	width: 926px;
	margin: 0 auto;
	position: relative;
	margin-bottom: 25px;
	min-height: 470px;
	&:last-child {
		margin-bottom: 0;
	}
}

.adaptiveContainer {
	display: flex;

	justify-content: space-between;
	flex-direction: row-reverse;
}
.subCard {
	display: flex;
	font-family: 'Segoe UI';
	font-style: normal;
}

.button_container {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 178px;
	height: 152px;
	margin-right: 4px;

	background-color: #dadcea;
	border-radius: 0 0 17px 17px;
}

.wrapperWaitForPayment {
	position: relative;
}
// Response
@media (max-width: 934px) {
	.card__wrapper {
		min-height: 570px;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		.adaptive_menu {
			background-color: #fff;
			height: 275px;
		}
	}
	.adaptiveContainer {
		position: absolute;
		bottom: 80%;
		left: 50%;
		transform: translate(-50%, -50%);
		gap: 2rem;
	}
	.subCard {
		gap: 2rem;

		width: 100%;
	}
	.button_container {
		width: 180px;
		height: 120px;
	}
}
@media (max-width: 764px) {
	.card__wrapper {
		margin: 1rem 0 0 0;
	}
	.subCard {
		gap: 1rem;
	}
	.adaptiveContainer {
		bottom: 80.5%;
	}
}
@media (max-width: $desktop-width-main) {
	.card__wrapper {
		height: 1200px;
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.adaptiveRevievBlock {
		width: 257px;
		height: 245px;
		border: 1px solid green;
	}
	.adaptiveContainer {
		bottom: 100%;
		width: 100%;
		height: 100%;
		//border: 1px solid blue;
	}
	.subCard {
		flex-direction: column;
		align-items: center;
	}
	.wrapperWaitForPayment {
		margin: 0 auto;
	}
}

@media (min-width: 617px) {
	.adaptiveRevievBlock {
		display: none;
	}
}
