.container {
  width: 320px;
  height: 460px;
  font-family: 'Segoe UI';
  font-style: normal;
  background: #ffffff;
  border-radius: 3px;
  padding: 70px 24px 50px;
  position: relative;
}

.button_popover {
  position: absolute;
  top: 14px;
  left: 100px;
  opacity: 1;
  width: 123px;
  height: 35px;
  display: flex;
  align-items: center;
  padding: 6px 9px;
  background: #535252;
  border-radius: 5px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
  text-shadow: 0px 0px 5.20755px rgba(0, 0, 0, 0.25);

  &__number {
    flex-shrink: 0;
    font-size: 17px;
    line-height: 20px;
  }

  &__title {
    display: block;
    font-size: 12px;
    line-height: 89.19%;
  }

  img {
    width: 19px;
    height: 15px;
    margin-right: 5px;
    margin-left: 8px;
  }
}

.title {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #4c4c4c;
  margin-bottom: 17px;
}

.text {
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #4c4c4c;
  margin-bottom: 17px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeBtn{
  width: 134px;
  height: 28px;
  background: #0090ff;
  border: none;
  border-radius: 16px;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.go_back_bnt {
  position: absolute;
  top: 5.5px;
  left: 17px;
  width: 45px;
  height: 45px;

  &:hover,
  &:active {
    background: #ededed;
  }
}
