%text-tiny {
  font-family: $font-segoe;
  font-size: 10px;
  line-height: 1.3;
}

%text-tiny-semibold {
  font-family: $font-segoe;
  font-size: 10px;
  font-weight: 600;
  line-height: 1.3;
}

%text-thin-mid {
  font-family: $font-segoe;
  font-size: 11px;
  line-height: 1.36;
}

%text-small {
  font-family: $font-segoe;
  font-size: 12px;
  line-height: 1.33;
}

%text-small-semibold {
  font-family: $font-segoe;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
}

%text-small-bold {
  font-family: $font-segoe;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.33;
}

%text-mid-bold {
  font-family: $font-segoe;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.36;
}

// %text-normal {}

// %text-bold {}

%text-label-positioned {
  font-family: $font-segoe;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.36;
}

%text-input {
  font-family: $font-segoe;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.3;
}

%text-input-mid {
  font-family: $font-segoe;
  font-size: 15px;
  line-height: 1.33;
}

%text-title {
  font-family: $font-segoe;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3125;
}

%text-button {
  font-family: $font-segoe;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.33;
}

%text-countdown {
  font-family: $font-segoe;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3125;
}

// %text-black {}
