@import 'styles/common/variables';
@import '../../Parts/optionButton';
@import 'styles/common/breakpoints';

.currencyOptionButton {
	@extend %option-button;

	width: 172px;
	height: 31px;
	@media screen and (max-width: $pre-tablet) {
		padding: 6px 13px 8px 13px;
		max-width: 160px;
	}
}
