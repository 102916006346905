.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  white-space: nowrap;

  pointer-events: none;
  // visibility: hidden;
  border: 0;
  opacity: 0;
}
