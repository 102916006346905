.payment__container {
  position: relative;
  width: 320px;
  min-height: 465px;
  padding-top: 22px;
  padding-left: 8px;
  padding-right: 1px;
  padding-bottom: 22px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.payment__timer {
  width: 110px;
  height: 110px;

  background: #d9d9d9;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto 39px;

  &.active {
    border: 3px solid #6aff79;
  }

  &_number {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    color: #ffffff;
  }
}

.paymentThanksComponent {
  &__title {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;

    color: #4c4c4c;
  }

  &__subtitle {
    @extend .paymentThanksComponent__title;
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 70px;
  }

  &__text {
    position: relative;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;

    color: #4c4c4c;
    padding: 0 10px;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 5px;
      right: 125px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #ff6000;
    }
  }
}

.text {
  &_item {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;

    color: #4c4c4c;
    list-style: disc;
    margin-bottom: 24px;
    margin-top: 37px;
    margin-left: 56px;
    margin-right: 42px;
    text-align: justify;

    &:last-child {
      margin-bottom: 0;
    }

    &.text_item_accent {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      margin-top: 0;
      margin-right: 14px;
      margin-left: 54px;
      text-align: left;
    }
  }

  &_container {
    padding: 55px 0;
    width: 320px;
    height: 336px;

    background: #ffffff;
    border-radius: 3px;
  }

  &_closebnt {
    position: absolute;
    top: 5.5px;
    left: 17px;
    width: 45px;
    height: 45px;

    &:hover,
    &:active {
      background: #ededed;
    }
  }
}
