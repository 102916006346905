.buttons_style {
	width: 109px;
	height: 33px;
	background: #ffffff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	border-radius: 2px;
	border: none;

	text-align: center;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
	color: #0090ff;
	&:hover {
		color: rgba(0, 144, 255, 0.75);
	}
	&:active {
		color: rgba(0, 144, 255, 0.6);
	}
}

.delete_button {
	width: 14px;
	height: 15px;
	position: relative;
	background-color: transparent;
	border: none;
	padding: 0;
	font-size: 16px;
}

.delete_button::before,
.delete_button::after {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 1px;
	background-color: #4c4c4c;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 10px;
	}
}

.delete_button::before {
	transform: translate(-50%, -50%) rotate(45deg);
}

.delete_button::after {
	transform: translate(-50%, -50%) rotate(-45deg);
}
