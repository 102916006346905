@import '/src/styles/common/breakpoints';

.description__section {
	padding: 15px 20px 15px;
	width: 100%;

	background-color: #ffffff;
	border: 0.5px solid #000000;
	box-sizing: border-box;
	border-radius: 2px;
	margin-bottom: 17px;
	font-weight: 450;

	@media screen and (min-width: $tablet) {
		padding: 15px 13px 16px 27px;
		width: 439px;
		height: 219px;
		margin-right: 44px;
	}

	@media screen and (min-width: $desktop) {
		padding: 28px 22px 25px 27px;
		width: 527px;
		margin-right: 30px;
	}
}

.description__text {
	font-family: 'Segoe UI';
	font-size: 9px;
	line-height: 12px;

	color: #4c4c4c;

	@media screen and (min-width: $tablet) {
		font-size: 11px;
		line-height: 15px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 12px;
		line-height: 16px;
	}
}

.description__block {
	display: flex;
	margin-top: 15px;
	justify-content: space-between;

	@media screen and (min-width: $tablet) {
		justify-content: flex-start;
	}
}

.description__paragraf {
	font-family: 'Segoe UI';
	font-size: 9px;
	line-height: 12px;

	color: #4c4c4c;
	width: 140px;

	@media screen and (min-width: $tablet) {
		width: 195px;
		font-size: 11px;
		line-height: 15px;
		margin-right: 60px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 12px;
		line-height: 16px;
		width: 220px;
		margin-right: 24px;
	}
}
strong {
	font-weight: 600;
}
.description__btnBlock {
	@media screen and (min-width: $desktop) {
		display: flex;
	}
}

.description__btn {
	display: block;
	padding: 10px 6px 7px 6px;
	width: 100px;
	height: 35px;

	background-color: #ff6000;
	border-radius: 3px;
	border: none;

	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 10px;
	line-height: 14px;

	color: #ffffff;

	cursor: pointer;

	&:first-child {
		margin-bottom: 10px;
	}

	&:hover {
		background: rgba(255, 96, 0, 0.9);
	}

	&:focus {
		background: rgba(255, 96, 0, 0.75);
	}

	@media screen and (min-width: $desktop) {
		&:first-child {
			margin-bottom: 0px;
			margin-right: 27px;
		}
	}
}
