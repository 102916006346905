@import 'styles/common/variables';

.subheaderHotelier {
	display: flex;
	justify-content: flex-end;
	gap: 16px;

	@media screen and (min-width: $tablet) {
		gap: 21px;
	}
}

.subheaderHotelierCard {
	position: relative;
}

.hotelCardButton {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $colors-black;

	@media screen and (min-width: $tablet) {
		min-width: 163px;
		padding: 3px 23px 3px 10px;
	}

	background-color: #ffffff;
	border: 1px solid transparent;
	border-radius: 4px;

	&:hover {
		border-color: #d0d0d0;
	}

	&.open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-color: #d0d0d0;
		border-bottom-color: transparent;

		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
	}

	&:focus {
		border-color: #d0d0d0;
	}

	& > :last-child {
		align-self: flex-end;
		margin-bottom: 3px;
		@media screen and (max-width: $pre-tablet) {
			margin-bottom: 0;
			padding: 2px;
		}
	}
}

.hotelMetaDesktop {
	display: flex;
	gap: 10px;
}

.hotelMetaMobile {
	@extend .hotelMetaDesktop;
}

.houseWithChimney {
	color: $colors-black;
}

.hotelierSettingsDropdownOverlay {
	position: absolute;
	z-index: 2;

	@media screen and (max-width: $pre-tablet) {
		top: 105%;
		left: -62px;
	}

	@media screen and (min-width: $tablet) {
		top: 98%;
		left: 0;
	}

	display: flex;
	flex-direction: column;

	width: 100%;
	min-width: 208px;

	background-color: $colors-white;

	border: 1px solid #d0d0d0;
	border-top: none;

	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;

	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.hotelierMobileIcon {
	width: 20px;
	height: 20px;
}

.hotelCardMobileWrapper {
	display: flex;
	gap: 4px;

	padding: 2px 4px;
	align-items: flex-end;
	justify-content: space-between;
}

.dark {
	color: $colors-white;
	background-color: $colors-bg-dark;
	border: none;
	&:hover {
		border: none;
	}
}
