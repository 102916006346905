@import 'styles/common/variables';

.personWrapper {
	text-align: start;
}

.userName {
	@extend %text-small;
	color: $colors-black;
	display: block;
	width: 130px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.karmaNumber {
	@extend %text-small;
	font-weight: 700;
	color: $colors-brand-accent;
}

.bookingKarma {
	@extend %text-small;
	font-weight: 400;
	color: $border-table;
}

.dark {
	background-color: $colors-bg-dark;
	color: $colors-white;
}
