@import '/src/styles/common/breakpoints';

.container {
	width: 100%;
	padding-top: 30px;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $desktop) {
		padding-bottom: 60px;
	}
}

// .test1_div{
//       min-width: 1200px;
//       width: 100%;
//       height: 200px;
// }

.btn__fetch {
	display: flex;
	margin: 0 auto;
	justify-content: center;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	line-height: 1;
	border-radius: 500px;
	transition-property: background-color, border-color, color, box-shadow, filter;
	transition-duration: 0.3s;
	border: 1px solid transparent;
	letter-spacing: 2px;
	min-width: 160px;
	text-transform: uppercase;
	white-space: normal;
	font-weight: 700;
	padding: 16px 14px 18px;
	color: #616467;
	box-shadow: inset 0 0 0 2px #616467;
	background-color: transparent;
	height: 48px;
	&:hover {
		color: #fff;
		background-color: #616467;
	}
}
