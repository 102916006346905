.burgerWrapper {
	position: relative;
	bottom: 1px;

	display: flex;
	align-items: center;
	justify-content: flex-end;

	margin-left: auto;
	background-color: inherit;
}

.posAbsolute {
	position: absolute;
	top: 100%;
	right: 0;

	height: 100%;

	display: flex;
	align-items: center;
}
