.btn_close {
  width: 52px;
  height: 52px;
  border: none;
  outline: none;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 10px;
  left: 18px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: #ededed;
  }
}
