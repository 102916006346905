.content_left_description {
	width: 471px;
	min-height: 146px;
	border-radius: 10px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	font-weight: 400;
	padding: 15px;
	line-height: 16px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #000000;
	background: #ffffff;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 249px;
		height: 100%;
	}
}
