@media (max-width: 1199px) {
	.modalOverlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

	.modal {
		padding: 20px;
		overflow-y: auto;
		max-height: calc(100% - 40px);

		@media (min-width: 320px) and (max-width: 767px) {
			max-width: 320px;
			padding-left: 0;
			padding-right: 0;
			overflow-x: hidden;
		}
	}

	.wrapVideoImg {
		display: flex;
		gap: 4px;
		margin-bottom: 13px;

		@media (min-width: 320px) and (max-width: 767px) {
			flex-direction: column;
			gap: 9px;
		}
	}

	.mobContainer {
		@media (min-width: 320px) and (max-width: 767px) {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	.wrapBtnClose {
		position: relative;
	}
	
	.btnCloseModal {
		width: 23px;
		height: 23px;
		position: absolute;
		top: 0;
		right: 15px;
		background-color: transparent;
		border: none;
		padding: 0;
		font-size: 16px;
		@media (min-width: 320px) and (max-width: 767px) {
			top: -105%;
			right: 11px;
		}
	}

	.btnCloseModal::before,
	.btnCloseModal::after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 1px;
		background-color: #ffffff;
	}

	.btnCloseModal::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	.btnCloseModal::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}

	.wrapRatingPay {
		display: flex;
		width: 599px;
		margin: 0 auto;
		margin-top: 17px;
		margin-bottom: 70px;
		justify-content: space-between;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 320px;
			flex-direction: column;
			margin-bottom: 19px;
		}
	}

	.payTagsWrap {
		display: flex;
		flex-direction: column;
	}

	.DetailsBlock_idTag {
		font-family: 'Segoe UI', sans-serif;
		font-weight: 600;
		font-size: 11px;
		text-align: center;
		color: #4c4c4c;
		margin-bottom: 10px;
	
		box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
		background: #fff;
		border-radius: 3px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.totalSq {
		width: 176px;
		display: flex;
		gap: 25px;
		align-items: center;
		margin-top: 8px;
		margin-bottom: 15px;

		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;

		color: #4c4c4c;
	}

	.wrapContent {
		width: 597px;
		margin: 0 auto;
		position: relative;
		margin-top: 20px;
		border-radius: 10px;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		font-size: 12px;
		font-weight: 400;
		padding: 15px;
		line-height: 16px;
		box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
		color: #4c4c4c;
		background: linear-gradient(267.96deg, #b0d1ea -4.2%, #ebf3f9 90.36%);

		@media (min-width: 320px) and (max-width: 767px) {
			width: 277px;
		}
	}

	.galleryOptions {
		display: flex;
		@media (min-width: 320px) and (max-width: 767px) {
			display: flex;
			flex-direction: column-reverse;
		}
	}


	.wrapBedOptions {
		display: flex;
		gap: 12px;
		margin-top: 92px;
		margin-bottom: 12px;

		@media (min-width: 320px) and (max-width: 767px) {
			margin-top: 9px;
		}
	}
}
