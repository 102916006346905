@import 'styles/common/variables';

.theme_switcher {
	display: flex;
	justify-content: flex-start;
	align-items: center;

	gap: 10px;
	padding: 10px 6px;

	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	font-family: 'Segoe UI', sans-serif;
	color: $colors-text-primary;
	word-break: break-word;

	&:hover,
	&:focus,
	&:active {
		font-weight: 600;
		color: $colors-white;
		background-color: $colors-text-accent;
	}
}

.theme_switcher.dark {
	color: $colors-white;
	background-color: $colors-bg-dark;
	&:hover,
	&:focus,
	&:active {
		background-color: $colors-text-accent;
	}
}

img.light {
	background-color: $colors-text-primary;
}
