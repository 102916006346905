@import 'styles/common/variables';

%subheader-hotelier-catalog {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  gap: 20px;

  @media screen and (min-width: $tablet) and (max-width: $pre-desktop) {
    margin-left: 10px;
    gap: 31px;
  }

  @media screen and (min-width: $desktop) {
    gap: 68px;
  }
}

%location-card {
  display: flex;
  align-items: center;
  gap: 11px;

  @media screen and (min-width: $tablet) {
    gap: 21px;
  }

  @media screen and (min-width: $desktop) {
    gap: 21px;
    min-width: 260px;
  }
}

%image-thumb {
  position: relative;
  width: 41px;
  height: 31px;
}

%location-img {
  width: 41px;
  height: 36px;

  position: absolute;
  top: -4px;

  filter: drop-shadow(0px 4px 4px $black-transparent-quarter);
  border-radius: 3px;

  @media screen and (min-width: $tablet) {
    top: -9px;

    width: 52px;
    height: 46px;
  }
}

%text-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding-bottom: 4px;
}

%city-and-country {
  font-family: $font-segoe;
}

%city {
  margin-right: 7px;

  font-size: 13px;
  line-height: 1.3;
  color: $colors-city;

  @media screen and (min-width: $tablet) {
    font-size: 21px;
    line-height: 1;
  }
}

%country {
  font-size: 11px;
  line-height: 1.33;
  color: $colors-country;

  @media screen and (min-width: $tablet) {
    font-size: 14px;
  }
}

%rent-option {
  min-width: 112px;
  max-width: 136px;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  font-weight: 600;
  font-size: 10px;
  line-height: 1.04;
  font-family: $font-segoe;
  color: $colors-city;

  @media screen and (min-width: $tablet) {
    flex-grow: 1;
    min-width: 169px;
    max-width: 248px;
    font-size: 15px;
  }
}
