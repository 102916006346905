@import 'styles/common/breakpoints';
$cubic: cubic-bezier(0.4, 0, 0.2, 1);

.modal__optionalRoom_wrapper {
	position: absolute;
	bottom: -100px;
	left: 50%;
  transform: translateX(-50%);
  border: 5px solid #fff;

  @media screen and (min-width: $tablet) {
		top: 0;
    left: 0;
    bottom: auto;
    transform: translateX(-105%);
	}
}

.modal__bed_disabled_block {
  height: 100%;
  background-color: rgb(81, 81, 81, 0.5);
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}

.modal__optionalRoom_list {
	display: flex;
}

.hotelier_roms__box_icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.hotelier_roms__box_bed_img {
	position: relative;
	background: #d4ebfb;
}

.hotelier_roms__box_number_room {
	width: 46px;
	height: 17px;
	position: absolute;
	top: 0;
	left: 0;
	background: #ffffff;
	box-shadow: 0px 0px 3.45395px 0.69079px rgba(0, 0, 0, 0.3);
	border-radius: 2.76316px;
}

.hotelier_roms__number_room {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 1.36;
	text-align: center;
	color: #4c4c4c;
}

.hotelier_roms__item {
	max-width: 240px;
	border-radius: 5px;
	background-color: #fff;
	list-style: none;
}
.hotelier_roms__item_hover {
	&:hover,
	&:focus {
		outline: 1px solid #6aff79;
	}
}

.hotelier_roms__item_active {
	z-index: 30;
	height: 82px;
	position: absolute;
	top: 40px;
	left: 50%;
	transform: translateX(-50%);
}
