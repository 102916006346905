@import 'styles/common/breakpoints';
$color-text: #4c4c4c;
$color-not-active-day: rgba(174, 175, 188, 0.5);
$color-active-day: #ffffff;
$colors-brand: #0090ff;

%text-style-month {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 12px;
	color: $color-text;
}

%text-style-all-month {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 11px;
	color: $color-text;
}

%text-style-week {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 9px;
	line-height: 12px;
	text-transform: capitalize;

	color: $color-text;
}
%text-style-day {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 9px;
	line-height: 12px;
	color: $color-text;
}
%border-radius {
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
}
.react-calendar {
	width: 170px;

	&--doubleView {
		width: 290px;
		margin: 0px auto;
		@media screen and (min-width: $tablet) {
			width: 350px;
		}
		.react-calendar__viewContainer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// margin: 2px 3px;

			> * {
				width: 138px;
				@media screen and (min-width: $tablet) {
					width: 49%;
				}

				// margin: 5px 0;
			}
		}
	}

	&,
	& *,
	& *:before,
	& *:after {
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	button {
		@extend%border-radius;
		margin: 0;
		border: 0;
		outline: none;
		transition: color 250ms, background-color 250ms;
		// &:disabled {
		// 	@extend%border-radius;
		// 	&:hover,
		// 	&:focus {
		// 		cursor: pointer;
		// 		color: $color-active-day;
		// 		background-color: rgba(174, 175, 188, 0.5);
		// 	}
		// }

		&:enabled {
			@extend%border-radius;
			&:hover {
				cursor: pointer;
				color: $color-active-day;
				background-color: rgba(0, 144, 255, 0.5);
			}
			&:focus {
				border-radius: 50%;
				// background-color: rgba(0, 144, 255);
				// &:hover {
				// 	background-color: rgba(0, 144, 255, 0.5);
				// }
			}
		}

		&.react-calendar__tile--active {
			// background: rgba(0, 107, 190, 1);
			// color: $color-active-day;
			&.react-calendar__tile--now {
				@extend%border-radius;
				// background-color: $colors-brand;
				&.react-calendar__tile--rangeStart {
					border-radius: 5px;
					// background-color: rgba(0, 144, 255);
				}
				&.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
					border-radius: 5px;
					// background-color: rgba(0, 144, 255);
					// &.react-calendar__tile--rangeEnd {
					// 	@extend%border-radius;
					// 	background-color: rgb(0, 144, 255);
					// }
				}
			}

			&:hover {
				@extend%border-radius;
			}
		}
		&.react-calendar__tile--now {
			// @extend%border-radius;
			// background-color: rgb(174, 175, 188);
			/* border-radius: 4px;
			border: 2px solid rgba(0, 144, 255, 0.7);
			background-color: transparent; */
		}
		&.react-calendar__tile--range {
			// background-color: rgba(0, 144, 255, 0.47);
		}
		&.react-calendar__tile--rangeStart {
			// background: $colors-brand;
			// color: $color-active-day;
      border: 2px solid #000;
			border-radius: 5px;
			transition: border-radius 250ms;
			&.react-calendar__tile--rangeBothEnds {
				// @extend%border-radius;
				// background-color: rgba(0, 144, 255);
				transition: border-radius 250ms;
				// &:hover,
				// &:focus {
				// 	@extend%border-radius;
				// }
			}
			&:hover,
			&:focus {
				border-radius: 5px;
			}
		}

		&.react-calendar__tile--rangeEnd {
			// background: $colors-brand;
			// color: $color-active-day;
			border-radius: 5px;
			transition: border-radius 250ms;
			&:hover,
			&:focus {
				border-radius: 5px;
			}
		}

		&.react-calendar__navigation__next-button {
			min-width: 33px;
			height: 33px;
			// border-radius: 50%;
		}
	}

	&__navigation {
		display: flex;
		height: 13px;
		// margin: 5px 5px;
		margin-bottom: 18px;

		&__label__divider {
			visibility: hidden;
		}

		button {
			margin: 0px 2px;
			min-width: 33px;
			height: 33px;
			background: none;
			display: flex;
			align-items: center;
			justify-content: space-between;
			@media screen and (min-width: $tablet) {
				justify-content: space-around;
			}
			@extend%text-style-month;
			transition: background-color 250ms;
			&.react-calendar__navigation__prev-button {
				border-radius: 7px;
				min-width: 33px;
				height: 33px;
				&:enabled {
					&:hover {
						border-radius: 7px;
					}
					&:focus {
						border-radius: 7px;
					}
				}
			}
			&.react-calendar__navigation__next-button {
				border-radius: 7px;
				min-width: 33px;
				height: 33px;
				display: flex;
				align-items: center;
				justify-content: center;
				&:enabled {
					&:hover {
						border-radius: 7px;
					}
					&:focus {
						border-radius: 7px;
					}
				}
			}
			&:disabled img {
				color: $color-not-active-day;
				visibility: hidden;
			}
			img {
				width: 13px;
				height: 13px;
			}
			&:enabled {
				&:hover,
				&:focus {
					background-color: rgba(208, 208, 208, 0.35);
					@extend%border-radius;
				}
			}
		}
	}
	&__navigation__label__labelText--from::first-letter {
		text-transform: uppercase;
	}
	&__navigation__label__labelText--to::first-letter {
		text-transform: uppercase;
	}
	&__month-view {
		&__weekdays {
			@extend%text-style-week;
			text-decoration: none;
      margin-bottom: 5px;
			&__weekday::first-letter {
				text-transform: uppercase;
			}
			&__weekday {
				padding: 2px;
				margin: 0;
				text-align: center;
				overflow: hidden;
				width: 100%;
				word-wrap: break-word;
				@media screen and (min-width: $tablet) {
					margin: 0;
				}
			}
		}

		&__weekNumbers {
			.react-calendar__tile {
				display: flex;
				align-items: center;
				justify-content: center;
				@extend%text-style-week;
			}
		}

		&__days {
			display: flex;
			flex-wrap: wrap;
			&__day {
				@extend%text-style-day;
				color: #4c4c4c;

				&--neighboringMonth {
					visibility: hidden;
					color: $color-not-active-day;
				}
			}
		}
	}

	&__year-view,
	&__decade-view,
	&__century-view {
		.react-calendar__tile {
			padding: 10px 2px;
			@extend%text-style-all-month;
		}
	}

	&__tile {
		max-width: 100%;
		height: 20px;
		padding: 2px;
		background: none;
		text-align: center;
		transition: border-radius 250ms;
		@media screen and (min-width: $tablet) {
			height: 24px;
		}
		&:disabled {
			@extend%border-radius;
			&:hover,
			&:focus {
				// cursor: pointer;
				// color: $color-active-day;
				// background-color: rgba(174, 175, 188, 0.5);
			}
		}
	}

	&--selectRange {
		button.react-calendar__tile {
			&--hover {
				background: rgba(0, 144, 255, 0.47);
				@extend%border-radius;
				color: #4c4c4c;
			}
			&--hover:disabled {
				background-color: rgba(174, 175, 188, 0.5);
				color: #4c4c4c;
			}

			&.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
				background: rgba(0, 144, 255, 0.7);
				@extend%border-radius;
				color: $color-active-day;
				&.react-calendar__tile--now {
					border-radius: 50%;
					// 	background-color: $colors-brand;
					// &.react-calendar__tile--rangeStart {
					// 	border-radius: 5px;
					background-color: rgba(0, 144, 255, 0.7);
					// &.react-calendar__tile--rangeEnd {
					// 	border-radius: 5px;
					// 	background-color: rgb(0, 144, 255);
					// }
					// }
				}

				&:hover {
					@extend%border-radius;
				}
			}
		}
	}
}

.calendar_wrapper_mobile {
	width: 100%;
	height: auto;
	// height: 360px;
	background-color: #ffffff;
	border-radius: 10px;
	margin: 8px auto 12px auto;
	padding: 12px 0px 12px 0px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}

.top_mob_calendar,
.bottom_mob_calendar {
	margin: 0 auto;
}

.top_mob_calendar {
	margin-bottom: 18px;
}

.calendar_context_box {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.react-calendar__navigation__next-button {
	border: none;
	outline: none;
	margin: 0px 2px;
	min-width: 33px;
	height: 33px;
	background: none;
	display: flex;
	align-items: center;
	justify-content: space-around;
	@extend%text-style-month;
	transition: background-color 250ms;
	border-radius: 7px;
	min-width: 33px;
	height: 33px;
	&:enabled {
		&:hover {
			border-radius: 7px;
		}
		&:focus {
			border-radius: 7px;
		}
	}

	&:disabled img {
		color: $color-not-active-day;
		visibility: hidden;
	}
	img {
		// transform: rotate(90deg);
		width: 13px;
		height: 13px;
	}
	&:enabled {
		&:hover,
		&:focus {
			background-color: rgba(208, 208, 208, 0.35);
			@extend%border-radius;
		}
	}
}

.dateBtn_wrapper_mobile {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

@keyframes disappear {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}