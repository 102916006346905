@import 'styles/common/variables';

.rating_img {
	cursor: pointer;
	width: 33px;
	height: 20px;
	background: transparent;
	border-radius: 6px;
	text-align: center;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	font-weight: 700;
	font-size: 20px;
	margin-left: 5px;

	@media screen and (min-width: $tablet) {
		padding-top: 0px;
	}

	@media screen and (min-width: $desktop) {
		width: 62px;
		height: 37px;
	}
}
.rating_img_s {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 33px;
	height: 20px;
	font-size: 11px;

	@media screen and (max-width: $pre-tablet) {
		//width: 30px;
		//height: 15px;
		//font-size: 9px;
	}
	@media screen and (min-width: $tablet) {
		width: 40px;
		height: 24px;
		font-size: 13px;
		font-weight: 700;
	}

	@media screen and (min-width: $desktop) {
		width: 43px;
		height: 26px;
		font-size: 15px;
	}
}
.rating_block {
	display: block;
	width: 257px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	background: #fff;
	padding: 23px 15px;
	cursor: default;

	@media (min-width: 320px) and (max-width: 767px) {
		margin: 0 auto;
		margin-bottom: 6px;
	}
}

.rating_block_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #4c4c4c;
	font-size: 12px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	width: 100%;

	.star_img {
		width: 25px;
	}
}

.rating_small_img {
	text-align: end;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	font-weight: 700;
	font-size: 13px;
}

.rating_block_footer {
	margin-top: 30px;
}
.rating_block_center_text {
	display: flex;
	justify-content: space-between;
	color: #4c4c4c;
	font-size: 11px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-top: 10px;
}
.rating_block_center_number {
	font-weight: 600;
}
