@import 'styles/common/variables';

.wrapper {
	background: #3a3a3a;
	padding: 0 6rem;
}
.container {
	// background: #e5e5e5;
	background: #ebedfe;
	display: flex;
	flex-direction: column;
}
.header {
	background: #000000;
	height: 43px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 4% 0 0;
}
.header_btn {
	display: flex;
	align-items: baseline;
	cursor: pointer;
	color: #dbd8d8;
	font-size: 12px;
	font-weight: 600;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	.header_btn_img {
		background-image: url(../../assets/images/place/closeBtn.png);
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 5px;
		width: 10px;
		height: 10px;
	}
}
.header_btn:hover {
	color: #d3d0d0;
	.header_btn_img {
		background-image: url(../../assets/images/place/closeBtnHover.png);
	}
}
.header_btn:active {
	color: #bebdbd;
	.header_btn_img {
		background-image: url(../../assets/images/place/closeBtnActive.png);
	}
}
.content {
	display: flex;
	justify-content: space-between;
}
.content_left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 1.5rem 1rem 0 4rem;
	width: 40%;
}
.content_left_top {
	display: flex;
	justify-content: space-between;
	// margin: 1.5rem 1rem 0 4rem;
}
.content_left_bottom {
	display: flex;
	justify-content: space-between;
	// margin: 1.5rem 1rem 0 4rem;
}
.content_left_place {
	display: flex;
	width: 300px;
}
.content_left_hotel {
	cursor: pointer;
	// height: 58px;
	width: 68px;
	margin-right: -22px;
	margin-top: 10px;
}
.content_left_check {
	display: flex;
	flex-direction: column;
	margin-left: 52px;
	width: 50%;
}
.content_left_price {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 50%;
}
.rating_img {
	cursor: pointer;
	width: 33px;
	height: 20px;
	background: transparent;
	border-radius: 6px;
	text-align: center;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	font-weight: 700;
	font-size: 20px;
	margin-left: 7px;

	@media screen and (min-width: $tablet) {
		padding-top: 0px;
	}

	@media screen and (min-width: $desktop) {
		width: 62px;
		height: 37px;
	}
}
.rating_img_s {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 33px;
	height: 20px;
	font-size: 11px;

	@media screen and (max-width: $pre-tablet) {
		//width: 30px;
		//height: 15px;
		//font-size: 9px;
	}
	@media screen and (min-width: $tablet) {
		width: 40px;
		height: 24px;
		font-size: 13px;
		font-weight: 700;
	}

	@media screen and (min-width: $desktop) {
		width: 43px;
		height: 26px;
		font-size: 15px;
	}
}
.rating_block {
	display: block;
	width: 191px;
	height: 184px;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
	position: absolute;
	z-index: 1000;
	background: #fff;
	padding: 10px 15px;
	cursor: default;
}

.rating_block_header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #4c4c4c;
	font-size: 12px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	width: 100%;

	.star_img {
		width: 25px;
	}
}

.rating_small_img {
	text-align: end;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	font-weight: 700;
	font-size: 13px;
}

.rating_block_footer {
	margin-top: 10px;
}
.rating_block_center_text {
	display: flex;
	justify-content: space-between;
	color: #4c4c4c;
	font-size: 11px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	margin-top: 10px;
}
.rating_block_center_number {
	font-weight: 600;
}
.content_left_place_location {
	display: flex;
	flex-direction: column;
}
.location_name {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #4c4c4c;
	font-size: 30px;
	line-height: 40px;
	padding-left: 53px;
	min-height: 80px;
	display: flex;
	align-items: center;
}
.location_place {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #4c4c4c;
	font-size: 13px;
	line-height: 17.3px;
	display: flex;
	margin-top: 14px;
}
.content_left_target {
	height: 100%;
	width: 18px;
	margin-top: 5px;
	background-image: url(../../assets/images/place/target.png);
	background-repeat: no-repeat;
	background-position: center;
}
.content_left_target:hover {
	background-image: url(../../assets/images/place/targetHover.png);
}
.content_left_target:active {
	background-image: url(../../assets/images/place/targetActive.png);
}
.location_address {
	padding-left: 37px;
}
.content_right {
	margin-top: 1.5rem;
	margin-right: 4rem;
}
.gallery {
	display: flex;
	justify-content: space-evenly;
	position: relative;	
}

.big__photo {
	&.smallS {
		width: 162px;
		height: 162px;
		border-radius: 7px;
	}

	&.mediumS {
		width: 218px;
		height: 218px;
		border-radius: 5px;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 118px;
			height: 112px;
		}
	}

}

//////////////////////////////////////////userCabinet

@media(max-width: 767px) {
	.big__photo {
		width: 228px !important; 
		height: 222px !important;
	}
}

@media(max-width: 460px) {
	.big__photo {
		width: 148px !important; 
		height: 142px !important;
	}
}
////////////////////////////////////////////



.checkIn {
	margin-right: 37px;
}
.checkOut {
	margin-right: 37px;
}
.big_photo_block_wrap {
	margin-top: 10px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #4c4c4c;
	font-size: 15px;
	font-weight: 400;
	display: flex;
}
.big_photo_block_text {
	width: 75%;
	display: flex;
	justify-content: space-between;
}
.price_block {
	margin-top: 10px;
	display: flex;
}
.price_text {
	display: flex;
	justify-content: space-between;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #006bbe;
	// color: #4c4c4c;
}
.price_val_symbol {
	height: 15px;
	margin-right: 10px;
}
.photo_gallery {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 4px;

	&.smallS {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 4px;
	}

	&.treeCollums {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 4px;
	}
}
.content_wrapper {
	&.smallS {
		margin-right: 12px;
	}

	&.mediumS {
		margin-right: 32px;
	}

	&.treeCollums {
		margin-right: 10px;
	}
}
.small_photo {
	width: 70px;
	height: 70px;
	cursor: pointer;
	border-radius: 7px;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 36px;
		height: 36px;
	}

	&.treeCollums {
		width: 70px;
		height: 70px;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 36px;
			height: 36px;
		}
	}
}
@media(max-width: 767px) {
	.small_photo {
		width: 71.49px !important; 
		height: 71.64px !important;
	
	}
}
@media(max-width: 460px) {
	.small_photo {
		width: 45.49px !important; 
		height: 45.64px !important;
	
	}
}

.small_photo:hover {
	filter: brightness(0.7);
}
.small_photo:active {
	filter: brightness(0.5);
}
.content_left_btn {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	width: 100px;
	height: 35px;
	background: #ff6000;
	border-radius: 3px;
	color: #fff;
	font-weight: 900;
	font-size: 10px;
	border: none;
	cursor: pointer;
	margin-top: 10px;
}
.content_left_btn_wrap {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
}
.content_left_btn:hover {
	background: rgba(255, 96, 0, 0.9);
}
.content_left_btn:active {
	background: rgba(255, 96, 0, 0.75);
}
.content_left_rating {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.bottom_content {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}
.bottom_content_left {
	display: flex;
	flex-direction: column;
	margin-left: 4.5rem;
}
.bottom_content_left_description {
	width: 602px;
	min-height: 169px;
	border-radius: 10px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 12px;
	font-weight: 400;
	padding: 27px;
	padding-bottom: 13px;
	margin: 0 auto;
	line-height: 16px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #4c4c4c;
	background: linear-gradient(267.96deg, #b0d1ea -4.2%, #ebf3f9 90.36%);

	@media (min-width: 320px) and (max-width: 767px) {
		width: 257px;
	}
}
.title {
	padding-bottom: 15px;
	font-size: 14px;
	font-weight: 500;
}


.bottom_content_title_description {
	font-weight: 600;
	margin-bottom: 15px;
}
.description {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
.place_description {
	background: #fff;
	border-radius: 15px;
	margin: 5px;
	padding: 5px 10px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #ff6000;
	font-size: 12px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}
.payment {
	background: #fff;
	width: 327px;
	height: 135px;
	margin-top: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	padding: 15px 15px;
	display: flex;
	flex-direction: column;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #4c4c4c;
	font-size: 12px;

	@media (min-width: 768px) and (max-width: 1199px) {
		margin-top: 0;
	}

	@media (min-width: 320px) and (max-width: 767px) {
		width: 257px;
		height: 100%;
		margin: 0 auto;
	}
}
.payment_content {
	cursor: pointer;
	margin-top: 10px;
	display: flex;
	img {
		margin-right: 30px;
	}
}
.bottom_content_right {
	display: flex;
	flex-direction: column;
	margin-left: 2rem;
	margin-right: 4rem;
	align-items: flex-end;
}
.columnFeedback {
	margin-top: 20px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 10px;
	width: 317px;
	height: 174px;
	/* height: 978px; */
}
.columnFeedback_bg {
	height: 75px;
	background-image: url(../../assets/images/feedback/feedback.jpg);
}
.feedback_wrap {
	display: flex;
	width: 315px;
	height: 75px;
	background: linear-gradient(
		180deg,
		rgba(196, 196, 196, 0) 0%,
		rgba(96, 96, 96, 0.305) 50.83%,
		rgba(56, 56, 56, 0.43) 71.67%,
		rgba(0, 0, 0, 0.44) 100%
	);
	// border-radius: 10px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border: 1.04839px solid rgba(0, 0, 0, 0.18);
}
.feedback_txt {
	display: flex;
	align-items: flex-end;
	margin-bottom: 6px;
	margin-left: 12px;
	font-family: Segoe UI;
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 32px;
	color: #ffffff;
}
.feedback_message {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-align: center;
	margin: 40px 0;
	font-weight: 400;
	font-size: 13px;
	line-height: 15px;
	color: #4c4c4c;
}
.option_header {
	display: flex;
	align-items: center;
	margin: 1.5rem 1rem 0 4rem;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 21px;
	color: #008aff;
}
.option_header_symbol {
	width: 24px;
	height: 42px;
}
.option_header_text {
	margin-left: 16px;
}
.option_content {
	display: flex;
	justify-content: space-between;
	border: 0.5px solid #aeafbc;
	margin: 1rem 2rem;
	padding: 2rem;
}
.option_content_left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 50%;
}
.option_content_right {
	display: flex;
	flex-direction: column;
}
.option_content_right_bottom {
	display: flex;
}
.option_content_column {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	width: 191px;
	margin-right: 40px;
}
.option_description {
	background: #fff;
	border-radius: 15px;
	margin: 5px;
	padding: 5px 10px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	font-size: 12px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
}
.option_bedroom {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
.option_gallery {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}
.option_content_btn_wrap {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}
.option_content_btn {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	width: 100px;
	height: 35px;
	background: #0090ff;
	border-radius: 3px;
	color: #fff;
	font-weight: 900;
	font-size: 10px;
	border: none;
	cursor: pointer;
	margin-top: 10px;
}
.option_content_btn:hover {
	background: rgba(0, 144, 255, 0.7);
}
.option_content_btn:active {
	background: rgba(0, 144, 255, 0.5);
}
.option_animal {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}
.option_animal_block {
	display: flex;
	margin-bottom: 2px;
}
.option_animal_text {
	margin-left: 3px;
	padding-left: 10px;
	width: 125px;
	height: 15px;
	background: #ffffff;
	border-radius: 5px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 350;
	font-size: 10px;
	line-height: 13px;
	color: #006c0b;
}
.option_animal_text_big {
	margin-left: 3px;
	padding-left: 10px;
	width: 125px;
	height: 30px;
	background: #ffffff;
	border-radius: 5px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 350;
	font-size: 10px;
	line-height: 13px;
	color: #006c0b;
}
.option_animal_header {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #4c4c4c;
}
.option_area {
	display: flex;
	justify-content: space-between;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: #a03507;
	// color: #4C4C4C;
}
.option_price_block {
	display: flex;
	align-items: center;
}
.no_options {
	display: flex;
	margin: 1rem 4rem;
	padding: 1.5rem;
	width: 520px;
	height: 128px;
	background: #ffffff;
	border-radius: 2px;
}
.no_options_block {
	display: flex;
	width: 100%;
	padding: 0.5rem;
	align-items: center;
	justify-content: space-around;
	box-sizing: border-box;
	border: 0.2px solid rgba(0, 0, 0, 0.5);
	border-radius: 10px;
}
.no_options_text {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #535252;
}
.temp_btn {
	margin-left: 1rem;
	border: 1px solid #0090ff;
	border-radius: 3px;
	padding: 0.5rem;
	background: #ffffff;
	color: #0090ff;
}
