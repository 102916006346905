@use './styles/common/breakpoints.scss' as br;
@use './styles/common/variables.scss' as v;

.container {
  font-family: 'Segoe UI';
}
.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    max-width: 1200px;
  }
}

.imageText {
  position: absolute;
  width: 211px;
  height: 36px;
  background-color: #f5ff86;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 11px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 900;

  span:first-child {
    color: v.$colors-brand-accent;
  }
  span:last-child {
    color: v.$colors-brand;
  }

  @media (min-width: br.$tablet) {
    width: 454px;
    height: 51px;
    font-size: 22px;
  }
  @media (min-width: br.$desktop) {
    width: 581px;
    height: 58px;
    font-size: 22px;
  }
  @media (max-width: 769px) {
    span:first-child {
      font-size: 11px;
    }
  }
}

.content {
  margin: 12px 0 68px;
  background-color: #f7fad2;
  border-radius: 33px;
  padding: 42px 24px;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  color: #4C4C4C;

  @media (min-width: br.$tablet) {
    margin-top: 26px;
    padding: 42px 60px;
  }
  @media (min-width: br.$desktop) {
    padding: 53px 86px;
  }
}

.p1{
  font-size: 13px;
  font-weight: 700;
  line-height: 17.29px;
  font-family: Segoe UI, sans-serif;
  margin-bottom: 30px;
  @media (max-width: 769px) {
    font-size: 11px;
  }
}

.pTitle{
  font-size: 15px;
  font-weight: 700;
  line-height: 19.95px;
  font-family: Segoe UI, sans-serif;
  margin-bottom: 5px;
}

.pDesc{
  font-size: 12px;
  font-weight: 700;
  line-height: 19.95px;
  font-family: Segoe UI, sans-serif;
  margin-bottom: 20px;
  @media (max-width: 769px) {
    font-size: 11px;
    font-weight: 600;
  }
}

.footer{
  margin-left: auto;
}
