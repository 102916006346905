@import 'styles/common/variables';

.iconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 8px;
	height: 6px;
	margin-bottom: 3px;
}

.arrowUp {
	width: 100%;
	height: 100%;
}

.arrowDown {
	@extend .arrowUp;

	transform: rotate3d(1, 0, 0, 180deg);

	@media (prefers-reduced-motion: no-preference) {
		transition: 110ms $cubic;
		transition-property: transform;
	}
}
