
.modal__box_price {
  padding: 13px 7px 19px 30px;
  display: flex;
  align-items: center;
  position: relative;
}

.modal__box_price:not(:first-child) {
  padding-top: 4px;
}

.modal__error_message {
  position: absolute;
  font-family: 'Segoe UI';
  font-weight: 700;
  font-size: 12px;
  line-height: 1.3;
  color: #ff6a6a;
  top: 7px;
  left: 80px;
}

// .modal__label_price__primary {
//   color: #6aff79;
// }

.modal__value_price {
  min-width: 40px;
  width: 66px;
  height: 23px;
  opacity: 0.55;
  border: 0.5px solid #4c4c4c;
  border-radius: 3px;
  margin-left: 16px;
  padding: 0px 5px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 13px;
  line-height: 1.3;
  text-align: center;

  &:disabled {
    background: #e3e3e3;
  }

  &_small {
    width: 52px;
    height: 18px;
  }
}

.modal__value_price_error {
  @extend .modal__value_price;
  border: 0.5px solid #ff6a6a;
}

.modal__value_price_text {
  position: absolute;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 8px;
  line-height: 1.375;

  &__primary {
    color: #6aff79;
  }

  &__secondary {
    color: #ffe600;
  }
  bottom: -12px;
  left: 72px;
}

.modal__value_text {
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 15px;
  line-height: 15.96px;
  margin-left: 5px;
  color: #787878;

  // &__primary {
  //   color: #6aff79;
  // }

  // &__secondary {
  //   color: #ffe600;
  // }
  margin-right: 5.8px;
}

.modal__button_submit {
  cursor: pointer;
  border-radius: 20px;
  font-family: 'Segoe UI';
  font-weight: 700;
  font-size: 10px;
  line-height: 1.33;
  text-align: center;
  color: rgba(120, 120, 120, 1);
  border: .2px solid #4c4c4c;
  width: 115px;
  // height: 32px;
  padding: 3px 5px;

  &__primary {
    background: #aef9b5;
  }

  &__secondary {
    background: #ffe600;
  }
}

.modal__button_submit_have_price {
  @extend .modal__button_submit;
  background: #6aff79;
  box-shadow: 0px 0px 5.53488px rgba(0, 0, 0, 0.25);
  color: #787878;
  &:hover {
    color: rgba(120, 120, 120, 0.6);
  }
  &:active {
    color: rgba(120, 120, 120, 0.4);
  }
}

.modal__button_submit_have_error {
  border: 0.5px solid #ff6a6a;
}

.modal__button_submit_change_price {
  background: #aef9b5;
  box-shadow: 0px 0px 5.53488px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  font-weight: 700;
  font-size: 10px;
  line-height: 1.3;
  color: #787878;
  &:hover {
    color: rgba(120, 120, 120, 0.6);
  }
  &:active {
    color: rgba(120, 120, 120, 0.4);
  }
}

.modal__box_bed {
  padding: 5px;
  background: #ebedfe;
  border: none;
  border-radius: 1px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.modal__label_price_all {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Segoe UI';
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #4c4c4c;

  
  
  &:hover .modal__checkbox {
    border: 3px solid #4c4c4c;
  }

}

input[type='checkbox'] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.modal__checkbox {
  transition: border 0.1s linear;
  display: inline-block;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background: #fff;
  border: 0.5px solid #000000;
  border-radius: 5px;
  margin-right: 4px;
  &:hover {
    border: 3px solid #4c4c4c;
    border-radius: 5px;
  }
}

.modal__checkbox_active {
  transition: opacity 0.1s linear;
  border: 3px solid #4c4c4c;
  border-radius: 5px;
  position: relative;
  padding: 6px;
  &::before {
    content: '';
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #4c4c4c;
  }
}

.modal__icon_bed {
  display: inline-block;
  width: 17px;
  height: 15px;
  fill: #787878;
  margin-right: 10px;
  margin-left: 10px;
}

.modal__box_button_plus {
  margin-left: 35px;
  position: relative;
}

.modal__button_plus {
  z-index: 2;
  position: absolute;
  cursor: pointer;
  background: #ebedfe;
  border: none;
  padding: 5.3px;
  border-radius: 3px;
  &:hover .modal__icon_plus {
    fill: rgba(120, 120, 120, 0.8);
  }
  &:active .modal__icon_plus {
    fill: rgba(120, 120, 120, 0.5);
  }
}

.modal__icon_plus {
  display: block;
  width: 8.28px;
  height: 8.28px;
  fill: #787878;
}

.calendar__wrapper_disabled {
  opacity: .6;
  pointer-events: none;
}

.modal__calendar_box {
  padding-top: 25px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  overflow: hidden;
  position: relative;
  // height: 163px;
}

.modal__gradient_primary {
  background: linear-gradient(to bottom, rgba(106, 255, 121, .5), rgba(217, 217, 217, 0));
}

.modal__gradient_secondary {
  background: linear-gradient(to bottom, rgba(255, 230, 0, .5), rgba(217, 217, 217, 0));
}

.modal__gradient_blue {
  background: linear-gradient(to bottom, rgba(0, 144, 255, 0.5), rgba(217, 217, 217, 0));
}

.modal__error_message_calendar {
  @extend .modal__error_message;
  top: 27px;
  left: 22px;
}

.modal__box_button {
  padding: 47px 30px 0px;
}

.modal__button_clear {
  cursor: pointer;
  min-width: 260px;
  min-height: 44px;
  // border: 0.5px solid transparent;
  border: 1px solid #0090FF;
  // background: #ebedfe;
  background-color: transparent;
  // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  border-radius: 33px;
  padding: 6px 12px;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 11px;
  line-height: 14.63px;
  text-align: center;
  // color: #787878;
  color: #0090FF;

  transition: background-color .15s ease-in;
  &:hover {
    background-color: #F1F4F5;
  }
  &:active {
    border: 0.5px solid #0090FF;
    color: #0090ff;
    background-color: #e0ecf0;
  }

  & span.modal__button_clear_text {
    display: inline-block;
    width: 89%; 
  }

  & span.modal__button_clear_icon {
    position: relative;
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      position: absolute;
      border: 1px solid #0090FF;
      top: -50%;
    }
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 1px;
      top: 4px;
      left: 8px;
      transform: rotateZ(-45deg);
      background-color: #0090FF;
    }
  }
  &_active {
    background-color: #006bbe;
    border: 1px solid #fff;

    &:hover {
      background-color: rgba(0, 107, 190, 0.7);
      border: 1px solid #fff;
    }
    &:active {
      background-color: rgba(0, 107, 190, 0.5)rgba(0, 107, 190, 0.5);
      border: 1px solid #fff;
    }

    & span.modal__button_clear_text {
      color: #fff;
    }

    & span.modal__button_clear_icon {
      &::after {
        background-color: #fff;
      }

      &::before {
        border-color: #fff;
      }
    }
  }
}

.modal__box_checkbox {
  width: 100%;
  height: 125px;
  padding: 0px 40px 0px 70px;
  margin-top: 20px; 
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>.modal__label_price_all:first-child::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: 0.5px solid #ff6000;
    border-radius: 1px;
    top: 0;
    left: -27px;
  }
  

  &>.modal__label_price_all:first-child::after {
      content: '';
      position: absolute;
      width: 13px;
      height: 0.5px;
      top: 5px;
      left: -28px;
      transform: rotateZ(-45deg);
      background-color: #ff6000;
    }

  &>.modal__label_price_all:nth-child(2)::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    border: 0.5px solid #006bbe;
    border-radius: 1px;
    top: 0;
    left: -27px;
  }

  & .modal__label_price_all {

    
  &_nos::after {
    content: '';
    width: 9px;
    height: 1px;
    position: absolute;
    top: 11px;
    left: -19px;
    background-color:  #006bbe;
    transform: rotateZ(-45deg);
  }

  &_ab::before {
    content: '';

    width: 16px;
    height: 16px;
    position: absolute;
    top: -2px;
    left: -27px;

  }

    &_block {
      display: flex;
      align-items: center;
      gap: 30px;
  
      & span {
        font-size: 12px;
        font-weight: 600;
      }
    }
    
    &_small {
      width: 20%;
      font-size: 12px;
      color: #787878;
  
      &_primary {
        &::before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          top: -2px;
          left: -27px;
          border: .5px solid #6aff79 !important;
          background-color: #6aff79;
        }
      }

      &_secondary {
        &::before {
          content: '';
          width: 16px;
          height: 16px;
          position: absolute;
          top: -2px;
          left: -27px;
          border: .5px solid #FFE600 !important;
          background-color: #FFE600;
        }
      }
    }
    cursor: default;
    margin-bottom: 10px;
  }
  & .modal__label_price_all:not(:last-child) {
    cursor: default;
  }
  // & .modal__label_price_all::before {
  //   content: '';
  //   position: absolute;
  //   width: 16px;
  //   height: 16px;
  //   border: 0.5px solid #ff6000;
  //   border-radius: 1px;
  //   top: 0;
  //   left: -27px;
  // }

  // & .modal__label_price_all::after {
  //   content: '';
  //   position: absolute;
  //   width: 13px;
  //   height: 0.5px;
  //   top: 5px;
  //   left: -28px;
  //   transform: rotateZ(-45deg);
  //   background-color: #ff6000;
  // }

  & .modal__label_price_all:nth-child(2)::before {
    border: 0.5px solid #006bbe;
  }

  // & .modal__label_price_all:nth-child(2)::after {
  //   top: 12px;
  //   left: -19px;
  //   width: 8px;
  //   background-color: #006bbe;
  // }

  & .modal__label_price_all:nth-child(4)::before {
    background: #c4c4c4;
    border: 0.5px solid transparent;
  }
}

.modal__label_price {
  cursor: pointer;
  position: relative;
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 15px;
  line-height: 1.3;
  
  // margin-right: 7px;  

  &::before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    opacity: .75;
    border-radius: 1px;
    top: 2px;
    left: -20px;
  }

  
  & span {
    display: inline-block;
    min-width: 40px;
    color: #787878;
    font-weight: 600;
  }

  &__primary {
    // color: #6aff79;
    &::before {
      background: #6aff79;
    }
  }

  &__secondary {
    // color: #ffe600;
    // margin-right: 7px;

    &::before {
      background: #ffe600;
    }
  }
}

.price__modal__title {
  width: 100%;
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;

  font-family: 'Segoe UI', sans-serif;
  font-size: 12px;
  line-height: 13.3px;
  font-weight: 350;
  color: #4C4C4C;

  &_error {
    padding: 13px 19px 37px 27px;
    text-align: center;
    color: #ff6000;
    font-weight: 700;
    font-family: 'Segoe UI', sans-serif;
    font-size: 12px;
    line-height: 13.3px;
  }
}

.price__block__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.price__highlighted_text {
  font-weight: 600;
  color: #ff6000;
}

.price__block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.priceblock__modal__btn__block {
  margin-top: 30px;
  padding-bottom: 13px;
  max-width: 205px;

  & button {
    font-family: 'Segoe UI', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: #787878;
    border: 0.2px solid #4c4c4c;
    outline: none;
    padding: 10px 40px 8px;
    border-radius: 30px;

    &:disabled {
      background-color: lightgray;
      cursor: initial;
    }
  }
}

.priceblock__modal__btn__primary {
  background-color: rgba(174, 249, 181, 0.5);

  &:hover {
    background-color: rgba(174, 249, 181, 0.4);
  }

  &:active {
    background-color: rgba(174, 249, 181, 0.3);
  }
}

.priceblock__modal__btn__secondary {
  background-color: rgba(255, 230, 0, 0.5);

  transition: background-color .22s ease;

  &:hover {
    background-color: rgba(255, 230, 0, 0.4);
  }

  &:active {
    background-color: rgba(255, 230, 0, 0.3);
  }
}

.notOurServiceTitle {
  font-family: "Segoe UI";
  font-weight: 350;
  font-size: 12px;
  text-align: center;
  line-height: 13.3px;
  color: #4c4c4c;
  padding: 5px 30px 10px 30px;

  &_error {
    color: #ff6000;
    font-weight: 700;
    text-align: center;
  }
}

.hostel__checkbox {
  font-family: 'Segoe UI', sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 13.3px;
  color: #4c4c4c;
  user-select: none;

  display: flex;
  align-items: center;
  padding-bottom: 13px;
  position: relative;
  cursor: pointer;

  & input.hostel__checkbox_input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .hostel__checkbox_checkmark::after{
      content: '';
      position: absolute;
      top: 10px;
      left: 3px;
      width: 5px;
      height: 1px;
      transform: rotateZ(45deg);
    }

    &:checked ~ .hostel__checkbox_checkmark::before{
      content: '';
      position: absolute;
      top: 8px;
      left: 5px;
      width: 12px;
      height: 1px;
      transform: rotateZ(125deg);
    }
  }

  &_checkmark {
    width: 19px;
    height: 19px;
    border: 1px solid #6e6e6e;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, .25);
    margin-left: 5px;
    position: relative;
  }

  &_primary {
    color: inherit;
    & input.hostel__checkbox_input {
  
      &:checked ~ .hostel__checkbox_checkmark::after{
        background-color: #1e8e29;
      }
  
      &:checked ~ .hostel__checkbox_checkmark::before{
        background-color: #1e8e29;
      }
    }
  }

  &_secondary {
    color: inherit;
    & input.hostel__checkbox_input {
  
      &:checked ~ .hostel__checkbox_checkmark::after{
        background-color: #a69811;
      }
  
      &:checked ~ .hostel__checkbox_checkmark::before{
        background-color: #a69811;
      }
    }
  }
}