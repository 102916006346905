.arrow__icon__block {
    position: absolute;
    top: 8px;
    left: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    cursor: pointer;
    border-radius: 50%;
    fill: #aeafbc;

    transition: all .22s ease-in-out;

    &:hover,
	&:focus {
		background: #ededed;
		fill: rgba(120, 120, 120, 0.7);
	}
}

.arrow__icon {
    width: 52px;
    height: 33px;
}