// breakpoints
$mobile: 320px;
$tablet: 768px;
$laptop: 970px;
$desktop: 1200px;

$pre-tablet: $tablet - 1px;
$pre-laptop: $laptop - 1px;
$pre-desktop: $desktop - 1px;

// content
$desktop-width-main: 616px;
