@import './breakpoints';

.relativeHeader {
	position: relative;
	opacity: 0.21;

	&:hover,
	&:focus-within {
		opacity: 1;
	}
}
.absoluteHeader {
	position: absolute;
	bottom: 80%;
	left: 0;
	font-size: 0.7rem;
	background-color: white;
	padding: 2px 4px;
}

body {
	background-color: #ebecfe;
}
// .headerLog-container {
//   background: lightblue;
//   height: 52px;
//   position: relative;
//   display: flex;
//   align-items: center;
//   // justify-content: space-around;
//   justify-content: space-between;
//   padding: 0 40px 0 20px;
// }

.padLoading {
	padding-left: 8px;
}
/* .ls */
.lang {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 20px;
	font-family: 'Segoe UI', sans-serif;
	color: #0090ff;
}
.langLog {
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
	line-height: 27px;
	font-family: 'Segoe UI', sans-serif;
	color: #0090ff;
}
.langLog:hover {
	z-index: 8;
}
.vector {
	padding: 0px 0px 2px 5px;
	transform: rotate(180deg);
	margin-left: 10px;
}
///////////////////////////////////////
//LinkRout
///////////////////////////////////////
.links-list {
	position: relative;
	word-break: break-word;

	display: grid;
	grid-template-columns: 0.9fr 1fr 1.2fr 1.3fr;
	gap: 2px;

	padding: 32px 0 0;

	@media screen and (min-width: $tablet) and (max-width: $pre-desktop) {
		grid-column: 1/3;
	}

	@media screen and (min-width: $desktop) {
		order: 1;
	}
}

.my-wrapper {
	@media screen and (min-width: $desktop) {
		order: 0;
	}
}

.nav-link-item {
	&:first-of-type {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.nav-link-header {
	font-size: 1rem;
	font-weight: 700;
}

.nav-link {
	display: inline-flex;
	padding: 4px;

	font-size: 11px;
	line-height: 1.3;
	font-family: 'Segoe UI';
	color: #4c4c4c;
	text-decoration: none;

	border-radius: 10px;

	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

	@media screen and (min-width: $tablet) {
		font-size: 0.8rem;
	}

	text-decoration: none;
	border-radius: 4px;

	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1);

	&:hover,
	&:focus {
		color: #2196f3;
	}
}
// .registration {
//   outline: none;
//   background: #ff6000;
//   border: 0;
//   box-sizing: border-box;
//   border-radius: 23px;
//   height: 31px;
//   width: 233px;
//   color: #fff;
//   text-align: center;
//   font-family: 'Segoe UI', sans-serif;
//   font-style: normal;
//   font-weight: 900;
//   font-size: 13px;
//   line-height: 18px;
// }
// .registration:hover {
//   outline: none;
//   border: 1px solid;
//   background: rgba(255, 96, 0, 0.5);
// }
// .registration:active {
//   outline: none;
//   color: #ff6000;
//   background: #ffffff;
//   border: 2px solid #ff6000;
// }
// .registration:focus {
//   outline: none;
// }
.in {
	background: #006bbe;
	border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 10px;
	width: 119px;
	height: 37px;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	color: #fff;
}
.in:hover {
	background: rgba(0, 144, 255, 0.5);
	outline: none;
}
.in:active {
	color: #0090ff;
	background: #ffffff;
	border: 1px solid #0090ff;
	outline: none;
}
.in:focus {
	outline: none;
}
//////////////////////////////////////////////////////////////
////////////HotelierCatalog
//////////////////////////////////////////////////////////////
.container {
	position: relative;
	// padding: 10px 0px 25px 0px;
	background-color: rgb(235, 237, 254);
	display: flex;
	justify-content: center;
}
.blockOne {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 3px;
	width: 600px;
	height: 480px;
	margin-right: 15px;
}
.block_block {
	position: relative;
	// padding-bottom: 10px;
	display: flex;
	flex-direction: column;
	text-align: center;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
	color: #787878;
	width: 186px;
	height: 118px;
}
.block_block img {
	position: absolute;
	left: 65px;
	bottom: 0px;
}
.block_string {
	display: flex;
	width: 525px;
	height: 105px;
	background: #f4f4f4;
	border: 2px solid #d0d0d0;
	box-sizing: border-box;
	border-radius: 20px;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 17px;
	color: #4c4c4c;
	// align-items: center;
	// padding: 18px 0 0 15px;
}
.block_string p {
	font-weight: 600;
	font-size: 10px;
	line-height: 13px;
}
.columnOne-btn_wrap {
	display: flex;
	align-items: center;
}
.blockButton {
	outline: none;
	margin-right: 26px;
	background: #ff6000;
	border: 1px solid #d0d0d0;
	box-sizing: border-box;
	border-radius: 23px;
	height: 33px;
	width: 204px;
	color: #fff;
	text-align: center;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: 900;
	font-size: 13px;
	line-height: 18px;
}
.blockButton:hover {
	outline: none;
	border: 1px solid;
	background: rgba(255, 96, 0, 0.9);
}
.blockButton:active {
	outline: none;
	color: #ffffff;
	background: rgba(255, 96, 0, 0.75);
}
.blockButton:focus {
	outline: none;
}
.blockTwo {
	display: flex;
	flex-wrap: wrap;
	margin-top: 26px;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 3px;
	width: 600px;
	height: 158px;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 19px;
}
.ypb {
	float: left;
	margin: 5px 0px 0px 10px;
	width: 126px;
	height: 73px;
}
#blueOne {
	margin: 30px 35px 0px 15px;
	color: #0090ff;
}
#orangeOne {
	margin: 30px 0px 0px 20px;
	color: #ff6000;
}
#blueTwo {
	margin: -25px 0px 0px 35px;
	color: #0090ff;
}
#orangeTwo {
	margin: -25px 0px 0px 20px;
	color: #ff6000;
}
#blueThree {
	margin: 0px 0px 0px 100px;
	color: #0090ff;
}
#orangeThree {
	margin: -25px 0px 0px 35px;
	color: #ff6000;
}
#blueFour {
	margin: 0px 0px 0px 55px;
	color: #0090ff;
}
#orangeFour {
	margin: 0px 0px 0px 80px;
	color: #ff6000;
}
#orangeFive {
	margin: 0px 0px 0px 20px;
	color: #ff6000;
}
.blockThree {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 3px;
	width: 600px;
	height: 291px;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	color: #4c4c4c;
}
.blockThree_block {
	display: flex;
	align-items: center;
}
.blockThree_block:first-child {
	margin-top: 25px;
}
.blockThree_block img {
	margin: 5px 15px 0 15px;
	height: 21px;
	width: 21px;
}
.blockThree_block p {
	margin: 0;
	padding: 16px 30px 0px 0px;
}
// .blockFoure {
//   padding-top: 17px;
//   display: flex;
//   margin-top: 25px;
//   background: #ffffff;
//   border: 1px solid rgba(0, 0, 0, 0.18);
//   border-radius: 3px;
//   width: 600px;
//   height: 174px;
//   font-family: 'Segoe UI', sans-serif;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 19px;
//   color: #4c4c4c;
// }
// .blockFoure p {
//   margin: 15px 0px 0px 0px;
//   font-size: 10px;
//   line-height: 13px;
// }
// .blockFoure_block {
//   display: flex;
//   align-items: center;
// }
// #blockFoure_text {
//   width: 369px;
// }
// .blockFoure_block img {
//   margin: 0px 20px 0px 20px;
// }
#registration {
	margin: 102px 0px 0px -100px;
	// border: 1px solid #ff6000;
}
#registration:hover {
	border: none;
}
#registration:active {
	border: 1px solid #ff6000;
}
/////////////////////////////////////////////////////////////////not header style
.columnTwo {
	background: #ffffff;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 3px;
	width: 317px;
	/* height: 978px; */
}
.columnTwo_header {
	font-family: 'Segoe UI', 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	color: #fff;
}
#vectorTwo {
	margin: -1px 0px 0px -1px;
}
#vectorThree {
	margin: -1px 0px 0px -3px;
}
.columnTwo_header p {
	margin: -40px 0px 0px 12px;
}
.columnTwo_block {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 20px;
}
#review {
	padding-top: 10px;
}
.columnTwo_footer {
	display: flex;
	justify-content: center;
	font-family: 'Segoe UI', 'Roboto', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	color: #787878;
}
.columnTwo_footer p {
	padding: 10px 10px 0px 0px;
}
.columnTwo_footer p:hover {
	padding: 0px 10px 0px 0px;
	font-size: 24px;
	line-height: 28px;
	color: #000000;
}
.catalog__content-right__bron {
	margin-top: 30px;
	width: 316px;
	height: 390px;
	background: #ffffff;
	border: 1px solid #c9c9c9;
	box-sizing: border-box;
	border-radius: 10px;
}
.catalog__content-left__seo {
	width: 600px;
	height: 158px;
	background: rgba(229, 229, 229, 0.58);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	font-weight: 700;
	font-size: 18px;
	color: #4c4c4c;
	font-family: 'Segoe UI', 'Roboto', sans-serif;
	line-height: 23px;
}
/*Стиль для HeaderOne*/
.cabinet {
	outline: none;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 17px;
	text-align: center;
	color: #ffffff;
	width: 143px;
	height: 31.97px;
	background: #0090ff;
	border-radius: 15px;
	border: 1px solid #0090ff;
}
.cabinet:hover {
	outline: none;
	background: rgba(0, 144, 255, 0.5);
}
.cabinet:active {
	outline: none;
	border: 1px solid #0090ff;
	background: #fff;
	color: #0090ff;
}
.cabinetDiv {
	margin-top: 15px;
}
.panel {
	margin-top: 30px;
	display: flex;
	align-items: center;
}
.panel img {
	padding-right: 20px;
}
.hilton {
	padding-left: 1px;
	display: flex;
	align-items: center;
}
.hilton:hover {
	padding: 0px 0px 0px 0px;
	width: 143px;
	height: 31px;
	border: 1px solid #d0d0d0;
	box-sizing: border-box;
	border-radius: 5px;
}
#vectorFour {
	padding-left: 40px;
}
#vectorThree {
	padding-left: 1px;
}

/* dropdown */
.position-relative {
	position: relative;
}

.dropdown {
	position: absolute;
	display: none;
	top: -10px;
	right: 0;
}

.dropdown-show {
	width: 140px;
	height: 260px;
	margin: 42px 0px 0px 0px;
	display: block;
	background-color: #fff;
	border: 1px solid #d0d0d0;
	border-radius: 5px;
}
.dropdown-show ul {
	display: flex;
	flex-direction: column;
	padding: 0px 0px 0px 0px;
}
.dropdown-show li {
	padding: 21px 0px 20px 0px;
	list-style-type: none;
}
.dropdown-show img {
	padding: 0px 10px 0px 10px;
	width: 14px;
	height: 14px;
}
.test {
	margin-top: -16px;
	font-family: 'Segoe UI', 'Roboto', sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 10px;
	line-height: 12px;
	color: #000;
	display: flex;
	align-items: center;
}
.test:hover {
	color: #fff;
	background-color: #006bbe;
	cursor: pointer;
}

.test:hover img {
	filter: brightness(5);
}

/* ////////////////////////////////////////////////// */
/* SelectorHoteliers */
/* ////////////////////////////////////////////////// */

.reg-obj {
	color: white;
	top: 30%;
	/* backgroundColor: blue[500], */
	height: 31px;
	width: 233px;
	border: none;
	text-align: center;
	font-family: 'Segoe UI', sans-serif;
	font-style: normal;
	font-weight: 900;
	font-size: 13px;
	line-height: 18px;
	border-radius: 15px;
	background-color: #ff6000;
}
.reg-obj:hover {
	background-color: rgba(255, 96, 0, 0.5);
}
.reg-obj:active {
	background-color: #fff500;
}

/* ////////////////////////////////////////////////// */
/* SelectorParlour */
/* ////////////////////////////////////////////////// */
.container-box {
	position: relative;
}
.requests-box {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;

	@media screen and (min-width: $tablet) {
		gap: 20px;
	}
}
.overlay {
	position: absolute;
	top: 128%;

	left: 0px;
	z-index: 68;

	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	padding: 0 10px;

	height: 25px;

	font-weight: 700;
	font-size: 11px;
	line-height: 1.5;
	font-family: 'Segoe UI', sans-serif;
	color: #fff;

	cursor: default;
	visibility: hidden;
	background-color: #000000;
	border-radius: 5px;
	opacity: 0;

	transition: 0.2s $transition;
	transition-property: opacity, visibility;
}

.container-box .imgs-wrap:hover + .overlay {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.5s;
}
.container-box {
	position: relative;
	display: flex;
	justify-content: flex-end;
}

.request-svg {
	width: 23px;
	height: 23px;
}
.container-hotel-box img:hover + .overlay {
	opacity: 1;
}
.container-hotel-box {
	display: flex;
	justify-content: flex-end;
}

.hotel-img {
	height: 29px;
	cursor: pointer;
	border: 0.4px solid #626367;
}
.hotel-img:hover {
	border: 0.4px solid #000000;
}
.hotel-img:active {
	border: none;
}
.vector-img {
	margin: 0 0px -20px 0px;
}
/* ////////////////////////////////////////////////// */
/* SelectorParlourScore */
/* ///////////////////////////////////////////////// */
.requests-ScoreBox {
	display: flex;
	align-items: baseline;
}
.container-ScoreBox {
	@media screen and (min-width: $tablet) {
		margin-right: 22px;
	}
}
.close-svg {
	width: 8px;
	height: 8px;
}
.selectedScore {
	position: absolute;
	display: none;
	font-family: 'Segoe UI', sans-serif;
}
.overlayScore {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-content: space-between;
	width: 164px;
	height: 332px;
	background: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	color: #4c4c4c;
	border-radius: 5px;
	font-family: 'Segoe UI', sans-serif;
	top: 60px;
	padding: 5px 19px 11px 15px;
	z-index: 1;
	margin-left: -65px;
}
.overlayScore:after,
.overlayScore:before {
	content: '';
	border: solid transparent;
	position: absolute;
	left: 70px;
	bottom: 100%;
}
.overlayScore:after {
	border-bottom-color: #fff;
	border-width: 8px;
	margin-left: 0;
}
.overlayScore:before {
	border-bottom-color: rgba(0, 0, 0, 0.25);
	border-width: 9px;
	margin-left: -1px;
}

.close-img {
	margin: 5px 0 5px 0;

	cursor: pointer;
}
.close-img svg {
	float: right;
}

.header-ScoreBox {
	margin-top: 10px;
	padding-top: 0%;
	color: #00ff19;
	font-weight: 600;
	font-size: 13px;
	text-align: center;
	width: 100%;
	margin-bottom: 10px;
}
.dislike-h {
	color: #ff6a6a;
}
.main-ScoreBox {
	font-weight: 400;
	font-size: 11px;
	margin-top: 12px;
	line-height: 15px;
	color: rgba(0, 144, 255, 1);

	@media screen and (min-width: $tablet) {
		color: rgba(76, 76, 76, 1);
	}
}
.text-ScoreBox {
	font-weight: 300;
	font-size: 11px;
	margin: 9px 0 20px;
	line-height: 12px;
	color: rgba(0, 144, 255, 1);

	@media screen and (min-width: $tablet) {
		color: rgba(76, 76, 76, 1);
	}
}
.value-ScoreBox {
	font-weight: 700;
	font-size: 11px;
	display: flex;
	justify-content: space-between;
	width: 164px;
	padding-left: 34px;
	padding-bottom: 10px;
}
.dagger {
	color: #ff6a6a;
}

.value-ScoreBox.dagger {
	margin-bottom: 5px;
	margin-top: 20px;
}
.good {
	color: #00ff19;
}
.price-ScoreBox {
	color: #4c4c4c;
}
.parlourHotel-container {
	position: relative;

	display: flex;

	border: 0.5px solid transparent;
	border-radius: 5px;
}
.parlourHotel-container:hover {
	border-color: #a5a5a5;
}

.city-cur {
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $tablet) {
		margin-right: 75px;
		margin-bottom: -3px;
	}

	.city-name {
		margin-top: 1px;
		font-weight: 400;
		font-size: 10px;
		margin-bottom: 2px;
	}

	.city-stars {
		font-family: 'Segoe UI', sans-serif;
		height: 17px;
		// width: 34px;
		box-shadow: 0px 0px 4.8421px rgba(0, 0, 0, 0.25);
		border-radius: 5px;
		color: #0090ff;
		font-weight: 900;
		margin-bottom: -5px;
		text-align: center;
		padding: 0px 3px 0 3px;
		font-size: 12px;
	}
}
.deposit-btn {
	height: 30px;
	width: 154px;
	background: #fff;
	border: 1px solid rgba(0, 144, 255, 1);
	border-radius: 10px;
	color: rgba(0, 144, 255, 1);
	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	margin-top: 15px;

	@media screen and (min-width: $tablet) {
		color: #656565;
	}
}
.deposit-btn:hover {
	border: 1px solid rgba(0, 107, 190, 1);
	color: rgba(0, 107, 190, 1);

	@media screen and (min-width: $tablet) {
		border: 1px solid #d0d0d0;
		color: #d0d0d0;
	}
}
.deposit-btn:active {
	border: 1px solid rgba(0, 107, 190, 1);
	color: rgba(0, 107, 190, 1);

	@media screen and (min-width: $tablet) {
		border: 1px solid #000000;
		color: #000000;
	}
}
.deposit-btn-style {
	display: flex;
	justify-content: center;
	bottom: 50px;
}
.parlourSetting-list {
	position: absolute;
	top: 94%;
	z-index: 1;

	@media screen and (max-width: $pre-tablet) {
		right: 0;
	}

	@media screen and (min-width: $tablet) and (max-width: 939px) {
		right: 60%;
	}

	@media screen and (min-width: 940px) and (max-width: $pre-desktop) {
		right: 0;
	}

	@media screen and (min-width: $desktop) {
		left: 0;
	}

	display: flex;
	flex-direction: column;
	justify-content: space-around;

	padding: 8px;

	font-family: 'Segoe UI', sans-serif;
	list-style: none;

	background-color: white;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
.parlourSetting-text {
	font-size: 11px;
	color: #282222;
	font-weight: 400;
	margin: auto 2px auto 15px;
}

.hotel-img-wrap {
	display: flex;
	gap: 5px;
}
.hotel-img-score {
	cursor: pointer;
	height: 29px;
}
.hotel-img-score:hover {
	border: 0.4px solid #000000;
}
.hotel-img-score:active {
	border: none;
}

.settings-link {
	display: flex;
	align-items: center;
	text-decoration: none;

	padding: 4px 8px;

	cursor: pointer;

	&:hover {
		color: #0090ff;
		background-color: white;

		.parlourSetting-text {
			color: #0090ff;
			font-weight: 700;
			background-color: white;
		}

		img.setting-logo {
			opacity: 0.7;
		}
	}
}

.setting-selected {
	display: flex;
	width: 209px;
	height: 36px;
}

.finance {
	background-image: url('../../assets/images/header/finance.svg');
}

.setting-logo-wrapper {
	margin: auto 0;
}

.setting-selected:last-child {
	padding-left: 8px;
}
/* ////////////////////////////////////////////////// */
/* SelectorMainUser */
/* ///////////////////////////////////////////////// */
.mainUser-header {
	display: flex;
	align-items: center;
}
.mainUser-btn {
	position: absolute;
	right: 0;
	left: 5%;
	margin: 0 auto;
	border: none;
	width: 143px;
	height: 32px;
	color: #fff;
	background-color: #0090ff;
	border-radius: 15px;
	font-family: 'Segoe UI', sans-serif;
	font-size: 13px;
	font-weight: 700;
}
.mainUser-btn:hover {
	background-color: #6dbffe;
}
.mainUser-btn:active {
	background-color: #006bbe;
}
/* ////////////////////////////////////////////////// */
/* SelectorLocation */
/* ///////////////////////////////////////////////// */
.location-header {
	display: flex;
	gap: 20px;
}
.location-search-wrap {
	display: flex;
	align-items: center;

	// position: absolute;
	// z-index: 11;
	// right: 0;
	// left: 5%;
	// top: 20%;
	// margin: 0 auto;
}
.location-search {
	display: flex;
	align-items: center;
	min-width: 300px;

	background-color: #ebedfe;
	border-radius: 5px;

	padding: 4px;

	@media screen and (min-width: $tablet) {
		padding: 6px;
	}

	img {
		width: 16.5px;
		height: 16.5px;
		margin-left: 5px;
	}
}
.location-search:hover {
	border: 1px solid #0090ff;
	.location-input {
		background: #fff;
	}
}

.location-input {
	// position: absolute;
	// right: 0;
	// left: 5%;
	// top: 50%;
	margin: 0 auto;
	width: 242px;
	height: 24px;
	border: none;
	outline: none;
	background-color: #f4f4f4;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	color: #626367;
	font-size: 12px;
	font-weight: 400;
	padding-left: 10px;
	background: #d0d0d0;
}
.location-input_search-location {
	margin: 0 auto;
	width: 100%;
	max-width: 242px;
	// height: 16px;
	// height: 24px;
	padding: 3px;

	@media screen and (min-width: $tablet) {
		// height: 24px;
		padding: 3px;
	}

	border: none;
	outline: none;
	background-color: #f4f4f4;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
	border-radius: 10px;
	color: #626367;
	font-size: 12px;
	font-weight: 400;
	padding-left: 10px;
	background: #fff;
}
.location-input:focus {
	background: #fff;
}
.location-input,
select,
textarea {
	color: #626367;
	font-size: 12px;
	font-weight: 600;
}
.location-input-object {
	position: relative;
	z-index: 11;
	width: 292px;
	// height: 268px;
	background: #fff;
	display: flex;
	flex-direction: column;
	// align-content: space-between;
	// justify-content: space-between;
	// padding: 10px 37px 10px 13px;
}

.location-input-object_component {
	display: flex;
	align-items: center;
	width: 292px;
	height: 67px;
	padding: 10px;
	padding-right: 37px;
}
.location-input-object_component:hover {
	background: rgba(0, 107, 190, 0.1);
}
.location-input-object_component:active {
	background: rgba(0, 107, 190, 0.22);
}
.location-input-object_img {
	width: 52px;
	height: 46px;
	border-radius: 10px;
}
.location-input-object_text {
	color: #626367;
	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	line-height: 16px;
	font-weight: 700;
	margin-left: 19px;
}
.location-input-object_text_span {
	color: #8e8e8e;
	font-size: 11px;
	font-weight: 400;
}
.booking-container {
	display: flex;
	align-items: center;
}
.location-img {
	width: 52px;
	height: 100%;

	@media screen and (min-width: $tablet) {
		height: 46px;
	}

	border-radius: 3px;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
	margin-right: 21px;
}
.location {
	color: #4c4c4c;
	font-weight: 600;
	font-size: 15px;
	font-family: 'Segoe UI', sans-serif;
}
.city-location {
	font-size: 24px;
	margin-right: 5px;
	/* font-weight: 400; */
}
.country-location {
	font-size: 14px;
	font-weight: 400;
	color: #626367;
}
.booking-location {
	font-size: 0.7rem;
	// white-space: nowrap;
}
/* ////////////////////////////////////////////////// */
/* SelectorUser */
/* ///////////////////////////////////////////////// */

.ava-img {
	margin-right: 5px;
	width: 23px;
	height: 23px;
	// border: 0.4px solid #656565;
	border-radius: 3px;
}
.ava-img:hover {
	border: 0.4px solid #000000;
}
.ava-img:active {
	border: none;
}
.imgs-wrap {
	height: 33px;
	width: 33px;
	padding: 0px;
	border-radius: 2px;
	align-items: center;
	display: flex;
	justify-content: center;
	img {
		width: 22px;
		height: 22px;
	}
}
.imgs-wrap:hover {
	background: rgba(196, 196, 196, 0.3);
}

/* ////////////////////////////////////////////////// */
/* SelectorAgent */
/* ///////////////////////////////////////////////// */
.btn-agent {
	width: 195px;
	height: 37px;
	background: #0090ff;
	border: none;
	border-radius: 3px;
	color: #fff;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 700;
	font-size: 15px;
}
.btn-agent:hover {
	background: #6dbffe;
}
.btn-agent:active {
	background: #006bbe;
}
