@import 'styles/common/variables';

$color-border-hover: #0090ff;
$color-border: #a5a5a5;
$color-text-input-location: #8e8e8e;
$color_hover_background: #e5e5e5;
$color_active_background: #e5e5e5;
$color-point-location-def: #aeafbc;
$color-point-location-active: #006bbe;

%text-region-list-style {
	font-family: 'Segoe UI', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	color: $color-text-input-location;
}
%text-location-list-style {
	font-family: 'Segoe UI', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 13px;

	color: #626367;
}

%body__border-hover-input {
	&:hover {
		outline: none;
		border: 1px solid $color-border-hover;
	}

	&:focus {
		background-color: #fff;

		border: 1px solid $color-border-hover;
		border-radius: 0;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}
%scroll-container {
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-snap-type: y mandatory;
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(183, 189, 198);
		height: 15px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}
	&::-webkit-scrollbar-track {
		background: inherit;
	}
	&::-webkit-scrollbar-track-piece:start {
		margin-top: 10px;
	}
	&::-webkit-scrollbar-track-piece:end {
		margin-bottom: 10px;
	}
}

.container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 242px;
}
.item_container {
	position: relative;
	display: flex;
	flex-direction: column;

	width: 100%;
}

.input_box {
	position: relative;

	height: 38px;

	&::before {
		content: '';
		background-image: url('../../../../../assets/images/search/search 90.svg');
		background-repeat: no-repeat;
		position: absolute;
		width: 20px;
		height: 20px;
		left: 18px;
		top: 11px;
	}
}
.input {
	width: 100%;
	height: 100%;

	padding: 11px 55px;

	font-family: 'Segoe UI', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 13px;

	color: #626367;

	outline: none;

	border: 1px solid $color-border;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;

	@extend%body__border-hover-input;
	background-color: #f4f4f4;

	&::placeholder {
		color: rgba(98, 99, 103, 0.5);
	}
}

.clear_inputBtn {
	position: absolute;

	top: 11px;
	right: 18px;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 17px;
	height: 17px;

	outline: none;

	border-radius: 100%;
	border: 0.7px solid #4c4c4c;
	background-color: #f4f4f4;
}
.color_btn {
	background-color: #ffffff;
}

.list_container {
	position: fixed;
	top: 50px;

	display: flex;
	flex-direction: column;

	width: 242px;
	height: 279px;

	padding-bottom: 24px;

	background: #ffffff;

	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	overflow-y: auto;
	animation: 0.3s ease-in-out 0s 1 normal none running animation-modalWindow;
	box-sizing: border-box;
	-webkit-animation: 0.3s ease-in-out 0s 1 normal none running animation-modalWindow;

	@extend%scroll-container;

	z-index: 20;
}
@keyframes animation-modalWindow {
	0% {
		transform: scale(0.95);
	}
	66% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
.list {
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;

	box-sizing: border-box;
	list-style-type: none;
}

.list_item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;

	padding: 0 12px 0 24px;

	box-sizing: border-box;
	margin: 5px 0px 0px 0px;
	overflow: hidden;
	height: 52px;
	cursor: pointer;
	scroll-snap-align: start;
	scroll-snap-stop: always;
	&:hover,
	&:active,
	&:focus {
		background-color: $color_active_background;
	}
	// @extend%point-location-icon;
}

.location_text {
	@extend %text-location-list-style;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.region_text {
	@extend %text-region-list-style;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.country_text {
	@extend %text-region-list-style;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
