@import 'styles/common/variables';
@import 'styles/common/breakpoints';

.currencyPickerBtn {
	width: 131px;
	@media screen and (max-width: $pre-tablet) {
		width: 103px;
		padding-right: 0;
	}
}
