@import 'styles/common/_breakpoints';

.propose__card {
	width: 926px;
	height: 141px;
	display: flex;
	justify-content: flex-start;
	margin: 0 auto;
	margin-bottom: 5px;
	padding-left: 6px;
	padding-right: 13px;
	background-color: #fff;

	border-radius: 5px;
	cursor: pointer;
	&:hover,
	&:focus {
		border: 1px solid #4c4c4c;
	}

	&:last-child {
		margin-bottom: 0px;
	}
}

.propose_card__container {
	padding-right: 0;

	@media (max-width: 934px) {
		.adaptive_BedWrapper {
			position: absolute;

			@media (max-width: 934px) {
				position: absolute;
				top: 33%;
				left: 75%;
				transform: translate(-50%, -50%);
				gap: 2rem;
			}
			@media (max-width: 616px) {
				left: 50%;
				top: 80%;
			}
		}
	}
	@media (max-width: 616px) {
		.locationAdaptive {
			display: none;
		}
	}
}
.card__price_action {
	display: flex;
	flex-direction: column;
	// justify-content: center;
	align-items: flex-start;
	margin-top: 12px;
	width: 100px;
}
.optionsButton_container,
.optionsButton_container_paid {
	padding: 0 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.optionsButton_container_paid {
	background: #535252;
	border-radius: 5px;
	color: #ffffff;
}
.card__price {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	margin-bottom: 14px;
	display: inline-block;
	display: inline-block;
	padding-left: 4px;
	color: #656565;
}
.wrap {
	margin-left: 60px;
	margin-right: 34px;
}
.card__photo {
	margin-top: 14px;
	margin-left: 10px;
}
.adaptive_BedWrapper {
	width: 218px;
	display: flex;
	padding-top: 14px;
	padding-left: 3px;
	padding-right: 4px;
	flex-direction: row;
	gap: 10px;
	overflow-x: auto;

	// Firefox
	scrollbar-width: thin;
	scrollbar-color: #0090ff #ffffff;
	.bed__options {
		// max-height: 132px;

		.propose_card__container {
			padding-right: 0;
			margin-bottom: 1px;
		}
	}
}

@media (max-width: 934px) {
	.propose__card {
		width: 100%;
		margin: 0 auto;
		border: 0px;
	}
}
@media (max-width: 616px) {
	.propose__card {
		//border: 1px solid red;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		min-height: 530px;
		position: relative;
		min-width: 400px;
		background-color: #ebedfe;

		// padding: 0 0 50rem 0;
		//justify-content: space-between;
		@media (max-width: 410px) {
			min-width: 350px;
		}

		.locationAdaptive {
			display: none;
			border: 1px;
		}
	}
	.optionsButton_container,
	.optionsButton_container_paid {
		position: absolute;
		right: 5%;
		top: 0%;
	}
	.card__photo {
		position: absolute;
		right: 10%;
		top: 22%;
	}
}
@media (max-width: $mobile) {
	.propose__card {
		min-width: 300px;
	}
	.card__photo {
		right: 5%;
		top: 22%;
	}
	.optionsButton_container,
	.optionsButton_container_paid {
		right: 0;
	}
}
