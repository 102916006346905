@import '/src/styles/common/breakpoints';

.section {
	margin-top: 10px;
	padding-left: 9px;
	padding-right: 9px;
	padding-bottom: 30px;

	@media screen and (min-width: $tablet) {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 24px;
	}

	@media screen and (min-width: $desktop) {
		justify-content: center;
		margin-top: 24px;
		width: 100%;
	}
}
.description {
	@media screen and (min-width: $tablet) {
		padding-right: 44px;
	}

	@media screen and (min-width: $desktop) {
		padding-right: 31px;
	}
}
