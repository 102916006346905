.checkTime {
  &__container {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #4c4c4c;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    &:first-child {
      margin-bottom: 9px;
    }
  }

  &__text {
    margin-right: 9px;
    width: 70px;
  }

  &__icon {
    width: 15px;
    height: auto;
    margin-right: 8px;
    fill: #505050;
  }
}
