.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 1200;
}

.modal {
	position: relative;
	max-width: calc(100vw - 48px);
	max-height: calc(100vh - 24px);
}
.container {
	width: 243px;
	height: auto;
	background-color: rgba(153, 196, 255, 1);
	color: rgba(255, 255, 255, 1);
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.25);
}
.close {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}
