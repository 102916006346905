@import 'styles/common/variables';

.subheaderJustLogined {
	display: flex;
	// justify-content: center;
	align-items: center;
	// margin-right: 8px;
	// padding: 8px 12px 4px 8px;

	&__icon {
		width: 29px;
		height: 29px;
		background-color: $colors-bg-light-grey;
		background-image: url('/assets/icons/Header/SubheaderHotelier/defaultIconHotelier.svg');
		background-repeat: no-repeat;
		background-position: center;
		margin-right: 11px;
		@media screen and (max-width: $pre-tablet) {
			margin-right: 0;
		}
	}

	&__text,
	&__text p {
		font-family: $font-segoe;
		font-weight: 400;
		font-size: 11px;
		line-height: 1.33;
		margin-right: 15px;
		width: 120px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;
	}
}
