@import 'styles/common/breakpoints';

$cubic: cubic-bezier(0.4, 0, 0.2, 1);

.header_wrap {
	display: flex;
	font-family: 'Segoe UI';
	position: fixed;
	width: 100%;
	z-index: 2;

	&_textContainer {
		display: flex;
		flex-direction: column;
		@media screen and (min-width: $tablet) {
			margin-left: 2px;
			flex-direction: row;
			//	justify-content: space-around;
		}
	}
}

.header_btn_wrap_left {
	display: flex;
	justify-content: space-around;
	background: #fff;
	width: 50%;
	height: auto;
	border-radius: 5px;
	font-size: 10px;
	font-weight: 700;
	line-height: 13px;
	color: #a5a5a5;
	padding: 8px 1px;
	border: 1px solid transparent;
	//	margin-top: -40px;

	cursor: pointer;
	.btn_nb {
		font-size: 11px;
		color: rgba(0, 107, 190, 0.6);
		margin-left: 10px;
	}

	@media screen and (min-width: $tablet) {
		display: flex;
		align-items: center;
		height: 29px;
		font-size: 12px;
		line-height: 16px;
		padding: 8px 35px 8px 6px;
	}
}

.header_btn_wrap_right {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	width: 50%;
	height: auto;
	border-radius: 5px;
	font-size: 10px;
	font-weight: 700;
	line-height: 13px;
	color: #a5a5a5;
	padding: 8px 12px;
	border: 1px solid transparent;
	cursor: pointer;
	//margin-top: -40px;

	.btn_nb {
		font-size: 11px;
		color: rgb(0, 107, 190);
		margin-left: 10px;
	}

	@media screen and (min-width: $tablet) {
		height: 29px;
		font-size: 12px;
		font-weight: 700;
	}
}

.header_btn_wrap_left:hover .header_btn_wrap_right:hover {
	border-color: #626367;
}

.header_btn_wrap_left:active .header_btn_wrap_right:hover {
	border-color: #000;
}

.info_wrap {
	display: flex;
	justify-content: space-between;

	@media screen and (min-width: $tablet) {
		margin-right: 25px;
	}
}

.header_btn_wrap_active_left {
	display: flex;
	justify-content: space-around;
	background: #fff;
	width: 50%;
	height: auto;
	border-radius: 5px;
	font-size: 10px;
	font-weight: 700;
	line-height: 13px;
	color: #535252;
	padding: 8px 1px;
	border: 1px solid #626367;
	cursor: pointer;
	//margin-top: 0;

	@media screen and (min-width: $tablet) {
		padding: 8px 35px 8px 6px;
	}

	.btn_nb {
		font-size: 11px;
		color: rgb(0, 107, 190);
		margin-left: 10px;
	}
	&.header_btn_wrap_left {
		margin-top: 0;
	}

	@media screen and (min-width: $tablet) {
		display: flex;
		//justify-content: space-between;
		align-items: center;
		height: 29px;
		font-size: 12px;
		font-weight: 700;
	}
}

.header_btn_wrap_active_right {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	width: 50%;
	height: auto;
	border-radius: 5px;
	font-size: 10px;
	font-weight: 700;
	line-height: 13px;
	color: #535252;
	padding: 8px 12px;
	border: 1px solid #626367;
	cursor: pointer;
	//margin-top: 0;
	.btn_nb {
		font-size: 11px;
		color: rgba(0, 107, 190, 0.6);
		margin-left: 10px;
	}

	@media screen and (min-width: $tablet) {
		height: 29px;
		font-size: 12px;
		font-weight: 700;
	}
}
.plusMobile {
	//position: relative;
	border-radius: 5px;
	box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	background: #fff;
	text-align: center;
	padding: 4px 4px;
	width: 19px;
	height: 19px;
	//margin-top: -5px;

	@media screen and (min-width: $tablet) {
		padding: 5px 5px;
		width: 25px;
		height: 25px;
		margin-top: 0;
	}
	/*@media screen and (min-width: $desktop) {
		position: absolute;
		bottom: 6px;
		left: 10px;
		width: 15px;
		height: 15px;
		padding: 0px 1px 0px 1px;
	}*/

	&:hover ~ .plusMobile_notification {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.5s;
		z-index: 120;
	}
	&:active ~ .plusMobile_notification {
		visibility: hidden;
		opacity: 0;
	}

	&_icon {
		transition: stroke 250ms $cubic;

		&:hover {
			stroke: rgba(0, 144, 255, 0.8);
		}
		&:active {
			stroke: rgba(0, 144, 255, 0.6);
		}
	}
}

.plusMobile_notification {
	opacity: 0;
	transition: opacity 0.3s;
	visibility: hidden;
	transition-property: opacity, visibility;
	position: absolute;
	left: 2px;
	top: 30px;
	z-index: 120;
	width: 98px;
	height: 34px;
	background: #000000;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 11px;
	line-height: 1.3;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 3px;

	@media screen and (min-width: $tablet) {
		width: 112px;
		height: 38px;
	}
}

.current_booking_text {
	width: 100px;
	@media screen and (min-width: $tablet) {
		width: auto;
	}
}
.current_booking_dots {
	margin-left: 24px;
	align-self: center;
	@media screen and (min-width: $tablet) {
		align-self: flex-end;
	}
}
.btn_img_dot:not(:last-child) {
	margin-right: 8px;
	@media screen and (min-width: $tablet) {
		margin-right: 14px;
	}
}

.right_default_container {
	padding-top: 50px;

	@media screen and (min-width: $tablet) {
		padding-top: 50px;
	}
}

.right_default_block {
	width: 252px;
	background: #fff;
	margin-left: auto;
	padding: 13px;
	margin-right: 10px;

	@media screen and (min-width: $tablet) {
		width: 340px;
		margin-left: 50%;
		padding: 20px;
		margin-right: 20px;
	}

	@media screen and (min-width: $desktop) {
		width: 501px;
		margin-left: 50%;
		padding: 20px 30px;
	}
}
.right_default_block_content {
	border: 0.2px solid rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	margin: 0px auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 12px;

	@media screen and (min-width: $tablet) {
		padding: 10px 20px;
	}

	@media screen and (min-width: $desktop) {
		padding: 15px 30px;
	}
}
.right_default_block_text {
	color: #535252;
	font-family: 'Segoe UI';
	width: 280px;
}
.right_default_block_header {
	font-weight: 600;
	font-size: 10px;
	margin-bottom: 10px;

	@media screen and (min-width: $tablet) {
		font-weight: 600;
		font-size: 11px;
	}
}

.right_default_block_main {
	font-weight: 350;
	font-size: 9px;
	@media screen and (min-width: $tablet) {
		font-size: 10px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 11px;
	}
}
// ===================left_default_block======================//
.left_default_block {
	//padding-top: 45px;
	margin: 0 auto;

	@media screen and (min-width: $tablet) {
		padding-left: 55px;
	}
	@media screen and (min-width: $desktop) {
		padding-left: 227px;
	}
}
.left_default_block_button {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 57px;
	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
	background: #0090ff;
	border-radius: 5px;
	padding: 10px 25px;
	border: none;
	&::before {
		display: inline-block;
		content: '';
		background-image: url('../../../assets/images/hoteliers/Plus.svg');
		background-repeat: no-repeat;
		background-color: #fff;
		background-position: right 11px bottom 11px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		position: relative;
		left: -15px;
		right: 0px;
		top: 0px;
		bottom: 0px;
		border-radius: 5px;
		width: 36px;
		height: 36px;
	}
	&::after {
		display: inline-block;
		content: '';
		background-image: url('../../../assets/images/hoteliers/WhiteHouse.svg');
		background-repeat: no-repeat;
		position: relative;
		left: 7px;
		right: 0px;
		top: -3px;
		bottom: 0px;
		width: 27px;
		height: 29px;
	}
	&:hover {
		background: rgba(0, 144, 255, 0.5);
	}
	&:active {
		background: rgba(0, 107, 190, 0.5);
		outline: 1px solid #006bbe;
	}
}

.btn__fetch {
	display: flex;
	margin-left: 61px;
	justify-content: center;
	outline: none;
	cursor: pointer;
	font-size: 14px;
	line-height: 1;
	border-radius: 500px;
	transition-property: background-color, border-color, color, box-shadow, filter;
	transition-duration: 0.3s;
	border: 1px solid transparent;
	letter-spacing: 2px;
	min-width: 160px;
	text-transform: uppercase;
	white-space: normal;
	font-weight: 700;
	padding: 16px 14px 18px;
	color: #616467;
	box-shadow: inset 0 0 0 2px #616467;
	background: transparent;
	height: 48px;
	&:hover {
		color: #fff;
		background: rgba(110, 110, 110, 0.7);
	}
	&:active {
		color: #fff;
		background: rgba(110, 110, 110, 0.9);
	}
}
// ==================UserCardBooking=============================== //
.user_card {
	position: relative;
	padding: 5px 0px 5px 0px;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 46px;
	border-radius: 5px;
	background-color: #fff;

	@media screen and (min-width: $tablet) {
		padding: 6px 51px 20px 40px;
		margin-top: 36px;
		margin-left: 25px;
		margin-right: 20px;
	}

	@media screen and (min-width: $desktop) {
		padding: 15px 62px 15px 62px;
		margin: 0 auto;
		width: 1075px;

		margin-top: 35px;
		background: #ffffff;
	}
}
.user_card_button__add {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 166px;
	height: 24px;
	color: #fff;
	font-size: 9px;
	font-family: 'Segoe UI';
	font-weight: 700;
	border-radius: 5px;
	border: none;
	outline: none;
	background: rgba(0, 144, 255, 0.4);
	margin-bottom: 5px;
	margin-left: 14px;
	cursor: pointer;

	@media screen and (min-width: $tablet) {
		width: 222px;
		font-size: 10px;
		justify-content: space-around;
		margin-left: 0;
	}

	&:hover {
		background: #89c3ef;
		outline: 0.5px solid #0090ff;
	}
	&:active {
		background: #89b4d5;
		outline: 0.5px solid #0090ff;
	}
}
.user_card_button__plus {
	position: absolute;
	top: 25px;
	left: 14px;
	cursor: pointer;
}
.user_card_item {
	min-width: 307px;
	border: 1px solid rgba(0, 0, 0, 0.18);
	border-radius: 5px;

	&:not(:last-child) {
		margin-bottom: 10px;
	}

	@media screen and (min-width: $tablet) {
		min-width: 635px;
	}

	@media screen and (min-width: $desktop) {
		width: 970px;
	}
}

.user_card_box__img {
	position: relative;
	width: 60px;
	height: 97px;
	border-radius: 5px 0px 0px 5px;
	outline: 2px solid transparent;
	transition: outline 10s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
	cursor: pointer;

	&:hover {
		outline: 2px solid rgb(54, 53, 53);
	}

	@media screen and (min-width: $tablet) {
		width: 129px;
		height: 129px;
	}

	@media screen and (min-width: $desktop) {
		width: 170px;
		height: 170px;
	}
}

.user_card_img {
	object-fit: cover;
	object-position: left;
	border-radius: 5px 0px 0px 5px;
	width: 60px;
	height: 99px;
	transition: transform 10s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
	&:hover,
	&:focus {
		transform: scale(1.3);
	}

	@media screen and (min-width: $tablet) {
		width: 129px;
		height: 129px;
	}

	@media screen and (min-width: $desktop) {
		width: 170px;
		height: 170px;
	}
}
.user_card_box__img__active {
	border-radius: 5px 0px 0px 5px;
	overflow: hidden;
	width: 170px;
	height: 170px;
}

.user_card_img_container {
	display: flex;
	align-items: center;
	//padding-left: 16px;
}
.user_card_img_icon:first-of-type {
	margin-right: 8px;
}
.user_card_name__hotel {
	max-width: 100px;
	position: absolute;
	top: 12px;
	left: 9px;
	font-family: 'Segoe UI';
	font-size: 13px;
	font-weight: 600;
	text-align: center;
	color: #ffffff;

	@media screen and (min-width: $tablet) {
		font-size: 16px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 20px;
		line-height: 1.35;
	}
}

.user_card_raiting__hotel {
	cursor: pointer;
	position: absolute;
	top: 35px;
	right: 15px;
	font-weight: bold;
	font-size: 10px;
	line-height: 13px;
	text-align: center;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	background-color: #fff;
	border-radius: 5px;

	@media screen and (min-width: $tablet) {
		top: 9px;
		right: 8px;
	}

	@media screen and (min-width: $desktop) {
		top: 12px;
		right: 6px;
	}
}

.user_card_button__small__add {
	cursor: pointer;
	background: #ffffff;
	border-radius: 50%;
	position: absolute;
	bottom: 1px;
	right: 1px;
	width: 29px;
	height: 29px;
	border: none;
	background-image: url('../../../assets/images/hoteliers/Plus.svg');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $tablet) {
		width: 34px;
		height: 34px;
		right: 5px;
		bottom: 7px;
	}

	@media screen and (min-width: $desktop) {
		width: 40px;
		height: 40px;
		left: 125px;
	}

	&:hover::before {
		display: inline-block;
		content: '';
		position: absolute;
		width: 7px;
		height: 7px;
		top: 1px;
		right: 0;
		background: #ff6000;
		border: 0.5px solid #ffffff;
		border-radius: 50%;
	}

	&:active {
		background-color: rgba(255, 255, 255, 0.76);
	}
	&:hover ~ .user_card_notification {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.5s;
		z-index: 120;
	}
	&:active ~ .user_card_notification {
		visibility: hidden;
		opacity: 0;
	}
}

.user_card_button__edit {
	cursor: pointer;
	background: #ffffff;
	position: absolute;
	bottom: 2px;
	left: 2px;
	width: 25px;
	height: 25px;
	border-radius: 2.76px;
	border: none;
	background-image: url('../../../assets/images/hoteliers/redact.svg');
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (min-width: $tablet) {
		bottom: 10px;
		left: 10px;
	}

	&:hover {
		background-image: url('../../../assets/images/hoteliers/redactHover.svg');
	}

	&:active {
		background-image: url('../../../assets/images/hoteliers/redactActive.svg');
	}
	&:hover ~ .user_card_notification_edit {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.5s;
		z-index: 120;
	}
	&:active .user_card_notification_edit {
		visibility: hidden;
		opacity: 0;
	}
}

.user_card_notification {
	opacity: 0;
	transition: opacity 0.3s;
	visibility: hidden;
	transition-property: opacity, visibility;
	position: absolute;
	right: -87px;
	bottom: 60px;
	z-index: 120;
	width: 148px;
	height: 66.07px;
	background: #000000;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 10px;
}
.user_card_notification_edit {
	opacity: 0;
	transition: opacity 0.3s;
	visibility: hidden;
	transition-property: opacity, visibility;
	position: absolute;
	right: -57px;
	bottom: 60px;
	z-index: 120;
	width: 108px;
	height: 59px;
	background: #000000;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	color: #ffffff;
	border-radius: 10px;

	@media screen and (min-width: $tablet) {
		right: 10px;
	}

	@media screen and (min-width: $desktop) {
		right: 50px;
	}
}

.user_card_box {
	display: flex;
	position: relative;
}
.user_card__buttonsContainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-left: 7px;
	margin-top: 8px;

	@media screen and (min-width: $tablet) {
		margin-left: 17px;
	}

	@media screen and (min-width: $desktop) {
		margin-left: 33px;
		align-items: center;
	}
}
.user_card__verify {
	padding: 3px 15px 3px 15px;
	margin-bottom: 5px;
	border-radius: 45px;
	background-color: rgb(84, 0, 190);
	color: #fff;
	text-align: center;
	font-family: 'Segoe UI';
	font-size: 9px;
	font-weight: 400;
	line-height: 1.33;

	transition: background-color 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
	backdrop-filter: blur(10px);

	@media screen and (min-width: $tablet) {
		font-size: 11px;
	}

	@media screen and (min-width: $desktop) {
		margin-bottom: 7px;
		padding-top: 4px;
		padding-bottom: 5px;
		padding-left: 10px;
		padding-right: 43px;
		font-size: 11px;
		font-weight: 700;
		line-height: 1;
	}

	&:hover {
		background-color: rgba(84, 0, 190, 0.8);
	}
	&:active {
		background-color: rgba(84, 0, 190, 0.6);
	}

	&_link {
		text-decoration: none;
		color: #fff;
		transition: text-decoration 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
		cursor: pointer;
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}
.userCardItemVerify {
	overflow-x: hidden;
	-webkit-backdrop-filter: blur(7.5px);
	background: rgba(76, 76, 76, 0.28);
	backdrop-filter: blur(7.5px);
	-webkit-filter: blur(7.5px);
	filter: blur(7.5px);
}
.user_card_button__big__add {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #929394;
	border-radius: 45px;
	padding: 3px 15px;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 9px;
	line-height: 1.33;
	text-align: center;
	border: none;
	cursor: pointer;
	color: #ffffff;

	@media screen and (min-width: $tablet) {
		font-size: 14px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 20px;
	}

	&::after {
		display: inline-block;
		content: '';
		position: absolute;
		width: 5px;
		height: 5px;
		top: 14px;
		left: 41px;
		background: #ff6000;
		border: 0.5px solid #ffffff;
		border-radius: 50%;

		@media screen and (min-width: $tablet) {
			width: 7px;
			height: 7px;
		}

		@media screen and (min-width: $desktop) {
			width: 10px;
			height: 10px;
			left: 108px;
		}
	}

	@media screen and (min-width: $tablet) {
	}

	@media screen and (min-width: $desktop) {
		padding: 6px 38px;
	}

	&:hover {
		background-color: rgba(146, 147, 148, 0.7);
	}
	&:hover .user_card_icon__plus {
		fill: rgba(0, 144, 255, 0.7);
	}
	&:active {
		background-color: rgba(146, 147, 148, 0.5);
	}
	&:active .user_card_icon__plus {
		fill: rgba(0, 144, 255, 0.5);
	}
	p {
		width: 104px;

		@media screen and (min-width: $tablet) {
			width: 298px;
		}

		@media screen and (min-width: $desktop) {
		}
	}
}
.user_card_icon__box {
	background-color: #ffffff;
	position: relative;
	width: 28px;
	height: 28px;
	border-radius: 50%;
	margin-right: 30px;

	@media screen and (min-width: $tablet) {
		/*width: 45px;
		height: 45px;*/
	}

	@media screen and (min-width: $desktop) {
		width: 80px;
		height: 80px;
	}
}
.user_card_icon__plus {
	fill: #0090ff;
	width: 12px;
	height: 12px;
	position: absolute;
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;

	@media screen and (min-width: $tablet) {
	}

	@media screen and (min-width: $desktop) {
		width: 35px;
		height: 35px;
	}
}

// =============================RoomsHotelier======================//

.hotelier_roms__boxs_scroll {
	margin-left: 2px;
	margin-right: 2px;
	overflow-x: hidden;
	overflow-y: hidden;
	// scroll-snap-type: x mandatory;
	// scroll-behavior: smooth;
	// background-color: #fff;
	cursor: pointer;

	.user_card & {
		margin-left: 19px;
		margin-right: 17px;
		overflow-x: auto;
	}
	// @media screen and (min-width: $desktop) {
	// 	margin-left: 14px;
	// 	margin-right: 14px;
	// }

	&::-webkit-scrollbar {
		cursor: pointer;
		max-width: 0px;
		height: 16px;
		display: block;
	}

	&::-webkit-scrollbar-track {
		background-color: #aeafbc;
		border-bottom: 7px solid #fff;
		border-top: 7px solid #fff;
		margin-left: 19px;
		margin-right: 19px;
		@media screen and (min-width: $desktop) {
			margin-left: 160px;
			margin-right: 160px;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: #0090ff;
		border-radius: 7px;
		border-top: 7px solid #fff;
		border-bottom: 7px solid #fff;
	}
}

.hotelier_roms__boxs_scroll_blur {
	overflow-x: hidden;
	-webkit-backdrop-filter: blur(7.5px);
	background: rgba(76, 76, 76, 0.28);
	backdrop-filter: blur(7.5px);
	filter: blur(7.5px);
}

.hotelier_roms__list {
	max-width: 787px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	row-gap: 10px;

	padding: 9px 0px 4px 0px;

	@media screen and (min-width: $tablet) {
		column-gap: 19px;
		row-gap: 26.5px;
		padding: 19px 58.5px 4px;
		justify-content: flex-end;
	}

	@media screen and (min-width: $desktop) {
		column-gap: 15px;
		row-gap: 24.5px;
		padding: 9px 15px 4px;
	}
	.user_card & {
		max-width: none;
		flex-wrap: nowrap;
		width: fit-content;
		column-gap: 16px;
		padding: 22px 3px 0px 3px;
	}

	& > * {
		flex: 1 0 calc(33.3333%);
		max-width: calc(33.3333%);
	}
}

.hotelier_roms__item {
	position: relative;
	width: 100%;
	max-width: 240px;
	border-radius: 5px;
	box-shadow: 0px 0px 6.5625px rgba(0, 0, 0, 0.3);
	overflow: visible;
	transition: outline 100ms $cubic;
	outline: 2px solid transparent;

	&.modal_open {
		position: fixed;
		z-index: 17;
		top: 50px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.hotelier_roms__item_hover {
	&:hover,
	&:focus {
		outline: 1px solid #6aff79;
	}
}

.hotelier_roms__item_active {
	z-index: 30;
	height: 82px;
	position: absolute;
	top: 40px;
	left: 50%;
	transform: translateX(-50%);
}

.hotelier_roms__box_bed_img {
	position: relative;
	background: #d4ebfb;
	height: 64px;

	@media screen and (min-width: $tablet) {
		height: 76px;
	}

	@media screen and (min-width: $desktop) {
		height: 86.25px;
	}
}

.hotelier_roms__box_number_room {
	width: 46px;
	height: 17px;
	position: absolute;
	top: 0;
	left: 0;
	background: #ffffff;
	box-shadow: 0px 0px 3.45395px 0.69079px rgba(0, 0, 0, 0.3);
	border-radius: 2.76316px;
}

.hotelier_roms__number_room {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 1.36;
	text-align: center;
	color: #4c4c4c;

	@media screen and (min-width: $tablet) {
		font-size: 11px;
	}

	@media screen and (min-width: $desktop) {
	}
}

.hotelier_roms__button_change_room {
	position: absolute;
	cursor: pointer;
	width: 16px;
	height: 16px;
	right: 0;
	top: 0;
	background: #ffffff;
	box-shadow: 0px 0px 3.45395px 0.69079px rgba(0, 0, 0, 0.3);
	border-radius: 2.76316px;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover .hotelier_roms__icon_change_room {
		fill: rgba(0, 144, 255, 0.7);
	}
	&:active .hotelier_roms__icon_change_room {
		fill: rgba(0, 144, 255, 0.5);
	}
}

.hotelier_roms__icon_change_room {
	width: 10px;
	height: 10px;
	fill: #0090ff;
}

.hotelier_roms__quantity_room {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 1.31;
	color: #787878;
}

.hotelier_roms__box_price {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 33px;
	padding-left: 109px;
	padding-right: 8px;
	padding-top: 2px;
	background: #ffffff;
	box-shadow: s 0px 0px 6.5625px rgba(0, 0, 0, 0.3);
	border-radius: 0px 0px 5px 5px;
}

.hotelier_roms__box_price_hostel {
	@extend .hotelier_roms__box_price;
}

.hotelier_roms__button_change_price {
	display: block;
	cursor: pointer;
	margin-right: 12px;
	border: none;
	background: rgba(0, 144, 255, 0.5);
	box-shadow: 0px 0px 5.53488px rgba(0, 0, 0, 0.25);
	border-radius: 4.42791px;
	width: 66px;
	height: 22px;
	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 9px;
	line-height: 1.3;
	text-align: center;
	color: #ffffff;

	@media screen and (min-width: $tablet) {
		width: 84px;
		height: 30px;
		font-size: 12px;
	}

	@media screen and (min-width: $desktop) {
	}

	&:hover {
		background: rgba(0, 144, 255, 0.4);
	}
	&:active {
		background: rgba(0, 144, 255, 0.3);
	}
	&_notverify {
		display: block;
		margin-right: 17px;
		border: none;
		background: rgba(0, 144, 255, 0.5);
		box-shadow: 0px 0px 5.53488px rgba(0, 0, 0, 0.25);
		border-radius: 4.42791px;
		width: 84px;
		height: 30px;
		font-family: 'Segoe UI';
		font-weight: 600;
		font-size: 12px;
		line-height: 1.3;
		text-align: center;
		color: #ffffff;
	}
}

.hotelier_roms__text_price {
	margin-right: 6px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 9px;
	line-height: 1.3;
	color: #535252;
	width: 48px;

	@media screen and (min-width: $tablet) {
		font-size: 10px;
	}

	@media screen and (min-width: $desktop) {
	}
}

.hotelier_roms__price {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;
	text-align: center;
	color: #535252;
}
.hotelier_roms__animalIcon {
	display: block;
	margin-right: 7px;
	margin-top: 5px;
	z-index: 1;
}
.hotelier_options_rooms__list {
	display: flex;
	background-color: rgb(255, 255, 255);
	//position: relative;
}

.hotelier_options_rooms__item1 {
	display: flex;

	align-items: center;
	justify-content: space-around;
	width: 240px;
	height: 100%;
	padding: 12px 8px 2px;
	background: #d4ebfb;
	min-height: 66.25px;

	@media screen and (min-width: $tablet) {
		min-height: 76px;
		padding: 15px 10px 2px;
	}

	@media screen and (min-width: $desktop) {
		padding: 25px 25px 20px;
		min-height: 86.25px;
	}
}

.hotelier_options_rooms__item2 {
	@extend .hotelier_options_rooms__item1;
	flex-direction: column;
	flex-basis: calc((100% - 8px) / 2);
	padding: 6.25px 13px;
	&:not(:last-child) {
		margin-right: 8px;
	}
}

.hotelier_options_rooms__item3 {
	@extend .hotelier_options_rooms__item2;
	flex-basis: calc((100% - 16px) / 3);
	padding: 2px 0px 0px 7px;

	@media screen and (min-width: $tablet) {
		padding: 7.25px 7px 7.25px 7px;
	}
}

.hotelier_options_rooms__item4 {
	@extend .hotelier_options_rooms__item2;
	flex-basis: calc((100% - 24px) / 4);
	padding: 8px 0px 0px 1px;

	@media screen and (min-width: $tablet) {
		padding: 12px 0px 1px 1px;
	}

	@media screen and (min-width: $desktop) {
		padding: 12px 0px 1px 1px;
	}
}

.hotelier_roms__box_icon {
	display: flex;
	align-items: center;
	justify-content: center;
}
.hotelier_roms__verify {
	position: absolute;
	top: 7px;
	left: 67px;
	padding-top: 4px;
	padding-bottom: 5px;
	padding-left: 10px;
	padding-right: 23px;
	border-radius: 45px;
	z-index: 1;

	background-color: rgb(84, 0, 190);
	color: #fff;
	text-align: center;
	font-family: 'Segoe UI';
	font-size: 9px;
	font-weight: 700;
	line-height: 1;
	transition: background-color 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;

	@media screen and (min-width: $tablet) {
		left: 147px;
		font-size: 11px;
	}

	@media screen and (min-width: $desktop) {
		left: 200px;
	}

	&:hover {
		background-color: rgba(84, 0, 190, 0.8);
	}
	&:active {
		background-color: rgba(84, 0, 190, 0.6);
	}

	&_link {
		text-decoration: none;
		color: #fff;
		transition: text-decoration 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
		cursor: pointer;
		&:hover,
		&:active {
			text-decoration: underline;
		}
	}
}

// =====================TYPE_ICON_BED========================//

.hotelier_roms__icon_single_bed_1_alone {
	width: 39px;
	height: 35px;
	margin-right: 19px;
}

.hotelier_roms__icon_single_bed_1_both {
	width: 39px;
	height: 35px;
	margin-right: 11px;
}

.hotelier_roms__icon_single_bed_2 {
	width: 28px;
	height: 25px;
	margin-right: 14px;
}

.hotelier_roms__icon_single_bed_3 {
	width: 28px;
	height: 25px;
	margin-right: 3px;
}

.hotelier_roms__icon_single_bed_4 {
	width: 22px;
	height: 19px;
	margin-right: 1px;
}

.hotelier_roms__icon_double_bed_1_alone {
	width: 52px;
	height: 42px;
	margin-right: 12px;
}

.hotelier_roms__icon_double_bed_1_both {
	width: 52px;
	height: 42px;
	margin-right: 16px;
}

.hotelier_roms__icon_double_bed_2 {
	width: 35px;
	height: 28px;
	margin-right: 10px;
}

.hotelier_roms__icon_double_bed_3 {
	width: 27px;
	height: 22px;
	margin-right: 5px;
}

.hotelier_roms__icon_double_bed_4 {
	width: 23px;
	height: 19px;
	margin-right: 2px;
}
.hotelier_options_rooms__icon_bed {
	width: 16px;
	height: 14px;
	fill: #787878;
	stroke-width: 0.5px;
	stroke: #535252;
}

// ======================================options_hostel_room========================
.hotelier_options_hostel_room {
	width: 232px;
	height: 100%;
	padding: 4px 13px 0.25px;
	background: #d4ebfb;

	@media screen and (min-width: $tablet) {
		width: 240px;
	}

	&__price {
		display: flex;
		flex-direction: column;
		align-items: center;
    padding: 2px;

    &:hover {
      background-color: #a9ebbd;
      border-radius: 3px;
      cursor: pointer;
    }
	}
	&__pricePerRoom {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 1px;
		padding-right: 1px;
		margin-bottom: 3px;
		width: 28px;
		height: 13px;
		color: rgba(0, 144, 255, 0.5);
		font-size: 10px;
		font-weight: 600;
		line-height: 1;
		background-color: #fff;
		font-family: 'Segoe UI';
	}
	&__showPricePerRoom {
		@extend .hotelier_options_hostel_room__pricePerRoom;
		position: absolute;
		top: 10px;
		padding-left: 2px;
		padding-right: 2px;
		width: auto;
	}
}

.hotelier_options_hostel_room__box_number {
	display: flex;
	align-items: flex-start;
	margin-bottom: 15px;
	margin-left: 47px;
}

.hotelier_options_hostel_room__text {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 1.2;
	color: #535252;
}

.hotelier_options_hostel_room__number {
	margin-right: 12px;
	margin-left: 4px;
}

.hotelier_options_rooms__icon_single_bed {
	width: 21px;
	height: 19px;
	fill: #787878;

	&.active {
		fill: #006bbe;
		background-color: #6aff79;
	}
}

.hotelier_options_hostel_room__box_list_bed {
	// max-width: 795px;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	&::-webkit-scrollbar {
		cursor: pointer;
		max-width: 12px;
		height: 2px;

		@media screen and (min-width: $tablet) {
			height: 12px;
		}
	}

	&::-webkit-scrollbar-track {
		background-color: #aeafbc;
		border-bottom: 4px solid #d4ebfb;
		border-top: 4px solid #d4ebfb;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #0090ff;
		border-radius: 5px;
		border-bottom: 4px solid #d4ebfb;
		border-top: 4px solid #d4ebfb;
	}
}

.hotelier_options_hostel_room__list {
	display: flex;
	//justify-content: center;
	padding-bottom: 1px;
}

.hotelier_options_hostel_room__item {
	//width: 21px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	&:not(:last-child) {
		margin-right: 4px;
	}
	// &:hover > .hotelier_options_hostel_room > .hotelier_options_rooms__icon_single_bed {
	// 	fill: #006BBE;
	// }

	// & .hotelier_options_rooms__icon_single_bed:nth-child(3n) {
	// 	fill: #ff6000;
	// }
	// &:active .hotelier_options_rooms__icon_single_bed {
	// 	fill: #000;
	// }
	// &:hover .hotelier_options_hostel_room__text {
	// 	color: #4c4c4c;
	// }
	// &:active .hotelier_options_hostel_room__text {
	// 	color: #000;
	// }
	&_notverify {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		&:not(:last-child) {
			margin-right: 4px;
		}

		&:hover .hotelier_options_rooms__icon_single_bed {
			fill: #006bbe;
		}
	}
}

.hotelier_options_hostel_room__item_active {
	& .hotelier_options_rooms__icon_single_bed {
		fill: #000;
	}
	& .hotelier_options_hostel_room__text {
		color: #000000;
	}
}

// ==============================Modal===================================
.suggest__close {
	display: block;
	width: 110px;
	height: 62px;
	padding: 0px 0px 0px 25px;
	position: absolute;
	top: 0;
	right: -120px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 900;
	font-size: 14px;
	line-height: 19px;
	border: 1px solid #7f7d7d;
  border-radius: 44px;
	color: #ffffff;
	cursor: pointer;
	background: transparent;
	z-index: 15;

	&_mobile {
		position: absolute;
		right: 6px;
		top: 4px;
		border: none;
		background-color: transparent;
		&_icon {
			width: 15px;
			height: 15px;
		}
	}

	&::before {
		content: '';
		background-image: url(../../MainPage/Assents/icon/clouse.svg);
		background-repeat: no-repeat;
		position: absolute;
		right: 65px;
		top: 22px;
		width: 25px;
		height: 25px;
	}

	&:hover {
		background: rgba(76, 76, 76, 0.12);
		backdrop-filter: blur(15px);
	}

	&:active {
		background: rgba(76, 76, 76, 0.22);
		backdrop-filter: blur(15px);
	}
}

.activeCards {
	border-radius: 0 0 5px 5px;
}
