.filter {
	&__container {
		position: relative;
		margin-top: 1px;
		margin-bottom: 2px;
		margin-left: 44px;
		
		display: flex;
		align-items: center;
		
		@media (min-width: 768px) and (max-width: 1199px) {
			margin-left: 0;
			justify-content: center;
		}
		@media (min-width: 320px) and (max-width: 767px) {
			width: 320px;
			margin: 0 auto;
			margin-top: 15px;
		}
		@media (max-width: 320px) {
			width: 320px;
			margin: 0 auto;
			margin-top: 15px;
		}
	}

	&__mobContainer {
		position: relative;
		display: flex;
		margin-left: 20px;
		margin-right: 15px;
	}

	&__sort {
		position: absolute;
		top: 5px;
	}
	
	&__options {
		margin-left: 28px;
	}

	&__row {
		display: flex;
		flex-wrap: wrap;

		&:nth-child(1) {
			margin-left: 83px;
		}
	}

	&.mobile {
        width: 99.9%;
    }
	
}
