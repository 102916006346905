@import 'styles/common/variables';

.hotelierLink {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 11px;

	padding-bottom: 6px 9px;

	width: 156px;
	height: 34px;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	color: $colors-text-primary;
	text-align: center;
	text-decoration: none;

	border: 0.5px solid transparent;
	border-radius: 5px;
	outline: none;

	&:hover {
		color: $colors-black;
		border-color: $colors-text-fourth;
	}

	&:focus {
		color: $colors-text-fourth;
		border-color: $colors-text-fourth;
	}

	@media screen and (min-width: $desktop) {
		margin-right: auto;
	}
}

.hotelierIcon {
	width: 13px;
	height: 15px;

	padding-bottom: 1px;
	pointer-events: none;
	transition: fill 250ms cubic-bezier(0.4, 0, 0.2, 1);

	.dark:hover {
		fill: rgba(208, 208, 208, 1);
	}

	.dark:active {
		fill: rgba(174, 175, 188, 1);
	}
}
.dark {
	color: $colors-white;
	transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1), border 250ms cubic-bezier(0.4, 0, 0.2, 1);

	&:hover {
		color: rgba(208, 208, 208, 1);
		border: 1px solid rgba(208, 208, 208, 1);
	}

	&:active {
		color: rgba(174, 175, 188, 1);
		border: 1px solid rgba(174, 175, 188, 1);
	}
}
