.container {
  font-family: 'Segoe UI';
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4c4c4c;

  width: 100%;

  padding: 8px 11px;
  padding-bottom: 41px;

  background: #ffffff;
  border-radius: 10px;
}

.title {
  font-weight: 900;
}

.text {
  font-weight: 300;
}
