.container {
	display: flex;
	// flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
	height: 91px;
	background: radial-gradient(50% 50% at 50% 50%, #ff6000 0%, rgba(241, 93, 4, 0.5) 100%);
	border: 1px solid #4c4c4c;
	border-radius: 20px;
	margin-bottom: 14px;

	@media (min-width: 320px) and (max-width: 767px) {
		margin-top: 3px;
		margin-bottom: 3px;
	}
}

.button {
	width: 346px;
	height: 56px;
	border-radius: 33px;
	opacity: 100%;

	background: #ffffff;
	border: none;
	cursor: pointer;

	display: flex;
	justify-content: center;
	align-items: center;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 21px;

	color: #ff6000;

	&:hover {
		background: rgba(0, 144, 255, 0.7);
	}

	&:active {
		background: rgba(0, 144, 255, 0.5);
	}

	@media (min-width: 320px) and (max-width: 767px) {
		width: 236px;
		height: 41px;
	}


}

// .wrapper {
// 	&__center {
// 		margin-left: 22px;
// 	}
// 	&__end {
// 		margin-left: 32px;
// 	}
// }
