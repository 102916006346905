@import 'styles/common/variables';

.selectUserType {
	//	@media screen and (min-width: $desktop) {
	padding: 4px;
	color: #eee;
	background: none;
	border: none;
	outline: none;

	&:hover {
		box-shadow: $box-shadow-modal;
	}

	& option {
		background-color: #282828;
		//	}
	}
}

.selectUserTypeAbsolute {
	display: none;
	/*position: absolute;
	top: 0;
	left: 0;

	width: 122px;
	padding: 4px;

	background: none;
	border: none;
	outline: none;

	&:hover {
		box-shadow: $box-shadow-modal;
	}

	& .option {
		background-color: lightgray;
	}*/

	@media screen and (min-width: $desktop) {
		display: block;
		position: absolute;
		top: 0;
		left: 0;

		width: 122px;
		padding: 4px;

		background: none;
		border: none;
		outline: none;

		&:hover {
			box-shadow: $box-shadow-modal;
		}

		& .option {
			background-color: lightgray;
		}
	}
}
