.wrapperMob{
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
}

.icon {
  width: 25px;
  width: 25px;
}

.title {
  font-family: 'Segoe UI';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #4c4c4c;
}

.list {
  margin-top: 23px;
}

.item {
  margin-bottom: 7px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}
.text {
  width: 170px;
  min-height: 23px;

  padding-left: 6px;
  margin-left: 10px;

  background: #ffffff;
  border-radius: 5px;

  display: flex;
  align-items: baseline;

  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #4c4c4c;
}
