@import 'styles/common/variables';

.personContainer {
	position: relative;

	display: flex;
	justify-content: space-between;

	@media screen and (min-width: $tablet) {
		min-width: 231px;
	}
}

%userCardButton {
	display: flex;
	flex-grow: 1;
	align-items: end;
	justify-content: space-between;
	// gap: 18px;

	background: none;
	border: none;
	border-radius: 5px;
}

.mobileUserCard {
	@extend %userCardButton;
	padding: 5px 7px 7px 8px;

	gap: 0;
}

.userCardButton {
	@extend %userCardButton;

	padding: 3px 19px 3px 10px;

	border: 1px solid transparent;
	@media screen and (max-width: $pre-tablet) {
		padding: 2px;
		margin-bottom: 0px;
	}
	&:hover {
		border: 1px solid #d0d0d0;
	}

	&.open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-color: #d0d0d0;
		border-bottom-color: transparent;

		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
	}
}

.userSettingsWrapper {
	position: absolute;
	top: 96%;
	@media screen and (max-width: $pre-tablet) {
		right: -66px;
	}
	@media screen and (min-width: $tablet) {
		left: 0;
	}
	z-index: 2;

	display: flex;
	flex-direction: column;

	width: 231px;

	background-color: $colors-white;
	box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.15);

	border: 1px solid #d0d0d0;
	border-top: none;

	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.userCardContent {
	display: flex;
	gap: 6px;
}

.dark {
	color: $colors-white;
	background-color: $colors-bg-dark;
	border: none;
	&:hover {
		border: none;
	}
}
