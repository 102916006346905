@import 'styles/common/variables';

.headerNobody {
  @media screen and (max-width: $pre-tablet) {
    margin-right: 4px;
  }

  @media screen and (min-width: $tablet) and (max-width: $pre-desktop) {
    margin-left: auto;
    margin-right: 25px;
  }

  @media screen and (min-width: $desktop) {
    flex-grow: 1;
  }
}

.headerNobodyContent {
  display: flex;
  gap: 21px;

  @media screen and (max-width: $pre-tablet) {
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (min-width: $tablet) and (max-width: $pre-desktop) {
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (min-width: $desktop) {
    flex-grow: 1;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
