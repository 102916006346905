.container {
	&__main {
		position: relative;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 20px;
	}

	&__usercard {
		padding: 25px;
		width: 100%;
		height: 204px;
		display: flex;
		justify-content: center;

		background: linear-gradient(
			180deg,
			rgba(196, 196, 196, 0) 0%,
			rgba(96, 96, 96, 0.1525) 50.83%,
			rgba(56, 56, 56, 0.215) 71.67%,
			rgba(0, 0, 0, 0.22) 100%
		);

		@media (min-width: 320px) and (max-width: 767px) {
			padding: 0;
			justify-content: center;
			height: 325px;
		}
	}
}
