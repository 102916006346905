@import 'styles/common/variables';
@import './rent-list-shared';

$p-v: 9px;

.rentList {
  @extend %rent-list;
}

.rentLink {
  @extend %link;
  cursor: pointer;
}

.rentOptionItem {
  @extend %rent-option-item;
}

.activeRentOptionIcon {
  @extend %active-rent-option-icon;
}
