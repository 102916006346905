@import 'styles/common/variables';

.settingsItemText {
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	font-family: $font-segoe;
	color: $colors-text-primary;

	.userSettingsLink:hover & {
		font-weight: 700;
		color: white;
	}
}

.settingsItem {
	&:last-child {
		padding-top: 15px;
	}
}
.settingsItem.dark > * {
	background-color: $colors-bg-dark;
	color: $colors-white;
}
.userSettingsLink {
	display: flex;
	flex-grow: 1;
	align-items: center;
	gap: 15px;

	width: 100%;
	padding: 10px 8px 10px 11px;

	@extend .settingsItemText;
	text-decoration: none;
	background: none;
	border: none;
	outline: none;

	&:focus,
	&:hover {
		font-weight: 700;
		color: $colors-white;
		background-color: #0090ff;
	}

	//*** this transition propetry makes flicker effect on drop-menu-link
	// @media (prefers-reduced-motion: no-preference) {
	// 	transition: 500ms $cubic;
	//
	// 	// transition-property: color, background-color;
	// }
}

.userSettingsButton {
	padding-top: 20px;
}

.settingsIcon {
	width: 18px;
	height: 18px;
	// color: #787878;

	.userSettingsLink:hover & {
		color: $colors-white;
	}
}

.iconExit {
	width: 17px;
	height: 17px;
	color: #626367;

	.userSettingsLink:hover & {
		color: $colors-white;
	}
}

.settingsIconWrapper {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 18px;
	height: 18px;
}

.loading {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(153, 149, 149, 0.5);
	z-index: 1000;
}
.dark {
	color: $colors-white;
}
