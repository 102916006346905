@import 'styles/common/variables';

.depositIndicator {
	position: absolute;
	display: flex;
	gap: 4px;

	right: 15px;
	top: 20px;
	.headerButton:hover,
	:focus {
		background: none;
	}

	@media screen and (min-width: $tablet) {
		position: relative;
		top: 0;
		right: 0;
	}
}

%iconThumbUp {
	width: 23px;
	height: 22px;
}

.thumbUp {
	@extend %iconThumbUp;
	color: $colors-thumb-up;
}

.thumbDown {
	@extend %iconThumbUp;
	color: rgba(255, 106, 106, 1);
	transform: rotateX(180deg);
}

.depositStatus {
	position: absolute;

	z-index: 1;
	width: 258px;
	font-family: $font-segoe;
	padding: 8px 26px 26px;

	//left: auto;
	right: 10px;
	top: 140%;
	background-color: rgba(41, 40, 40, 1);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

	@media screen and (min-width: $tablet) {
		top: 121%;
		left: 0;
		background-color: $colors-white;
	}
}
.closeButton_wrap {
	position: absolute;
	top: 2px;
	right: 5px;
	width: 25px;
	height: 25px;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	border-radius: 3px;
	border: transparent;
}

.closeButton_wrap:hover,
.closeButton_wrap:focus {
	background-color: rgba(76, 76, 76, 0.05);
}
.closeButton_wrap:active {
	background-color: rgba(76, 76, 76, 0.1);
}

.closeButton_icon {
	width: 13px;
	height: 13px;
}

.text-ScoreBox {
	font-size: 11px;
	font-weight: 300;
}
.toggleHasDepositBtn {
	position: absolute;
	top: -4px;
	left: -4px;
	z-index: 1;

	font-size: 0.6rem;

	padding: 0;

	width: 16px;
	height: 16px;
}

.tooltip {
	top: -8px;
	left: 18px;

	&::after {
		top: 50%;
		left: unset;
		right: calc(100% - 2px);

		transform: rotate(270deg);
	}
}

.balanceAmountMobile {
	color: inherit;
	@media screen and (min-width: $tablet) {
		color: rgba(76, 76, 76, 1);
	}
}
