.house {
	&__container {
		display: flex;
		justify-content: center;
		align-items: center;

		position: relative;
		width: 78px;
		height: 45px;
		border-radius: 5px;
		text-align: center;
		position: relative;
		margin-right: 6px;
		margin-top: 12px;
		margin-bottom: 12px;
		cursor: pointer;

		background: #5a5b5c80;
		transition: background 300ms linear;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 71px;
			height: 29px;
			margin-top: 0;
			margin-right: 12px;
		}

		&.active {
			background: #0085eb;
			&:hover {
				background: #0090ff;
			}
		}

		&:hover {
			background: #5a5b5c66;
		}

		&:active {
			background: #0090ff;
		}

		&:active .active {
			background: #0090ff;
		}

		&:hover > .house__overlay {
			visibility: visible;
			opacity: 1;
			transition-delay: 0.5s;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__overlay {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.5s;
		transition-property: opacity, visibility;

		position: absolute;
		top: -34px;
		left: -70%;
		z-index: 10;

		height: 23px;
		width: 141px;
		padding: 4px;

		background: #ffffff;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		line-height: 15px;
		text-align: center;

		color: #4c4c4c;
	}

	&__text {
		width: 70px;
		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 700;
		font-size: 11px;
		line-height: 15px;

		color: #ffffff;

		@media (min-width: 320px) and (max-width: 767px) {
			font-size: 9px;
			font-weight: 600;
			line-height: 11.97px;
		}
	}
}

.filter__container {
	display: flex;
	margin-left: 19px;
	
	@media (min-width: 320px) and (max-width: 767px) {
		width: 320px;
		flex-wrap: wrap;
	}
}
