.AppScrollContainer {
	position: relative;

	height: 100vh;
	&.overflowYHidden {
		overflow-y: hidden;
	}
	&.overflowXHidden {
		overflow-x: hidden;
	}

	&.overflowYScroll {
		overflow-y: scroll;
	}
}
