@import '../Assents/variables.scss';
@import 'styles/common/breakpoints';

$color-text: #4c4c4c;
$animation-x: 100px;
$animation-y: -90px;
$animationSuggest-x: 195px;
$animationSuggest-y: -34px;
$animationWork-x: 335px;
$animationWork-y: -130px;
$marginLeftInCalendar: 225px;
$marginRightInCalendarCheckBox1: 140px;

%text-style-checkbox {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
}
%text-style-checkbox-window {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 11px;
	// line-height: 15px;
	color: $color-text;
}
%suggest-block-style {
	// margin: 5px 15px;
	min-width: 197px;
	height: 40px;
	background: #ffffff;
	box-shadow: 0px 0px 8px 3px rgba(0, 107, 190, 0.3);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
}
%suggest-text-style {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	color: #656565;
}
%suggest-position-text {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 60px;
}
.calendar__section {
	display: block;
	position: relative;
	// height: 206px;
	width: 100%;
	// margin-top: 75px;
	// background: linear-gradient(
	// 	180deg,
	// 	rgba(196, 196, 196, 0) 0%,
	// 	rgba(96, 96, 96, 0.1525) 50.83%,
	// 	rgba(56, 56, 56, 0.215) 71.67%,
	// 	rgba(0, 0, 0, 0.22) 100%
	// );

	margin: 0;
	padding: 47px 0px 15px 0px;

	@media screen and (min-width: $tablet) {
		padding: 22px 0px 20px 0px;
	}
	@media screen and (min-width: $desktop) {
		padding: 12px 0px 12px 0px;
	}
}

.mobile_calendar_wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 293px;
	padding: 7px 21px;
	margin: 0 auto;
	overflow: hidden;
	border-radius: 30px;
	background-color: rgba(255, 255, 255, 1);
}

.mobile_calendar_main_btn {
	border-radius: 30px;
	outline: none;
	border: none;
	background-color: transparent;
	font-weight: 600;
	font-size: 12px;
	font-family: 'Segoe UI';
	font-style: normal;
	line-height: 1.33;
	color: $color-text;
}

.calendar_mobile_btn_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.mobile_calendar_dote {
	@extend .mobile_calendar_main_btn;
	font-size: 15px;
	margin: 0 5px;
}
.calendar_bottom_btn_wrapper {
	display: flex;
	margin: 0 auto;
	align-items: center;
}
.calendar_mobile_bedicon_wrapper {
	width: 15px;
	height: 12px;
	margin-right: 5px;
}
.calendar_mobile_dateicon_wrapper {
	width: 12px;
	height: 12px;
	margin-right: 5px;
}

.btn_location {
	@extend .mobile_calendar_main_btn;
	position: relative;
	display: block;
	margin: 0 auto 3px auto;
	width: auto;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surfaceMobile.svg);
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		left: -15px;
		width: 9px;
		height: 14px;
	}
}

.btn_bed {
	@extend .mobile_calendar_main_btn;
	// max-width: 80px;
	font-weight: 350;
	font-size: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.btn_date {
	@extend .mobile_calendar_main_btn;
	// max-width: 80px;
	font-weight: 350;
	font-size: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.calendar__section__main {
	@extend.calendar__section;
	padding: 16px 0px 16px 0px;
	background: linear-gradient(134.12deg, #ffffff 3.36%, #0090ff 89.09%),
		linear-gradient(91.77deg, rgba(255, 255, 255, 0.5) 5.43%, rgba(0, 107, 190, 0.5) 97.49%);
	@media screen and (min-width: $tablet) {
		padding: 22px 0px 20px 0px;
	}
	@media screen and (min-width: $desktop) {
		padding: 12px 0px 12px 0px;
	}
}

.calendar__general {
	// position: relative;
	padding: 0px 10px 0px 15px;
	margin-left: 0;

	@media screen and (min-width: $desktop) {
		display: flex;
		align-items: center;
		padding: 0;
		margin-left: var(--marginLeftInCalendar);
		margin-bottom: 12px;
	}
}

.calendar__general_suggest {
	@extend .calendar__general;
	@media screen and (min-width: $tablet) {
		margin-bottom: 10px;
	}
	@media screen and (min-width: $desktop) {
		margin-bottom: 21px;
	}
}
.calen__image {
	margin-top: 10px;
	margin-left: 250px;
}
.calendardouble__block {
	width: 350px;
	height: 158px;
	display: flex;
	align-items: center;
}
.calendar__block {
	position: relative;
	display: flex;
	justify-content: space-between;

	// height: 165px;
	background-color: #fff;
	padding: 20px 20px 20px 55px;
	// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px 3px rgba(0, 107, 190, 0.3);

	border-radius: 10px;
	@media screen and (min-width: $tablet) {
		width: 100%;
		max-width: 750px;
		margin: 0 auto 10px auto;
	}
	@media screen and (min-width: $desktop) {
		width: auto;
		min-width: 750px;
		margin: 0;
	}
}

// .calendar__block__suggest {
// 	@extend .calendar__block;
// 	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
// }

.calendar__box {
	position: relative;
	width: 100%;
	height: 100%;
}

.location__block {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	width: 266px;
}

.location__select {
	position: relative;
	padding-bottom: 3px;
	font-family: $text_font_family;
	font-weight: $ligth_font_weigth;
	font-size: 17px;
	//line-height: 23px;
	color: #4c4c4c;
	// width: 266px;
	border-bottom: 1px solid #dee0fc;
}

.icon__location {
	position: absolute;
	left: -28.6px;
	top: -2px;
	width: 14.4px;
	height: 35px;
	fill: rgba(0, 107, 190, 1);
}

.icon__location_img {
	position: absolute;
	left: -64px;
	top: -24px;
	width: 60px;
}

.location__input {
	width: 260px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: $text_font_family;
	font-weight: $ligth_font_weigth;
	font-size: 17px;
	line-height: 23px;
	color: #787878;

	border: none;
	outline: none;
}

.icon__arrow-down {
	position: absolute;
	left: 274px;
	top: 10px;
	width: 10px;
	height: 5.4px;
}

.icon__arrow-up {
	position: absolute;
	left: 274px;
	top: 10px;
	width: 10px;
	height: 5.4px;
	transform: rotate(180deg);
}

.location-search_container {
	position: absolute;
	top: 28px;
	left: 3px;
}

.room__select {
	margin-top: 22px;
	padding-bottom: 2px;
	font-family: $text_font_family;
	font-weight: $ligth_font_weigth;
	font-size: 13px;
	line-height: 17px;

	color: #787878;

	width: 266px;
	border-bottom: 1px solid #dee0fc;
}

.select__bedroom {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	height: 150px;
	padding: 4px;
	background: #fff;
	// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px 3px rgba(0, 107, 190, 0.3);
	border-radius: 10px;
	z-index: 30;
	& .icon__block:nth-child(3) {
		margin-right: 0;
	}
	& .icon__block:nth-child(5) {
		width: 105px;
		@media screen and (min-width: $tablet) {
			width: 112px;
		}
	}
	& .icon__block:nth-child(6) {
		width: 105px;
		margin-right: 30px;
		@media screen and (min-width: $tablet) {
			width: 112px;
			margin-right: 0;
		}
		@media screen and (min-width: $desktop) {
			margin-right: 20px;
		}
	}
	@media screen and (min-width: $tablet) {
		position: absolute;
		left: -1%;
		top: 68%;
		width: 557.4px;
		height: auto;
		padding: 11px;
		flex-wrap: nowrap;
		margin-bottom: 0;
	}
	@media screen and (min-width: $desktop) {
		left: -15%;
		top: 68%;
		width: 718px;
	}
	&--hidden {
		display: flex;
		align-items: center;
		background: #fff;
		// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 0px 10px 3px rgba(0, 107, 190, 0.3);
		border-radius: 10px;
		padding: 4px;

		@media screen and (min-width: $tablet) {
			position: absolute;
			left: -1%;
			top: 68%;
			width: 364px;
			padding: 11px;
			margin-bottom: 0;
		}
		@media screen and (min-width: $desktop) {
			left: -15%;
			top: 68%;
			width: 474px;
		}
	}
}

.icon__block {
	position: relative;
	display: flex;

	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 80px;
	height: 68px;

	color: #aeafbc;

	cursor: pointer;

	fill: #aeafbc;
	transition: background-color 250ms, border-radius 250ms, fill 250ms, color 250ms;
	@media screen and (min-width: $tablet) {
		width: 112px;
		height: 68px;
	}
	&:first-child {
		@media screen and (min-width: $desktop) {
			margin-left: 15px;
		}
	}

	margin-right: 10px;

	&:hover,
	&:focus {
		background-color: #ededed;
		border-radius: 3px;
		fill: #787878;
		color: #787878;
		&:hover .icon__bed {
			fill: #787878;
		}
	}

	&--hidden {
		display: none;
	}
}

.icon__bed__box {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon__bed {
	&--single {
		width: 30px;
		height: 25px;
		fill: #aeafbc;
	}

	&--double {
		width: 30px;
		height: 25px;
		fill: #aeafbc;
	}
	&--trio {
		width: 20px;
		height: 20px;
		fill: #aeafbc;
	}
}

.icon__bed__gbvss {
	width: 25px;
	height: 23px;
	fill: #aeafbc;
}

.icon__hiden {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 37px;
	width: 37px;
	cursor: pointer;
	border-radius: 50%;
	fill: #aeafbc;
	transition: background-color 250ms, fill 250ms;

	&:hover,
	&:focus {
		background-color: #ededed;
		fill: #787878;
	}
	@media screen and (min-width: $tablet) {
		margin-left: 10px;
	}
	@media screen and (min-width: $desktop) {
		margin-left: 0;
		height: 52px;
		width: 52px;
	}
}

.icon__dots {
	width: 25px;
	height: 20px;
	@media screen and (min-width: $tablet) {
		width: 42px;
		height: 10px;
	}
}

.icon__arrow {
	width: 40px;
	height: 40px;
}
.icon__line {
	margin: 0 4px;
}

.icon__description {
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $text_font_family;
	font-weight: $light_bold_font_weight;
	font-size: 13px;
	line-height: 1.3;
	text-align: center;
	@media screen and (min-width: $tablet) {
		font-size: 15px;
		line-height: 20px;
	}

	&--mutation {
		width: 82px;
		height: 22px;
		border: 3px solid;
		box-sizing: border-box;
		border-radius: 5px;
	}
}

.info__block {
	visibility: visible;
	@media screen and (min-width: $tablet) {
		width: 134px;
		display: block;
	}
	@media screen and (min-width: $desktop) {
		width: auto;
		margin-left: 35px;
	}
}

.info__block-hidden {
	display: none;
}
.info__count {
	display: flex;
	align-items: flex-end;
}

.info__icon {
	width: 41px;
	height: 37px;
}

.info__number {
	margin-left: 13px;
	font-family: $text_font_family;
	font-weight: $light_bold_font_weight;
	font-size: 17px;
	line-height: 23px;
	color: #ffffff;
}

.info__message {
	margin-top: 9px;
	font-family: $text_font_family;
	font-weight: $light_bold_font_weight;
	font-size: 10px;
	line-height: 13px;
	color: #ffffff;
}

.icon__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 65px;

	background: #000000;
	color: #ffffff;

	border-radius: 5px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;

	left: 240px;
	top: -76px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.notification__overlay {
	position: absolute;
	padding: 8px;
	z-index: 4;
	text-align: center;
	width: 150%;

	background: #000000;
	color: #ffffff;

	border-radius: 5px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	top: 110%;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.icon__block:hover + .icon__overlay {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.5s;
}

.icon__block:hover .notification__overlay,
.notification__overlay:focus {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.5s;
}

.notification__hidden {
	display: none;
}

.arrow__block {
	display: flex;
	margin-top: 33px;
}

.arrow__icon {
	width: 27px;
	height: 20px;
}

.arrow__icon__bed {
	width: 30px;
	height: 25px;
	fill: #787878;
}

.arrow__text {
	margin-left: 12px;
	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 17px;
	line-height: 23px;
	text-align: center;

	color: #787878;
}

.arrow__icon__block {
	position: absolute;
	left: 3px;
	top: 58%;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 52px;
	width: 52px;
	cursor: pointer;
	border-radius: 50%;
	fill: #787878;
	transition: background-color 250ms, fill 250ms;

	&:hover,
	&:focus {
		background-color: #ededed;
		fill: rgba(120, 120, 120, 0.7);
	}
}

.gbvcss_chapter {
	font-family: "Segoe UI";
	border: 4px solid #AEAFBC;
	margin-bottom: 5px;
	padding: 3px 10px;
	border-radius: 5px;
	color: #AEAFBC;
	text-align: center;
	font-size: 12px;
	line-height: 1.5;
	font-weight: 700;
}

.gbvcss_title {
	font-family: "Segoe UI";
	color: #AEAFBC;
	text-align: center;
	font-size: 12px;
	line-height: 1.5;
	font-weight: 700;
	margin-bottom: 15px;
}

.gbvcss__buttons_wrap {
	display: flex;
	flex-direction: row-reverse;
	justify-content: center;
	align-items: center;
}

.arrow__roomsicon__block {
	// position: absolute;
	// left: 3px;
	margin-top: 10px;

	display: flex;
	justify-content: center;
	align-items: center;

	height: 52px;
	width: 52px;
	cursor: pointer;
	border-radius: 50%;
	fill: #787878;
	transition: background-color 250ms, fill 250ms;

	&:hover,
	&:focus {
		background-color: #ededed;
		fill: rgba(120, 120, 120, 0.7);
	}
}

.icon__div {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 11px 11px 10px 14px;
	transition: background-color 250ms, border-radius 250ms;

	&--active {
		&:hover {
			background-color: #ededed;
			border-radius: 3px;
			cursor: pointer;
		}
	}
}
/*==== block input travel with....= window ====*/

.block__checkbox_openModal {
	// position: absolute;
	// top: 100px;
	// right: 20%;
	// display: flex;
	// flex-direction: column;
	// align-items: start;
	position: absolute;
	top: 104%;
	right: 14%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 70px;
	z-index: 20;
}

.checkbox_info_wrapper {
	width: 50%;
	display: flex;
	justify-content: flex-start;
	margin-left: auto;
	z-index: 20;
}

.block__checkbox {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-right: 100px;
	// z-index: 20;

	@media screen and (min-width: $desktop) {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		margin: 0px var(--marginRightInCalendarCheckBox) 0px 0px;
		z-index: 20;
	}
}

.block_checkbox_row {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.block_checkbox_work {
	display: flex;
	align-items: center;
	width: 197px;
	position: relative;
	justify-content: flex-start;
	margin: 0 auto 8px auto;
	@media screen and (min-width: $tablet) {
		justify-content: flex-end;
		width: 145px;
		height: 34px;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 0;
	}
	@media screen and (min-width: $desktop) {
		margin-right: 65px;
	}
}

.block_checkbox_animal {
	display: flex;
	align-items: center;
	width: 197px;
	position: relative;
	justify-content: flex-start;
	margin: 0 auto;
	@media screen and (min-width: $tablet) {
		position: relative;
		width: 145px;
		height: 34px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: right;
		margin: 0;
	}
	@media screen and (min-width: $desktop) {
		width: 190px;
		height: auto;
	}
}

@keyframes moveVertical {
	from {
		transform: translate(0);
	}
	to {
		transform: translate($animationWork-x, $animationWork-y);
	}
}

.vertical-block {
	@media screen and (min-width: $desktop) {
		animation: moveVertical 700ms forwards;
		width: 170px;
	}
}

@keyframes moveVerticalSecondary {
	from {
		transform: translate(0);
	}
	to {
		transform: translate($animation-x, $animation-y);
	}
}

@keyframes moveVerticalSecondarySuggest {
	from {
		transform: translate(0);
	}
	to {
		transform: translate($animationSuggest-x, $animationSuggest-y);
	}
}

.vertical-secondary-block {
	@media screen and (min-width: $tablet) {
		animation: moveVerticalSecondarySuggest 900ms forwards;
	}
	@media screen and (min-width: $desktop) {
		animation: moveVerticalSecondary 900ms forwards;
		width: 170px;
	}
}

.checkbox_input {
	appearance: none;
	-webkit-appearance: none;
}
.checkbox_input_animal {
	appearance: none;
	-webkit-appearance: none;
}
.checkbox_label {
	@extend%text-style-checkbox;
	cursor: pointer;
	outline: none;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		background: #edf3f7;
		border: 0.5px solid #000000;
		border-radius: 5px;
		width: 18px;
		height: 18px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-ms-border-radius: 3px;
		-o-border-radius: 3px;
		transition: border 0.1s linear;
		-webkit-transition: border 0.1s linear;
		-moz-transition: border 0.1s linear;
		-ms-transition: border 0.1s linear;
		-o-transition: border 0.1s linear;
		@media screen and (min-width: $tablet) {
			border-radius: 5px;
			width: 24px;
			height: 24px;
			right: -32px;
		}
	}

	&:after {
		content: '';
		opacity: 0;
		position: absolute;
		top: 50%;
		right: -25px;
		transform: translateY(-50%);
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #4c4c4c;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		transition: opacity 0.1s linear;
		-webkit-transition: opacity 0.1s linear;
		-moz-transition: opacity 0.1s linear;
		-ms-transition: opacity 0.1s linear;
		-o-transition: opacity 0.1s linear;
	}
}
.checked_label_animal {
	@extend%text-style-checkbox;
	cursor: pointer;
	outline: none;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		background: #edf3f7;
		border: 3px solid #4c4c4c;
		border-radius: 5px;
		width: 18px;
		height: 18px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
		transition: border 0.1s linear;
		-webkit-transition: border 0.1s linear;
		-moz-transition: border 0.1s linear;
		-ms-transition: border 0.1s linear;
		-o-transition: border 0.1s linear;
		@media screen and (min-width: $tablet) {
			border-radius: 5px;
			width: 24px;
			height: 24px;
			right: -32px;
		}
	}
	// &:hover::before,
	// &:focus::before,
	// &:active::before {
	// 	content: '';
	// 	position: absolute;
	// 	background: #edf3f7;
	// 	border: 3px solid #4c4c4c;
	// 	border-radius: 5px;
	// 	width: 24px;
	// 	height: 24px;
	// 	-webkit-border-radius: 5px;
	// 	-moz-border-radius: 5px;
	// 	-ms-border-radius: 5px;
	// 	-o-border-radius: 5px;
	// }
}

.block_checkbox_animal.vertical-secondary-block label {
	animation: colorChange 700ms forwards;
	font-weight: 500;
}

.checkbox_label_animal {
	@extend%text-style-checkbox;
	cursor: pointer;
	outline: none;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		background: #edf3f7;
		border: 3px solid #4c4c4c;
		border-radius: 5px;
		width: 18px;
		height: 18px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
		transition: border 0.1s linear;
		-webkit-transition: border 0.1s linear;
		-moz-transition: border 0.1s linear;
		-ms-transition: border 0.1s linear;
		-o-transition: border 0.1s linear;
		@media screen and (min-width: $tablet) {
			border-radius: 5px;
			width: 24px;
			height: 24px;
			right: -32px;
		}
	}
	&:after {
		content: '';
		opacity: 1;
		position: absolute;
		top: 50%;
		right: 4.5px;
		transform: translateY(-50%);
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: #4c4c4c;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		transition: opacity 0.1s linear;
		-webkit-transition: opacity 0.1s linear;
		-moz-transition: opacity 0.1s linear;
		-ms-transition: opacity 0.1s linear;
		-o-transition: opacity 0.1s linear;
		@media screen and (min-width: $tablet) {
			right: -25px;
			width: 10px;
			height: 10px;
		}
	}
}
.animate {
	animation: colorChange 700ms forwards;
	font-weight: 500;
}

@keyframes colorChange {
	from {
		color: #ffffff;
	}
	to {
		color: #ffffff;
	}
}
.checkbox_input:checked + .checkbox_label {
	&:before {
		background: #edf3f7;
		border: 3px solid #4c4c4c;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}
	&:after {
		opacity: 1;
	}
}

.block_checkbox_animal_window {
	margin: 10px 0px 0px 0px;
	position: absolute;

	width: 261px;
	// height: 234px;
	top: 100%;
	right: -32px;

	background: #d0d0d0;
	// box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	z-index: 100;
}
.animal_window_ul {
	// margin: 20px 0px 0px 25px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 20px 20px 20px 25px;
	@extend%text-style-checkbox-window;
}
.animal_window_li {
	margin-bottom: 4px;
	padding: 8px;
	position: relative;
	width: 90%;
	background: #ffffff;
	border-radius: 5px;
	text-align: left;
	cursor: pointer;
	transition: background-color 250ms;
	&:before {
		content: '';
		position: absolute;
		top: 0px;
		left: -36px;
		width: 26px;
		height: 26px;
		background: #ffffff;
		border-radius: 50%;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
	}

	&:hover {
		background-color: #e1e1e1;
		&::before {
			background-color: #e1e1e1;
		}
	}
	&:active {
		background-color: #d4ebfb;
		&::before {
			background-color: #d4ebfb;
		}
	}
}

.animal_window_li_selected {
	@extend.animal_window_li;
	background: #ffffff;
	transition: background-color 250ms;
	&:hover {
		background-color: #ffffff;
		&::before {
			background-color: #ffffff;
		}
	}
}

.cat::after {
	content: '';
	background-image: url(../Assents/icon/Kitty.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}

.small_dog::after {
	content: '';
	background-image: url(../Assents/icon/Small_Dog.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}
.middle_dog::after {
	content: '';
	background-image: url(../Assents/icon/Middle_Dog.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}
.big_dog::after {
	content: '';
	background-image: url(../Assents/icon/Big_Dog.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}
.racoon::after {
	content: '';
	background-image: url(../Assents/icon/Racoon_New.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}
.smaller_animal::after {
	content: '';
	background-image: url(../Assents/icon/Smaller_Than_Cat.svg);
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	top: 0;
	left: -36px;
	width: 26px;
	height: 26px;
}

.modal__backdrop_checkbox {
	opacity: 1;
	visibility: visible;
	transition: opacity 250ms ease, visibility 250ms ease;
}

.modal__backdrop_checkbox_notvisible {
	opacity: 0;
	visibility: hidden;
	transition: opacity 250ms ease, visibility 250ms ease;
}
// .modal__backdrop_checkbox {
// 	width: 100%;
// 	height: 100%;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	z-index: 100;
// }

/* =============================block suggest!===========================*/

.block__suggest-modal {
	margin: 0;
	width: 100%;
	height: 100%;
	padding-top: 17px;
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
	scroll-behavior: none;
	background: linear-gradient(153.39deg, rgba(255, 255, 255, 0.8) 6.62%, rgba(0, 144, 255, 0.8) 87.26%),
		linear-gradient(112.54deg, rgba(255, 255, 255, 0.4) 6.78%, rgba(0, 107, 190, 0.4) 66.37%),
		linear-gradient(116.65deg, rgba(255, 255, 255, 0.2) 6.75%, rgba(0, 107, 190, 0.2) 51.09%);
	// backdrop-filter: blur(15px);
	z-index: 30;
	padding: 47px 0px 15px 0px;
	@media screen and (min-width: $tablet) {
		padding: 17px 0 0 0;
	}

	@media screen and (min-width: 320px) {
		overflow-y: scroll;
	}
}
.suggest__container {
	// margin: 12px 220px 6px 220px;
	width: 750px;
	margin: 0 auto 11px auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.suggest__container-close {
	display: none;
}
.suggest__amount {
	display: flex;
	align-items: center;
	justify-content: center;
}
.suggest__date {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	&::before {
		content: '';
		background-image: url(../Assents/icon/weekly-calendar.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 22px;
		top: 11px;
		width: 20px;
		height: 20px;
	}
}
.suggest__select-date {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	&::before {
		content: '';
		background-image: url(../Assents/icon/weekly-calendar.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		top: 11px;
		width: 20px;
		height: 20px;
	}
}
.suggest__location {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surface1.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		top: 11px;
		width: 20px;
		height: 20px;
	}
}
.suggest__select-location {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surfacewite.svg);

		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		top: 11px;
		width: 20px;
		height: 20px;
	}
}
.suggest__bedroom {
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;

	&::before {
		content: '';
		background-image: url(../Assents/icon/icon-bed.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 22px;
		top: 11px;
		width: 28px;
		height: 28px;
	}
}
.suggest__select-bedroom {
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	&::before {
		content: '';
		background-image: url(../Assents/icon/icon-bedwhite.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		top: 11px;
		width: 28px;
		height: 28px;
		fill: white;
	}
}

.suggest__select-date,
.suggest__select-location,
.suggest__select-bedroom {
	&.animate-color {
		animation: sugColorChange 2s linear forwards;
	}
}

@keyframes sugColorChange {
	from {
		background-color: #ffffff;
	}
	to {
		background-color: rgba(0, 107, 190, 1);
	}
}

.reverse-animation {
	animation: sugReverseColorChange 2s linear forwards;
}

@keyframes sugReverseColorChange {
	from {
		background-color: rgba(0, 107, 190, 1);
	}
	to {
		background-color: #ffffff;
	}
}

// .suggest__date,
// .suggest__location,
// .suggest__bedroom {
// 	&.animate-color-unselected {
// 		animation: sugColorChangeUnselected 2s linear forwards;
// 	}
// }

// @keyframes sugColorChangeUnselected {
// 	from {
// 		background-color: #46ea88; /* початковий колір */
// 	}
// 	to {
// 		background-color: #ffffff; /* кінцевий колір */
// 	}
// }

.suggest__close {
	width: 104px;
	height: 62px;
	padding: 0px 0px 0px 25px;
	position: absolute;
	top: 5px;
	right: 2%;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 900;
	font-size: 14px;
	line-height: 19px;
	border: none;
	color: #ffffff;
	cursor: pointer;
	background-color: transparent;
	z-index: 15;
	@media screen and (min-width: $desktop) {
		top: 50px;
		right: 5%;
	}
	&::before {
		content: '';
		background-image: url(../Assents/icon/clouse.svg);
		background-repeat: no-repeat;
		position: absolute;
		right: 65px;
		top: 22px;
		width: 20px;
		height: 20px;
		@media screen and (min-width: $desktop) {
			width: 25px;
			height: 25px;
		}
	}
	&:hover {
		width: 104px;
		height: 62px;
		background: rgba(76, 76, 76, 0.12);
		backdrop-filter: blur(15px);
		border-radius: 44px;
		@media screen and (min-width: $desktop) {
			width: 104px;
			height: 62px;
		}
	}

	&:active {
		width: 104px;
		height: 62px;
		background: rgba(76, 76, 76, 0.22);
		backdrop-filter: blur(15px);
		border-radius: 44px;
		@media screen and (min-width: $desktop) {
			width: 104px;
			height: 62px;
		}
	}
}

.suggest__close_inContainer {
	@extend.suggest__close;
	position: relative;
	top: 0;
	right: 0;
	width: 104px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	&::before {
		content: '';
		background-image: url(../Assents/icon/clouse.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
		width: 18px;
		height: 18px;
	}
	&:hover {
		width: 104px;
		height: 40px;
		background: rgba(76, 76, 76, 0.12);
		backdrop-filter: blur(15px);
		border-radius: 44px;
	}

	&:active {
		width: 104px;
		height: 40px;
		background: rgba(76, 76, 76, 0.22);
		backdrop-filter: blur(15px);
		border-radius: 44px;
	}
}

.gbvcss_notification {
	position: absolute;
	top: 35px;
	right: -30px;
	padding: 8px;
	width: 210px;
	text-align: justify;
	text-indent: 15px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	border-radius: 5px;
	color: #ffffff;
	background-color: #000000;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
	@media screen and (min-width: $desktop) {
		top: 25px;
	}
}

.vertical-secondary-block:hover .gbvcss_notification,
.gbvcss_notification:focus {
	opacity: 1;
	visibility: visible;
	transition-delay: 0.5s;
}

.bottom_btn {
	display: block;
	margin: 0 auto;
	text-align: center;
	outline: none;
	border: none;
	@extend %suggest-block-style;
	@extend %suggest-text-style;
	background: rgba(0, 107, 190, 1);
	color: #ffffff;
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	transition: background-color 2s ease, color 2s ease;
	// &:disabled {
	// 	background: #ffffff;
	// 	color: #656565;
	// }
	&:active {
		background: #ffffff;
		color: #ffffff;
	}
	// transition: opacity 2s ease;
	// &:disabled {
	// 	opacity: 0.5;
	// }
	// &:active {
	// 	opacity: 1;
	// }
}

.bottom_btn_disabled {
	@extend .bottom_btn;
	background: #ffffff;
	color: #656565;
}
