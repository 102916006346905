@import 'styles/common/variables';

.avatarThumb {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 27px;
	height: 27px;
	padding: 4px 2px 0;
	margin-top: 2px;
	overflow: hidden;

	background-color: $colors-bg-avatar;
	border: 0.1px solid #787878;
	border-radius: 3px;
	color: #787878;
	@media screen and (max-width: $pre-tablet) {
		margin-top: 0px;
		padding: 4px;
	}
}

.avatar {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
