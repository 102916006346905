.chat {
	width: 320px;
	height: 281px;
	padding: 22px 16px;
	background: #dadcea;

	margin-right: 4px;
	border-radius: 0 0 17px 17px;

	&_block {
		width: 289px;
		height: 148px;
		background: #ffffff;
		border-radius: 6px;
		text-align: center;
		font-weight: 600;
		font-size: 25px;
		line-height: 33px;
		display: flex;
		align-items: center;
		justify-content: center;

		color: rgba(101, 101, 101, 0.5);

		margin-bottom: 18px;
	}

	&_text {
		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 600;
		font-size: 11px;
		line-height: 15px;
		max-width: 248px;

		&:not(:first-child) {
			padding-bottom: 13px;
		}

		color: #0090ff;

		&_accent {
			color: #ff6000;
		}

		&_username {
			margin-right: 15px;
		}

		&_message {
			margin-left: 4px;
			font-weight: 350;
		}

		&__list {
			margin-bottom: 9px;
			margin-top: 8px;

			height: 131px;
			width: 100%;

			display: flex;
			flex-direction: column-reverse;
			overflow-y: auto;
			padding: 6px 15px;

			&::-webkit-scrollbar {
				cursor: pointer;
				width: 11px;
				height: 19px;
			}

			&::-webkit-scrollbar-track {
				background: #ebedfe;
				border-radius: 5px;
				border: 3px solid #fff;
			}

			&::-webkit-scrollbar-thumb {
				// width: 3px;
				height: 21px;

				border-radius: 5px;
				background: #bababf;
				border: 3px solid #fff;
				border-top: none;
				border-bottom: none;
			}
		}
	}
	&_message {
		resize: none;
		width: 251px;
		height: 66px;

		background: #ffffff;
		border: none;
		border-radius: 6px;
		padding: 4px 12px;

		font-family: 'Segoe UI';
		font-style: normal;
		font-weight: 400;
		font-size: 11.1111px;
		line-height: 15px;
		display: flex;
		align-items: flex-end;
		color: #0090ff;

		&:focus {
			outline: none;
			border: 0.3px solid #000000;
		}

		&::-webkit-scrollbar {
			cursor: pointer;
			width: 4px;
			height: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #bababf;
			border-radius: 50px;
		}

		&_container {
			display: flex;
			align-items: flex-end;
		}
	}

	&_btn_send {
		cursor: pointer;
		margin-left: 13px;
		margin-bottom: 6px;
		border: none;
		width: 15px;
		height: 15px;
		background: transparent;
	}
}
.button {
	width: 150px;
	height: 35px;

	background: #0090ff;
	border: none;
	border-radius: 16px;

	font-weight: 700;
	font-size: 10px;
	line-height: 13px;
	display: flex;
	align-items: center;
	justify-content: center;

	color: #ffffff;
	cursor: pointer;

	&:disabled {
		background: rgba(0, 144, 255, 0.5);
		cursor: auto;
		&:hover {
			background: rgba(0, 144, 255, 0.5);
		}
	}

	&:first-child {
		margin-bottom: 28px;
	}

	&:hover {
		background: rgba(0, 144, 255, 0.7);
	}

	&:hover &_popover {
		visibility: visible;
		opacity: 1;
		transition-delay: 0.5s;
	}
	&:hover .waitForHotelierPaymentConfirm {
		pointer-events: initial;
		visibility: visible;
		opacity: 1;
	}
	&:active {
		background: rgba(0, 144, 255, 0.5);
	}

	&_popover {
		visibility: hidden;
		opacity: 0;
		transition: all, 0.5s;

		width: 123px;
		height: 35px;

		display: flex;
		align-items: center;
		padding: 4px;
		padding-left: 7px;

		background: #535252;
		border-radius: 5px;

		font-family: 'Roboto';
		font-style: normal;
		font-weight: 700;
		font-size: 17px;
		line-height: 20px;
		color: #ffffff;

		text-shadow: 0px 0px 5.20755px rgba(0, 0, 0, 0.25);

		&__number {
			flex-shrink: 0;
			font-size: 17px;
			line-height: 20px;
		}

		&__title {
			display: block;
			font-size: 12px;
			line-height: 89.19%;
		}

		img {
			width: 19px;
			height: 15px;
			margin-right: 5px;
			margin-left: 8px;
		}
	}
}
.contact {
	padding: 8px 28px;
	height: 119px;
	width: 209px;
	background: #f1ff9a;

	font-weight: 700;
	font-size: 11px;
	line-height: 15px;

	color: #4c4c4c;

	margin-right: 4px;
	border-radius: 0 0 17px 17px;

	@media (max-width: 546px) {
		left: -5%;
	}
	// @media (max-width: 494px) {
	// 	left: -12%;
	// }
	@media (max-width: 454px) {
		left: -16%;
	}

	p {
		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 12px;
		}
	}

	&__phone {
		display: flex;
		align-items: center;
		margin-bottom: 7px;

		img {
			margin-right: 11px;
		}
		&:last-child {
			margin-bottom: 0px;
		}
	}
}

.user_info {
	width: 209px;
	height: 152px;

	background: #dadcea;
	border-radius: 0 0 17px 17px;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 14px 12px;
	// @media (max-width: 616px) {

	// }

	@media (max-width: 494px) {
		right: 29%;
	}
	@media (max-width: 448px) {
		right: 27%;
	}

	&_item {
		width: 184px;
		height: 23px;
		@media (max-width: 616px) {
			width: 90%;
			height: 30px;
			font-size: 11px;
		}
		background: #ffffff;
		border-radius: 5px;

		font-weight: 600;
		font-size: 11px;
		line-height: 15px;
		display: flex;
		align-items: center;
		justify-content: center;

		color: #4c4c4c;

		margin-bottom: 8px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&_icon {
		width: 25px;
		height: 25px;
		background: #ffffff;
		border: 0.3px solid #787878;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.waitForHotelierPaymentConfirm {
	position: absolute;
	bottom: calc(100% - 12px);
	left: 50%;
	width: 150px;
	min-height: 183px;
	padding: 9px 8px 12px;
	pointer-events: none;
	visibility: hidden;
	background-color: #535252;
	border-radius: 5px;
	opacity: 0;
	transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
	transition-property: visibility, opacity;
	transform: translateX(-50%);
}

.waitForHotelierPaymentConfirmTextNormal {
	padding: 0 16px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 89.19%;
	/* or 11px */
	text-align: center;
	color: #ffffff;
	text-shadow: 0px 0px 5.20755px rgba(0, 0, 0, 0.25);

	&:first-of-type {
		margin-bottom: 8px;
	}
}

.accentedPaymentStatus {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	padding: 2px 4px;
	font-style: normal;
	font-weight: 700;
	font-size: 10px;
	line-height: 13px;
	font-family: 'Segoe UI';
	color: #ff6000;
	text-align: center;
	background-color: #ffffff;
	border: 1px solid #ff6000;
}
@media (max-width: 616px) {
	.chat {
		position: absolute;
		top: 113%;
		right: 50%;
		transform: translate(50%, 50%);
	}
	.user_info {
		position: absolute;
		bottom: 25%;
		right: 33%;
		width: 120px;
		height: 120px;
		transform: translate(50%, 50%);
	}
	.contact {
		position: absolute;
		top: 12%;
		left: 2%;
		border-radius: 17px;
		transform: translate(50%, 50%);
	}
}
@media (max-width: 578px) {
	.contact {
		left: -3%;
	}
	.user_info {
		right: 30%;
	}
}
@media (max-width: 494px) {
	.contact {
		left: -12%;
	}
	.user_info {
		right: 27%;
	}
}
@media (max-width: 414px) {
	.contact {
		left: -23%;
	}
	.user_info {
		right: 26%;
	}
}
@media (max-width: 430px) {
	.contact {
		left: -20%;
	}
}
@media (max-width: 410px) {
	.contact {
		left: -10%;
		height: 119px;
		width: 150px;
		padding: 8px 14px;
	}
	.user_info {
		right: 29%;
	}
}
@media (max-width: 358px) {
	.contact {
		left: -18%;
	}
	.user_info {
		right: 27%;
	}
}
