%text-user_name {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #656565;
}
%text-user_number {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 121.01%;
  color: #ff6000;
}
%text-booking-karma {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 121.01%;
  color: #ffffff;
}
%text_feedback-area {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: #4c4c4c;
}
%text_count {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;
  color: #787878;
}
%text_time {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 11px;
  color: #4c4c4c;
}
%text_rank {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #0090ff;
}
%text_hotel-name {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #656565;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
.full_booking_feedback_container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  width: 316px;
  box-sizing: border-box;
}

.user_feedback_container {
  margin: 10px 0px 0px 0px;
  width: 316px;
  height: 98px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  border: 1.04839px solid rgba(0, 0, 0, 0.18);
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.user_feedback_container_open {
  margin: 10px 0px 0px 0px;
  width: 316px;
  max-height: 220px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background: #ffffff;
  border: 1.04839px solid rgba(0, 0, 0, 0.18);
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  & .feedback_block {
    max-height: 220px;
    overflow: hidden;
  }
}
.user_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 72px;
  height: 100%;
  & .user_name {
    padding: 1px 1px 0px 5px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;

    flex-wrap: wrap;
    height: 26px;
    @extend%text-user_name;
    word-break: break-word;
    white-space: pre-line;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    overflow: hidden;
  }
}

.avatar_block {
  position: relative;
  width: 52px;
  height: 57px;
  // top: 2px;
  // left: 9px;
  & .user_number {
    position: absolute;
    top: 45px;
    left: 35px;
    width: 16px;
    height: 14px;
    @extend %text-user_number;
  }
  & .user_karma {
    position: absolute;
    top: 36px;
    left: 2px;
    width: 38px;
    height: 12px;
    @extend %text-booking-karma;
  }
  & .user_avatar {
    position: absolute;
    top: 5px;
    left: 0px;
    width: 52px;
    height: 56px;
    border-radius: 3px;
  }
  .hotel_rank_block {
    position: absolute;
    top: 8px;
    left: 26px;
    width: 23px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 0px 4.8421px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    & .hotel_rank {
      width: 23px;
      height: 13px;
      @extend %text_rank;
    }
  }
}

.feedback_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 3px;
  width: 161px;
  height: 100%;
  overflow: hidden;
  & .feedback_area_block {
    padding: 30px 0px 0px 8px;
    @extend %text_feedback-area;
    word-break: break-word;
    white-space: pre-line;
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.btn_block {
  margin-bottom: 1px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.button {
  width: 20px;
  height: 20px;
  border: none;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  &:hover {
    background-color: rgba(196, 196, 196, 0.5);
    border-radius: 2px;
  }
}
.btn_open_disc {
  background-image: url('../../../assets/images/bookingFeedback/full.svg');
  background-repeat: no-repeat;
  background-color: inherit;
  background-position: center;
}
.btn_close_disc {
  background-image: url('../../../assets/images/bookingFeedback/notfull.svg');
  background-repeat: no-repeat;
  background-color: inherit;
  background-position: center;
}
.btn_open_comment {
  background-image: url('../../../assets/images/bookingFeedback/comment.svg');
  background-repeat: no-repeat;
  background-color: inherit;
  background-position: center;
}
.btn_unlike {
  background-image: url('../../../assets/images/bookingFeedback/like.svg');
  background-repeat: no-repeat;
  background-color: inherit;
  background-position: center;
}
.btn_like {
  background-image: url('../../../assets/images/bookingFeedback/likeactive.svg');
  background-repeat: no-repeat;
  background-color: inherit;
  background-position: center;
  animation: like 0.5s 1;

  -webkit-animation: like 0.5s 1;
  &:active {
    background-color: inherit;
  }
  &:focus {
    background-color: inherit;
  }
}

@keyframes like {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
  }
  80% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
  }
  100% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
  }
}

.countL {
  position: absolute;
  right: 44px;
  bottom: 4px;
  width: 15px;
  height: 11px;
  display: block;
  @extend %text_count;
}
.countC {
  position: absolute;
  right: 85px;
  bottom: 4px;
  width: 15px;
  height: 11px;
  display: block;
  @extend %text_count;
}

.time_block {
  width: 39px;
  height: 11px;
  @extend %text_time;
}

/* =========  for comment block============= */

.user_feedback_comment_container {
  margin: 10px 0px 0px 0px;
  width: 301px;
  height: 98px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  border: 1.04839px solid rgba(0, 0, 0, 0.18);
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
}
.user_feedback_comment_container_open {
  margin: 10px 0px 0px 0px;
  width: 301px;
  max-height: 220px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  box-sizing: border-box;
  background: #ffffff;
  border: 1.04839px solid rgba(0, 0, 0, 0.18);
  transition: all 0.1s ease-out;
  -webkit-transition: all 0.1s ease-out;
  -moz-transition: all 0.1s ease-out;
  -ms-transition: all 0.1s ease-out;
  -o-transition: all 0.1s ease-out;
  & .feedback_block {
    max-height: 220px;
    overflow: hidden;
  }
}
