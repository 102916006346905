@import 'styles/common/variables';

.imageList {
	margin: 0;
	padding: 0;
}

.imageItem {
	position: relative;
	width: 115px;
	height: 115px;
	border-radius: 5px;
	margin-bottom: 14px;
	@media screen and (max-width: $pre-tablet) {
		width: 115px;
		height: 115px;
		margin-bottom: 5px;
	}
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 5px;
}

.rank {
	position: absolute;
	top: 7px;
	right: 6px;

	// padding: 3px 4px 3px 5px;

	font-weight: bold;
	font-size: 10px;
	line-height: 13px;
	text-align: center;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	color: #0090ff;
	// Is this box shadow neccessary?
	// box-shadow: 0px 0px 4.8421px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
	@media screen and (max-width: $pre-tablet) {
		top: 5px;
		right: 5px;
	}
}
