@import '../../styles/common/breakpoints';

.modal-backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10;
	background: rgba(76, 76, 76, 0.28);
	backdrop-filter: blur(15px);
}

.modal-content {
	position: relative;
	width: 292px;
	border-radius: 4px;
	margin-top: 17px;
	margin-right: auto;
	margin-left: auto;
    z-index: 12;
}

.closeButton_wrap {
	position: absolute;
	top: 1px;
	right: 4px;
	width: 35px;
	height: 35px;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	
	border-radius: 3px;
	border: transparent;
}

.closeButton_wrap:hover,
.closeButton_wrap:focus {
	background-color:rgba(76, 76, 76, 0.05);
	
}
.closeButton_wrap:active {
	background-color:rgba(76, 76, 76, 0.1);
}


.closeButton_icon {
	width: 18px;
	height: 18px;
}
