@import 'styles/common/variables';

.notification__wrapp {
	margin: 0 auto;
	margin-top: 70px;
	width: 307px;
	height: 143px;

	background-color: #fff;
	border-radius: 2px;

	display: flex;
	justify-content: center;
	align-items: center;

	@media screen and (min-width: $tablet) {
		margin-left: auto;
		margin-right: 30px;
		margin-top: 65px;
		width: 501px;
		height: 110px;
	}

	@media screen and (min-width: $desktop) {
		margin-right: 99px;
		margin-top: 60px;
	}
}

.notification__block {
	display: flex;
	width: 260px;
	height: 99px;
	padding-left: 20px;
	padding-top: 10px;
	padding-bottom: 6px;
	border: 0.2px solid rgba(0, 0, 0, 0.5);
	border-radius: 10px;

	@media screen and (min-width: $tablet) {
		width: 438px;
		height: 69px;
		padding-left: 45px;
		padding-top: 11px;
		padding-right: 13px;
	}
}

.notification__title {
	width: 173px;
	padding-bottom: 10px;
	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 11px;
	line-height: 1.3;

	color: #535252;
	@media screen and (min-width: $tablet) {
		width: 283px;
		padding-bottom: 8px;
		font-size: 12px;
	}
}

.notification__text {
	width: 152px;
	font-family: 'Segoe UI';
	font-weight: 350;
	font-size: 11px;
	line-height: 15px;

	color: #535252;
	@media screen and (min-width: $tablet) {
		width: 262px;
	}
}

.notification__icon {
	margin-top: 23px;
	width: 50px;
	height: 42px;

	@media screen and (min-width: $tablet) {
		margin-top: 0px;
		margin-left: auto;
	}
}
