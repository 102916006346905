@import 'styles/common/variables';

.SendRequest__backdrop {
	position: fixed;
	inset: 0;
	z-index: 1000;
	display: flex;
	overflow-y: scroll;
	background-color: rgba(76, 76, 76, 0.28);
	-webkit-backdrop-filter: blur(15px);
	backdrop-filter: blur(15px);
	// opacity: 0;
	transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.SendRequest__thumb {
	z-index: 1001;
	max-width: 926px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	width: 100%;
	height: min-content;
	width: 100%;
	height: min-content;
}

.Video__thumb {
	width: 432px;
	max-height: 239px;
	border-radius: 10px;
	// margin-right: 20px;
	width: 100%;
	margin-right: 15px;
}
.Gallery__thumb {
	margin-top: 21px;
	// margin-left: 20px;
	// margin-bottom: 18px;
	max-width: 461px;
	max-height: 218px;
	width: 100%;
	margin-right: 15px;
	width: 100%;
	margin-right: 15px;
}


.SendRequest__modalBlock {
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
}
.SendRequest__modalBlock2 {
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
}
.SendRequest__modalBlock2 {
	display: flex;
	justify-content: space-between;
	margin-top: 18px;
}
.SendRequest__modalCompomnent {
	max-width: 432px;
	width: 100%;
	height: 100%;
	border-radius: 10px;
}

.SendRequest__reserveBlock {
	width: 100%;
	padding: 18px 0;
	border-radius: 20px;
	position: sticky;
	margin-top: 15px;
	overflow: hidden;
	border: 1px solid #4c4c4c;
	
}

.SendRequest__Btn{
	position: sticky;
	z-index: 99999;
  	top: 0;
  	overflow-y: auto;
	
}

.SendRequest__Btn{
	position: sticky;
	z-index: 99999;
  	top: 0;
  	overflow-y: auto;
}
.SendRequest__reserveButton {
	border: 1px solid #ff6000;
	border-radius: 33px;
	max-width: 346px;
	width: 100%;
	padding: 16px;
	background: #fff;
	opacity: 1;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 700;
	font-size: 18px;
	color: #ff6000;
	position: relative;
	z-index: 1;
	margin: 0 auto;
	display: block;
}
.SendRequest__buttonThumb {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(50% 50% at 50% 50%, rgb(255, 96, 0) 0%, rgb(241, 93, 4) 100%);
	opacity: 0.5;
	backdrop-filter: blur(45px);
}
.SendRequest__closeButton {
	background-color: transparent;
	border: none;
	width: 100%;
	display: flex;
	justify-content: flex-end;

	&  img  {
		width: 27px;
		height: 27px;
	}
}

.SendRequest__closeButtonGallery {
	background-color: transparent;
	border: none;
	display: none;
}


@media(max-width: 768px) {
    .Gallery__thumb {
		margin: 0;
	}

	.SendRequest__modalBlock {
		padding: 0 10px;
	}

	.DescReserveBlock {
		display: flex;
		flex-direction: column-reverse;
		padding: 0 10px;
	}

	.SendRequest__modalBlock2 {
		flex-direction: column-reverse;
	}

	.SendRequest__thumb {
		margin-top: 40px;
	}

}

@media(max-width: 767px) {
	.SendRequest__modalBlock {
		flex-direction: column;
	}

	.Video__thumb {
		max-width: 100%;
		width: 100%;
		margin-bottom: 15px;
	}

	.SendRequest__modalCompomnent {
		max-width: 767px;
		width: 100%;
	}

	.Video__thumb {
		max-height: fit-content;
	}

	.Gallery__thumb {
		max-width: 100%;
	}
	
}

@media(max-width: 460px) {

	.Video__thumb {
		display: flex;
		justify-content: center;
	}

	.SendRequest__reserveButton {
		max-width: 236px;
	}

	.SendRequest__thumb {
		margin-top: 7px;
	}

	.SendRequest__closeButtonGallery {
		background-color: transparent;
		border: none;
		display: flex;
	}

	.Gallery__thumb {
		max-width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	
	.SendRequest__closeButton img {
		width: 15px;
		height: 15px;
		margin-right: 10px;
	}

	.SendRequest__modalBlock {
		margin-top: 5px;
	}

}

@media(max-width: 350px) {
	.SendRequest__modalCompomnent {
		max-width: 268px;
		width: 100%;;
	}
	.Video__thumb {
		display: flex;
		justify-content: center;
	}


	

}
.SendRequest__closeButtonGallery {
	background-color: transparent;
	border: none;
	display: none;
}


@media(max-width: 768px) {
    .Gallery__thumb {
		margin: 0;
	}

	.SendRequest__modalBlock {
		padding: 0 10px;
	}

	.DescReserveBlock {
		display: flex;
		flex-direction: column-reverse;
		padding: 0 10px;
	}

	.SendRequest__modalBlock2 {
		flex-direction: column-reverse;
	}

	.SendRequest__thumb {
		margin-top: 40px;
	}

}

@media(max-width: 767px) {
	.SendRequest__modalBlock {
		flex-direction: column;
	}

	.Video__thumb {
		max-width: 100%;
		width: 100%;
		margin-bottom: 15px;
	}

	.SendRequest__modalCompomnent {
		max-width: 767px;
		width: 100%;
	}

	.Video__thumb {
		max-height: fit-content;
	}

	.Gallery__thumb {
		max-width: 100%;
	}
	
}

@media(max-width: 460px) {

	.Video__thumb {
		display: flex;
		justify-content: center;
	}

	.SendRequest__reserveButton {
		max-width: 236px;
	}

	.SendRequest__thumb {
		margin-top: 7px;
	}

	.SendRequest__closeButtonGallery {
		background-color: transparent;
		border: none;
		display: flex;
	}

	.Gallery__thumb {
		max-width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	
	.SendRequest__closeButton img {
		width: 15px;
		height: 15px;
		margin-right: 10px;
	}

	.SendRequest__modalBlock {
		margin-top: 5px;
	}

}

@media(max-width: 350px) {
	.SendRequest__modalCompomnent {
		max-width: 268px;
		width: 100%;;
	}
	.Video__thumb {
		display: flex;
		justify-content: center;
	}

}
//=========================object description ====================================

.Description__block {
	display: flex;
	background-color: white;
	padding: 12px 13px;
	border-radius: 5px;
	margin-top: 18px;
}

.Description__leftSide {
	display: flex;
}

.Description__leftSide {
	display: flex;
}

.Description__imageThumb {
	// width: 115px;
	height: 115px;
	margin: 0px 12px;
	display: flex;
}

.Description__mainImage {
	width: 115px;
}
.Description__rathing {
	color: #0090ff;
	font-family: 'Segoe UI', sans-serif;
	font-weight: 700;
	font-size: 17px;
	text-align: center;
	text-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}
.Description__hotelType {
	background-color: #0090ff;
	padding: 30px 2px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	min-width: 78px;
	border-radius: 5px;
	color: #fff;
	min-width: 78px;
	border-radius: 5px;
	color: #fff;
}
.Description__sections {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: space-between;
	align-items: flex-start;
}
.Description__icon {
	width: 21px;
	margin: 0 8px;
	margin: 0 8px;
}

.Description__text {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: #656565;
	display: flex;
	align-items: center;
}

.Description__HotelName {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 700;
	font-size: 18px;
	color: #656565;
	display: flex;
	align-items: center;
}

.Scroll__container2 {
	width: 218px;
	height: 145px;
	margin-right: 5px;
	display: flex;
	flex-wrap: wrap;
	padding-top: 18px;
	padding-left: 3px;
	padding-right: 4px;
	padding-bottom: 10px !important;
	gap: 10px;
	// overflow-x: auto;
	// overflow-y: hidden;
	cursor: pointer;
}
// .Scroll__container1 {
// 	width: 218px;
// 	height: 145px;
// 	display: flex;
// 	margin-left: 15px;
// 	flex-wrap: wrap;
// 	padding-top: 18px;
// 	padding-left: 3px;
// 	padding-right: 4px;
// 	flex-direction: row;
// 	grid-gap: 10px;
// 	gap: 10px;
// 	position: static;
// 	top: 0;
// 	left: 0;
// 	box-sizing: border-box;
// 	cursor: pointer;
// }
.Scroll__container1 {
	width: 218px;
	height: 145px;
	display: flex;
	margin-left: 15px;
	padding-top: 18px;
	padding-left: 3px;
	padding-right: 4px;
	overflow-y: hidden;
	cursor: pointer;
	gap: 10px;
}
.Scroll__container1::-webkit-scrollbar {
	width: 3px; /* Ширина прокрутки */
	height: 10px;
}

.Scroll__container1::-webkit-scrollbar-track {
	background: #f1f1f1; /* Колір фону прокрутки */
}

.Scroll__container1::-webkit-scrollbar-thumb {
	background-color: #0090ff; /* Колір панелі прокрутки */
	border-radius: 5px; /* Закругленість країв */
}

.Scroll__container1::-webkit-scrollbar-thumb:hover {
	background-color: #0090ff; /* Колір панелі прокрутки при наведенні */
}


.Scroll__container1::-webkit-scrollbar {
	width: 3px; /* Ширина прокрутки */
	height: 10px;
}

.Scroll__container1::-webkit-scrollbar-track {
	background: #f1f1f1; /* Колір фону прокрутки */
}

.Scroll__container1::-webkit-scrollbar-thumb {
	background-color: #0090ff; /* Колір панелі прокрутки */
	border-radius: 5px; /* Закругленість країв */
}

.Scroll__container1::-webkit-scrollbar-thumb:hover {
	background-color: #0090ff; /* Колір панелі прокрутки при наведенні */
}

.Scroll__container2::-webkit-scrollbar {
	width: 3px; /* Ширина прокрутки */
	height: 10px;
	height: 10px;
}


.Scroll__container2::-webkit-scrollbar-track {
	background: #f1f1f1; /* Колір фону прокрутки */
}


.Scroll__container2::-webkit-scrollbar-thumb {
	background-color: #0090ff; /* Колір панелі прокрутки */
	border-radius: 5px; /* Закругленість країв */
}


.Scroll__container2::-webkit-scrollbar-thumb:hover {
	background-color: #0090ff; /* Колір панелі прокрутки при наведенні */
}

.Description__Video__thumb {
	width: 107px;
	height: 107px;
	border-radius: 10px;
	margin-top: auto;
	margin-bottom: auto;
}

.Description__imageThumbMobile {
	display: none;
}

@media(max-width: 768px) {
	.Description__block {
		flex-direction: column;
	}

	.Scroll__container1 {
		width: 100%;
		overflow: none;
	
	}

	.Description__leftSide {
		justify-content: space-between;
	}

	.Description__sections {
		gap: 10px;
		
		&:last-child p:first-child{
			align-items: center;
		}
	}

	.Scroll__container2 {
		height: auto;
		margin-bottom: 30px;
	}	
}

@media(max-width: 767px) {
	.Description__imageThumb {
		display: none;
	}
	.Description__imageThumbMobile {

		height: 115px;
		margin: 0px 12px;
		display: flex;
	
	}
	
	.Description__mainImageMobile {
		width: 115px;
		border-radius: 2px;
	}

	.Description__sections:last-child {
		display: none;
	}

	.Description__leftSide {
		justify-content: space-around;
	}

	.Description__icon {
		margin-left: 0;
	}

	.bedOptionsMobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
	}

	.Scroll__container2 {
		justify-content: center;
	}
}

@media(max-width: 767px) {
	.Scroll__container2 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		padding: 0 10px;
	}
}

@media(max-width: 380px) {
	.Scroll__container2 {
		overflow-x: auto;
		overflow-y: hidden;
		justify-content: flex-start;
		margin: 20px 0 0;
	}
}


.Description__imageThumbMobile {
	display: none;
}

@media(max-width: 768px) {
	.Description__block {
		flex-direction: column;
	}

	.Scroll__container1 {
		width: 100%;
		overflow: none;
	}

	.Description__leftSide {
		justify-content: space-between;
	}

	.Description__sections {
		gap: 10px;
		
		&:last-child p:first-child{
			align-items: center;
		}
	}

	.Scroll__container2 {
		height: auto;
		margin-bottom: 30px;
	}	
}

@media(max-width: 767px) {
	.Description__imageThumb {
		display: none;
	}
	.Description__imageThumbMobile {

		height: 115px;
		margin: 0px 12px;
		display: flex;
	
	}
	
	.Description__mainImageMobile {
		width: 115px;
		border-radius: 2px;
	}

	.Description__sections:last-child {
		display: none;
	}

	.Description__leftSide {
		justify-content: space-around;
	}

	.Description__icon {
		margin-left: 0;
	}

	.bedOptionsMobile {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
	}

	.Scroll__container2 {
		justify-content: center;
	}
}

@media(max-width: 767px) {
	.Scroll__container2 {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		padding: 0 10px;
	}
}

@media(max-width: 380px) {
	.Scroll__container2 {
		overflow-x: auto;
		overflow-y: hidden;
		justify-content: flex-start;
		margin: 20px 0 0;
	}
}


//==========================RathingBlock========================================

.RathingBlock {
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
	background: #fff;
	border-radius: 5px;
	width: 257px;
	height: 245px;
	padding: 25px;
	display: flex;
	flex-direction: column;
	// margin-left: 143px;
}

.RathingBlock__icon {
	width: 37px;
	height: 33px;
	color: #4c4c4c;
	margin-right: 16px;
}

.RathingBlock__title {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: #4c4c4c;
	display: flex;
	align-items: center;
	// margin-left: 16px;
}

.RathingBlock__rathing {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 700;
	font-size: 17px;
	text-align: center;
	color: #0090ff;
	margin-left: auto;
}

.RathingBlock__rathing_list {
	display: flex;
	flex-direction: column;
}
.RathingBlock__rathing_item {
	display: flex;
	justify-content: space-between;
	margin: 5px 0;
	font-weight: 400;
	font-size: 12px;
	color: #4c4c4c;
}
.RathingBlock__rathing_qantity {
	font-weight: 600;
	font-size: 12px;
}

@media(max-width: 768px) {
	.RathingPayDesc__Block {
		display: flex;
		flex-direction: column;
		padding: 0 10px;
		
	}

	.RathingPay__Block {
		display: flex;
		justify-content: space-around;
	}
}

@media(max-width: 767px) {
	.RathingPay__Block {
		flex-direction: column;
	}

	.RathingBlock {

		max-width: 767px;
		width: 100%;

		max-width: 257px;
		width: 100%;
		margin: 0 auto;

	}
}
//==========================PaymentBlock========================================

.PaymentBlock {
	border-radius: 10px;
	width: 326px;
	height: 135px;
	box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.2);
	background: #fff;
	padding: 14px 17px 9px;
	display: flex;
	flex-direction: column;
	// margin-left: 75px;
	justify-content: space-between;
	margin-top: 23px;
}

.PaymentBlock__icon {
	width: 16px;
	height: 13px;
	margin-right: 20px;
}
.PaymentBlock__payment_item {
	display: flex;
	justify-content: flex-start;
	margin: 5px 0;
	font-weight: 400;
	font-size: 12px;
	color: #4c4c4c;
	font-family: 'Segoe UI', sans-serif;
}

@media(max-width: 767px) {
	.PaymentBlock {
		max-width: 767px;
		width: 100%;
	}
}

@media(max-width: 767px) {
	.PaymentBlock {
		max-width: 767px;
		width: 100%;
	}
}

//==========================DescriptionBlock========================================
.DescriptionBlock {
	border-radius: 10px;
	width: 402px;
	height: 181px;
	background: linear-gradient(225deg, #b0d1ea 0%, #ebf3f9 100%);
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	padding: 20px 18px;
}

.DescriptionBlock__title {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 600;
	font-size: 15px;
	color: #000;
	margin-bottom: 18px;
	line-height: 20px;
}

.DescriptionBlock__text {
	font-weight: 350;
	font-size: 12px;
	line-height: 16px;
	color: #4c4c4c;
	display: flex;
	text-align: center;
}

@media(max-width: 768px) {
	.DescriptionBlock {
		max-width: 100%;
		width: 100%;
		text-align: start;
		height: auto;
	}

	.DescriptionBlock__text {
		text-align: start;
	}
}





@media(max-width: 768px) {
	.DescriptionBlock {
		max-width: 100%;
		width: 100%;
		text-align: start;
		height: auto;
	}

	.DescriptionBlock__text {
		text-align: start;
	}
}




//==========================Feedback========================================
.FeedbackTitle {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 600;
	font-size: 24px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 460px;
	height: 75px;
	background: linear-gradient(180deg, #7b9ee9 0%, #7b9ee9 0.83%, #383838 160%, #000000 7%);
	background-color: #7b9ee9;
	border-radius: 10px 10px 0 0;
	border-radius: 10px 10px 0 0;
}
.FeedbackList {
	max-width: 460px;
	width: 100%;
	max-width: 460px;
	width: 100%;
	height: 103px;
	background-color: #fff;
	display: flex;
	justify-content: center;
	border-radius: 0 0 10px 10px;
}
.FeedbackItem {
	display: flex;
	justify-content: center;
	font-family: 'PT Mono', sans-serif;
	font-weight: 400;
	font-size: 13px;
	text-align: center;
	color: #4c4c4c;
	align-items: center;
}


@media(max-width: 768px) {
	.FeedbackBlock {
		max-width: 768px;
		width: 100%;
		padding: 0 10px;
	}

	.FeedbackTitle {
		width: 100%;
	}

	.FeedbackList {
		max-width: 100%;
	}
	
}


@media(max-width: 768px) {
	.FeedbackBlock {
		max-width: 768px;
		width: 100%;
		padding: 0 10px;
	}

	.FeedbackTitle {
		width: 100%;
	}

	.FeedbackList {
		max-width: 100%;
	}
	
}

//==========================Amenities========================================

.AmenitiesBlock1 {
	font-family: 'Segoe UI', sans-serif;
	// font-weight: 600;
	// font-size: 24px;
	// color: #fff;
	display: flex;
	justify-content: space-between;
	width: 460px;
	flex-wrap: wrap;
	background: transparent;
	margin-top: 47px;
	margin-left: 5px;
	gap: 4px;
	
}
.AmenitiesBlock2 {
	font-family: 'Segoe UI', sans-serif;
	// font-weight: 600;
	// font-size: 24px;
	// color: #fff;
	display: flex;
	
	width: 460px;
	flex-wrap: wrap;
	background: transparent;
	margin-top: 47px;
	margin-left: 5px;
	column-gap: 15px;
	
	margin-left: 5px;
	gap: 4px;
	
}
.AmenitiesBlock2 {
	font-family: 'Segoe UI', sans-serif;
	// font-weight: 600;
	// font-size: 24px;
	// color: #fff;
	display: flex;
	
	width: 460px;
	flex-wrap: wrap;
	background: transparent;
	margin-top: 47px;
	margin-left: 5px;
	column-gap: 15px;
	
}
.AmenitiesBlock__item {
	display: flex;
	display: flex;
	border: 0.2px solid #4c4c4c;
	border-radius: 100px;
	padding: 4px 10px;
	// width: 101px;
	height: 24px;
	background: #fff;
	font-size: 12px;
	color: #ff6000;
	border: none;

	
}



@media(max-width: 768px) {
	.AmenitiesBlock1 {
		max-width: 700px;
		width: 100%;
		margin: 27px auto 27px;
	}

	.AmenitiesBlock2 {
		max-width: 700px;
		width: 100%;
		margin: 27px auto 27px;
		justify-content: space-between;
	}
}

@media(max-width: 767px) {
	.AmenitiesBlock1 {
		padding: 0 10px;
	}

	.AmenitiesBlock__item  {
		font-size: 10px;
	}
} 


@media(max-width: 768px) {
	.AmenitiesBlock1 {
		max-width: 700px;
		width: 100%;
		margin: 27px auto 27px;
	}

	.AmenitiesBlock2 {
		max-width: 700px;
		width: 100%;
		margin: 27px auto 27px;
		justify-content: space-between;
	}
}

@media(max-width: 767px) {
	.AmenitiesBlock1 {
		padding: 0 10px;
	}

	.AmenitiesBlock__item  {
		font-size: 10px;
	}
} 

//==========================RoomDetails========================================
.RoomDetailsBlock {
	display: flex;
	border: 0.5px solid #aeafbc;
	width: 926px;
	background: #ebedfe;
	padding: 11px 0px;
	margin-top: 20px;
	margin-bottom: 50px;
	margin-bottom: 50px;
}
.RoomDetails__description {
	display: flex;
	padding-left: 8px;
}
.RoomDetailsBlock__idTag {
	font-family: 'Segoe UI', sans-serif;
	font-weight: 600;
	font-size: 11px;
	text-align: center;
	color: #4c4c4c;
	margin: 0;
	margin: 0;
	box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.3);
	background: #fff;
	border-radius: 3px;
	width: 46px;
	height: 17px;
}

.RoomDetailsBlock__area {
	display: flex;
	align-items: center;
}
.RoomDetailsBlock__item {
	display: flex;
	display: flex;
	border: none;
	border-radius: 100px;
	padding: 4px 10px;
	height: 24px;
	background: #fff;
	font-size: 12px;
	color: #0090ff;
	margin: 5px;
}

.RoomDetailsBlock__booking_Options {
	background: #fff;
	border-radius: 10px;
	width: 97%;
	padding: 19px;
	margin-top: 35px;
}

.RoomDetails__place {
	display: flex;
	align-items: center;
	gap: 15px;
}

.RoomDetailsBlock__iconsPlusText {
	display: flex;
	align-items: center;
	justify-content: center;
}

.roomDetails {
	padding-bottom: 50px;
}

@media(max-width: 768px) {
	.RoomDetails__description {
		justify-content: space-around;
	}

	.RoomDetailsBlock {
		flex-direction: column;
		width: 100%;
		padding: 10px 10px;
	}

	.RoomDetailsBlock__booking_Options {
		width: 100%;
	}
}

@media(max-width: 767px) {
	.animals {
		display: none;	
	}
	.RoomDetailsBlock__item  {
		font-size: 10px;
		margin: 0;
		display: flex;
		margin: 5px 0px ;
	}

	.RoomDetails__place {
		margin-bottom: 20px;
		max-width: 425px;
		width: 100%;

	}
} 


@media(max-width: 425px) {
	.RoomDetails__place {
		flex-direction: column;
		align-items: flex-start;
	}

	.RoomDetails__PlaceArea {
		max-width: 425px;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
} 


.RoomDetails__place {
	display: flex;
	align-items: center;
	gap: 15px;
}

.RoomDetailsBlock__iconsPlusText {
	display: flex;
	align-items: center;
	justify-content: center;
}

.roomDetails {
	padding-bottom: 50px;
}

@media(max-width: 768px) {
	.RoomDetails__description {
		justify-content: space-around;
	}

	.RoomDetailsBlock {
		flex-direction: column;
		width: 100%;
		padding: 10px 10px;
	}

	.RoomDetailsBlock__booking_Options {
		width: 100%;
	}
}

@media(max-width: 767px) {
	.animals {
		display: none;	
	}
	.RoomDetailsBlock__item  {
		font-size: 10px;
		margin: 0;
		display: flex;
		margin: 5px 0px ;
	}

	.RoomDetails__place {
		margin-bottom: 20px;
		max-width: 425px;
		width: 100%;

	}
} 


@media(max-width: 425px) {
	.RoomDetails__place {
		flex-direction: column;
		align-items: flex-start;
	}

	.RoomDetails__PlaceArea {
		max-width: 425px;
		width: 100%;
		align-items: center;
		display: flex;
		flex-direction: column;
	}
} 

.Animals__item {
	border-radius: 5px;
	// width: 144px;
	height: 23px;
	// background: #fff;
	margin: 5px 0;
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	height: fit-content;
}
.Animals__icon {
	margin-right: 10px;
}
.Animals__text {
	font-family: 'Segoe UI', sans-serif;
	max-width: 175px;
	width: 100%;
	font-weight: 600;
	font-size: 11px;
	color: #4c4c4c;
	background: #fff;
	padding: 8px;
	border-radius: 5px;
	// margin: 5px 0px;
}

