$color-white: #ffffff;
$color-blue: #0090ff;
$color-red: #ff6000;
$color-text: #4c4c4c;

@import './breakpoints';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container-about-company {
	width: 100%;
	margin: 0 auto;
	background: $color-white;

	@media screen and (min-width: $mobile) {
		width: $mobile;
	}

	@media screen and (min-width: $tablet) {
		width: $tablet;
	}

	@media screen and (min-width: $desktop) {
		width: $desktop;
		background-color: #ebecfe;

        & footer {
                margin-right: 166px;
                margin-left: 718px;
            }
	}
}

.company-hero-wrapper {
	background-image: url('../../assets/images/aboutCompany/bg-company-mobile.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	align-content: center;
	width: 100%;

	padding-top: 61px;
	padding-bottom: 96px;
	padding-left: 20px;
	padding-right: 20px;

	@media screen and (min-width: $tablet) {
		background-image: url('../../assets/images/aboutCompany/bg-company-tablet.jpg');
		padding-top: 167px;
		padding-bottom: 223px;
		padding-left: 86px;
		padding-right: 86px;
	}

	@media screen and (min-width: $desktop) {
		background-image: url('../../assets/images/aboutCompany/bg-company-desktop.jpg');
		padding-top: 286px;
		padding-bottom: 350px;
		padding-left: 175px;
		padding-right: 175px;
	}
}

.hero-text-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px 10px;
	margin: 0 auto;
	background-color: $color-white;
	border-radius: 20px;

	@media screen and (min-width: $desktop) {
		padding: 10px 20px;
	}
}

.hero-text-price {
	font-family: 'Segoe UI';
	font-weight: 900;
	font-size: 11px;
	line-height: 1.4em;
	color: $color-red;

	@media screen and (min-width: $tablet) {
		font-size: 20px;
	}

	@media screen and (min-width: $desktop) {
        font-size: 22px;
	}
}

.hero-text-booking {
	font-family: 'Segoe UI';
	font-weight: 900;
	font-size: 11px;
	line-height: 1.4em;
	color: $color-blue;

	@media screen and (min-width:$tablet) {
		font-size: 20px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 22px;
	}
}

.hero-text-info {
	font-family: 'Segoe UI';
	font-size: 9px;
	line-height: 1.3em;
	text-align: center;

	color: $color-text;

	@media screen and (min-width: $tablet) {
		font-size: 20px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 22px;
	}
}

.about-company-text {
	margin-top: 28px;
    margin-bottom: 28px;
	padding-left: 40px;
	padding-right: 40px;

	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 12px;
	line-height: 1.3em;
	text-align: justify;

	color: #4c4c4c;

	@media screen and (min-width: $tablet) {
		font-size: 19px;
		margin-top: 53px;
        margin-bottom: 53px;
		padding-left: 86px;
		padding-right: 86px;
	}

	@media screen and (min-width: $desktop) {
		font-size: 22px;
		margin-top: 74px;
        margin-bottom: 74px;
		padding-left: 202px;
		padding-right: 202px;
	}
}
