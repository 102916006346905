@import 'styles/common/variables';
@import '../BurgerButton/burger-button';

.closeDrawerButton {
  @extend %burgerButton;

  position: relative;
  top: -4px;

  margin-left: auto;
  padding: 5px 8px 6px;

  @media screen and (min-width: $tablet) {
    padding: 10px 13px;
  }

  color: $border-table;
}

.closeDrawerIcon {
  @extend %burgerIcon;
}
