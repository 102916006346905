.container {
	width: 326px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -8px;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 257px;
		margin: 0 auto;
		margin-top: 12px;
	}
}

.description {
	height: 20px;
	margin-bottom: 16px;
	margin-right: -22px;
	padding: 2px 10px;
	display: flex;
	align-items: baseline;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;

	color: #ff6000;
	background: #ffffff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	border-radius: 10px;

	// &:hover {
	// 	&:nth-child(2n + 2) {
	// 		margin-right: 5px;
	// 	}
	// }
}
