.modal__tooltip {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  position: relative;

  &_wrapper {
    border-radius: 50%;
    border: 1px solid #204A87;
    margin: 15px 10px 15px auto;
  }

  &_icon_block {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 50%;
    background: rgba(226, 230, 235, 1);
    border: 2px solid #fff;
    width: 21px;
    height: 21px;
    cursor: pointer;

    &:hover {
      background: rgba(226, 230, 235, 0.7);
    }

    &:active {
      background: rgba(226, 230, 235, 1);
    }
  }

  &_text{
    font-family: 'Segoe UI', sans-serif;
    font-weight: 600;
    font-size: 11px;
    line-height: 14.63px;
    color: #fff;
    background-color: #000;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-45%);
    top: 10px;
    width: 150px;
    text-align: center;
    padding: 5px;
  }
}