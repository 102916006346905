.logoText {
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.35;
  font-family: 'Segoe UI';
  color: #ff6000;
  text-align: justify;
  white-space: nowrap;
}

.logoFirstPart {
}

.logoSecondPart {
  color: #0090ff;
}
