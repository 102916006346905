@import 'styles/common/variables';
@import './toggleButtonShared';

.toggleButton {
	@extend %button-reset;
	@extend %toggle-button-shared;

	&:hover {
		border-color: $colors-brand;
	}
	@media screen and (max-width: $pre-tablet) {
		padding-left: 14px;
	}
}

.buttonText {
	@extend %toggle-button-shared-text;

	@media screen and (max-width: $pre-tablet) {
		font-size: 11px;
	}
}

.arrow {
	width: 9px;
	height: 9px;

	color: $colors-brand;
	transform: rotate(180deg);

	&.active,
	.toggleButton:focus-within & {
		transform: rotate(0);
	}

	&.dark {
		color: $colors-white;
	}
}
