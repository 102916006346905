@import 'styles/common/variables';

.tab__section {
	position: fixed;
	top: 39px;
	width: 100%;
	display: flex;
	justify-content: center;
	font-family: 'Segoe UI';
	font-weight: 700;
	z-index: 1;
	@media screen and (min-width: $tablet) {
		top: 52px;
	}
}

.tab {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
	padding-top: 10px;
	padding-bottom: 10px;
	width: 100%;
	height: 58px;
	color: #a5a5a5;

	cursor: pointer;

	background: #ffffff;
	border-radius: 5px;

	@media screen and (min-width: $tablet) {
		padding-top: 5px;
	}

	&:not(:first-child) {
		@media screen and (min-width: $desktop) {
			padding-left: -65px;
		}
	}
	&:first-child {
		@media screen and (min-width: $desktop) {
			padding-left: 161px;
			padding-right: 66px;
		}
	}

	&:hover {
		color: #535252;
		border: 0.5px solid #000000;
	}

	&_active {
		color: #535252;
		border: 1px solid #626367;
	}
	@media screen and (min-width: $tablet) {
		flex-direction: row;
		height: 29px;
	}

	.offersContainer {
		//position: fixed;
		padding-right: 17px;
		padding-left: 17px;
		margin-right: auto;

		@media screen and (min-width: $tablet) {
			padding-left: 0;
		}
	}
}
.proposeContainer {
	//position: fixed;
	display: flex;
	align-items: baseline;
	margin-right: auto;
	padding-left: 17px;
}
.tab__text {
	position: relative;
	margin-right: auto;
	font-size: 12px;
	line-height: 16px;

	&:first-child {
		margin-bottom: 5px;
	}
}
.userBookingContainer {
	display: flex;
	padding-left: 17px;
	padding-right: 25px;

	@media screen and (min-width: $tablet) {
		padding-right: 20px;
	}
}
.userBooking {
	display: block;
	padding-right: 20px;

	@media screen and (min-width: $tablet) {
		margin: 0 auto;
		padding-right: 28px;
	}
}
.tab__count {
	margin-left: 20px;
	font-size: 11px;
	line-height: 15px;
}

.textWithDot {
	display: block;
	padding-right: 15px;
}
