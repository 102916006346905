.drawerFooter {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 12px;

  margin-top: auto;
}

.pickerAbsolute {
  position: absolute;
  top: -40px;
  right: 0;
}
