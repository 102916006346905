.reviews__section {
	width: 460px;
	margin: 0 auto;
	margin-top: 15px;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 279px;
		margin-top: 0;
	}
}

.wrapReviews {
	background: rgb(123, 158, 233);
}

.reviews {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 75px;

	font-family: Segoe UI;
	font-size: 24px;
	font-weight: 600;
	line-height: 31.92px;

	color: #ffffff;

	background: linear-gradient(
		180deg,
		rgba(196, 196, 196, 0) 0%,
		rgba(96, 96, 96, 0.305) 50.83%,
		rgba(56, 56, 56, 0.43) 71.67%,
		rgba(0, 0, 0, 0.44) 100%
	);

	@media (min-width: 320px) and (max-width: 767px) {
		height: 63px;
	}
}

.reviews__bg {
	height: 103px;
	background: #ffffff;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: 320px) and (max-width: 767px) {
		height: 57px;
	}
}

.reviews__messages {
	font-family: PT Mono;
	font-size: 13px;
	font-weight: 400;
	line-height: 14.56px;
	text-align: center;

	color: #4c4c4c;
}
