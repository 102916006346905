@import 'styles/common/variables';

%logoLink {
	display: inline-flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
}

.logoLink {
	@extend %logoLink;

	position: relative;
	top: -1px;
	left: -3px;

	width: 33px;
	height: 31px;

	@media screen and (min-width: $tablet) {
		width: 50px;
		height: 46px;
	}

	color: $colors-brand;
	outline: none;

	&:hover {
		color: $colors-text-accent;
	}

	&:focus {
		color: $colors-logo-active;
	}
}

.drawerLogoLink {
	@extend %logoLink;

	width: 40px;
	height: 40px;
	padding: 6px 6px 8px 6px;

	color: $colors-brand;

	background-color: $colors-white;
	border-radius: 50%;

	&:hover {
		color: $colors-text-accent;
	}

	&:focus {
		color: $colors-logo-active;
	}
}

.logoIcon {
	width: 100%;
	height: 100%;
}
