@import 'styles/common/variables';

.headerButton {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 33px;
	height: 33px;

	background: none;
	border: 0;
	border-radius: 50%;
	outline: none;
	background-color: transparent;
	color: $colors-text-primary;

	&:hover,
	&:focus,
	&:active {
		border: solid 2px rgba(0, 144, 255, 1);
		background-color: rgba(41, 40, 40, 1);
		transition: $transition;
	}

	@media screen and (min-width: $tablet) {
		border-radius: 2px;
		padding: 5px;

		&:hover,
		&:focus {
			background: rgba(196, 196, 196, 0.3);
		}
	}
}

.headerIcon {
	width: 22px;
	height: 22px;
}

.headerButtonWrapper {
	position: relative;
	display: flex;
	justify-content: center;
}

.tooltipOverlay {
	position: absolute;
	top: 41px;
	left: 0;
	z-index: 2;

	display: flex;
	align-items: center;
	justify-content: center;

	min-width: 70px;
	width: fit-content;
	height: 21px;
	max-width: 190px;
	padding: 0 10px;

	white-space: nowrap;

	visibility: hidden;
	background-color: #282828;
	border-radius: 5px;
	opacity: 0;
	transition: 0.2s 0.21s $cubic;
	transition-property: opacity, visibility;

	.headerButton:hover + & {
		&.delay {
			transition-delay: 0.7s;
		}

		opacity: 1;
		visibility: visible;
	}
}

.buttonTooltip {
	font-weight: 700;
	font-size: 11px;
	line-height: 1.5;
	font-family: $font-segoe;
	color: $colors-white;
}

.unreadMsgs {
	position: absolute;
	top: 5px;
	left: 16px;

	padding: 0 4px;

	font-size: 8px;
	line-height: 1.5;
	font-family: $font-segoe;
	color: $colors-white;

	background: #ff6000;
	border: 0.5px solid #ffffff;
	border-radius: 50%;
}
