.input_container {
	width: 100%;
	height: 39px;
	padding: 0 12px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	background-color: #ffffff;

	transition: background-color cubic-bezier(0.4, 0, 0.2, 1);
}

.input_container:focus,
.input_container:hover {
	background-color: #ebedfe;
}

.search_icon {
	width: 20px;
	height: 20px;
	margin-right: 14px;
}

.input {
	width: 200px;
	padding: 6px 15px;
	border: none;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
	border-radius: 10px;

	font-family: 'Segoe UI', serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 1.33em;
	color: #4c4c4c;
}

.input:hover,
.input:focus {
	border: 1px solid #0090ff;
	outline: none;
}

.list_location_photo {
	margin-right: 19px;
	border: 0.25px solid #656565;
	border-radius: 10px;
}

.list_location_text {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
}

.city_text {
	font-family: 'Segoe UI', serif;
	font-weight: 700;
	font-size: 12px;
	line-height: 1.33em;
	color: #626367;
}

.country_text,
.region_text {
	font-family: 'Segoe UI', serif;
	font-size: 11px;
	line-height: 1.33em;
	color: #626367;
}

.list_container {
	width: 100%;
	background-color: #ffffff;
	height: 15px;
	border-radius: 6px;
}

.list_container {
	width: 100%;
	height: auto;
}

.list_location_item {
	padding: 13px;
	display: flex;
	align-items: center;

	transition: background-color cubic-bezier(0.4, 0, 0.2, 1);
}

.list_location_item:not(:last-child) {
	margin-bottom: 11px;
}

.list_location_item:hover {
	background-color: rgba(0, 107, 190, 0.1);
}

.list_location_item:focus,
.list_location_item:active {
	background-color: rgba(0, 107, 190, 0.22);
}
