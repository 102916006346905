%suggest-block-style {
	margin: 0 auto;
	width: 233px;
	min-height: 34px;
	background: #ffffff;
	border-radius: 5px;
}
%suggest-text-style {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 1.5;
	color: #626367;
}
%suggest-position-text {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 66px;
	padding-right: 8px;
}

.container {
	margin: 0 auto;
	width: 314px;
}
.close_btn {
	width: 83px;
	height: 49px;
	padding: 0 0 0 25px;
	position: absolute;
	top: 0px;
	right: 2%;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 900;
	font-size: 11px;
	line-height: 1.3;
	border: none;
	color: #ffffff;
	cursor: pointer;
	background-color: transparent;
	z-index: 15;

	&::before {
		content: '';
		width: 11px;
		height: 11px;
		background-image: url(../Assents/icon/clouse.svg);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:hover {
		width: 83px;
		height: 47px;
		background: rgba(76, 76, 76, 0.12);
		border-radius: 33px;
	}

	&:active {
		width: 83px;
		height: 49px;
		background: rgba(76, 76, 76, 0.22);
		border-radius: 33px;
	}
}

.suggest__date {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	margin-bottom: 5px;
	&::before {
		content: '';
		background-image: url(../Assents/icon/weekly-calendar.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		width: 20px;
		height: 20px;
	}
}
.suggest__select_date {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	margin-bottom: 5px;
	&::before {
		content: '';
		background-image: url(../Assents/icon/weekly-calendar.svg);
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		width: 20px;
		height: 20px;
	}
}

.suggest__select_date_mobile {
	@extend .suggest__date;
	color: #656565;
}
.suggest__location {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	margin-bottom: 5px;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surface1.svg);
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		left: 25px;
		width: 20px;
		height: 20px;
	}
}

.span_location {
	@extend%suggest-text-style;
	font-weight: 400;
}

.suggest__select_location_mobile {
	@extend .suggest__location;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surface_grey.svg);
	}
}
.suggest__select_location {
	position: relative;
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);
	// box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.2);
	color: #ffffff;
	margin-bottom: 5px;
	&::before {
		content: '';
		background-image: url(../Assents/icon/surfacewite.svg);
		background-position: center;
		background-repeat: no-repeat;
		position: absolute;
		left: 25px;
		width: 20px;
		height: 20px;
	}
}
.suggest__bedroom {
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	margin-bottom: 19px;
	&::before {
		content: '';
		background-image: url(../Assents/icon/icon-bed.svg);
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		left: 22px;
		width: 28px;
		height: 28px;
	}
}

.suggest__select_bedroom {
	@extend%suggest-block-style;
	@extend%suggest-position-text;
	@extend%suggest-text-style;
	background: rgba(0, 107, 190, 1);

	margin-bottom: 19px;
	padding-left: 8px;
}

.suggest__select_bedroom_mobile {
	@extend%suggest-text-style;

	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 18px;
	padding-left: 16px;

	// padding-right: 8px;
}

.suggest__select_date,
.suggest__select_location,
.suggest__select_bedroom {
	&.animate-color {
		animation: sugColorChange 2s linear forwards;
	}
}

@keyframes sugColorChange {
	from {
		background-color: #ffffff;
	}
	to {
		background-color: rgba(0, 107, 190, 1);
	}
}

.reverse-animation {
	animation: sugReverseColorChange 2s linear forwards;
}

@keyframes sugReverseColorChange {
	from {
		background-color: rgba(0, 107, 190, 1);
	}
	to {
		background-color: #ffffff;
	}
}

.arrow__icon__selectedbed {
	// width: 22px;height: 22px;
	fill: #aeafbc;
}
