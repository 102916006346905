@import 'styles/common/variables';
@import 'styles/common/breakpoints';

.pickerDropdown {
	top: -6px;
	left: -8px;

	display: flex;
	flex-direction: column;
	gap: 10px;

	padding: 6px 8px 13px 8px;

	background-color: $colors-white;
	box-shadow: $box-shadow-notification;
	border-radius: 5px;
	background-color: $colors-bg-drawer;

	z-index: 2; // Oleksandr

	@media screen and (min-width: $desktop) {
		background-color: $colors-white;
	}

	&.dark {
		background-color: $colors-bg-drawer;
	}
	@media screen and (max-width: $pre-tablet) {
		align-items: start;

		// padding: 6px 13px 13px 13px;
	}
}
