.container {
	width: 254px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 29px;
}

.description {
	height: 20px;
	margin-bottom: 8px;
	margin-right: 15px;
	padding: 2px 10px;
	display: flex;
	align-items: baseline;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 300;
	font-size: 12px;
	line-height: 16px;

	color: #ff6000;
	background: #ffffff;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
}
