$hero_text_color: #ffffff;
$text_color: #656565;
$title_color: #0090ff;
$second_text_color: #ff6000;

$text_font_family: 'Segoe UI', sans-serif;
$normal_font_weigth: 400;
$ligth_font_weigth: 600;
$light_bold_font_weight: 700;
$bold_font_weigth: 900;
