@import 'styles/common/breakpoints';

.chat-container {
	margin-bottom: 36px;
	height: 600px;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: $tablet) {
		flex: 1;
		width: 20%;
		flex-grow: 1;
		height: auto;
	}
}

.logo-section {
	padding: 24px 24px 24px 31px;
	text-align: center;
	background-color: #ffffff;
	border: 0.5px solid #656565;
	border-radius: 10px 10px 0px 0px;
	flex: 1;
}

.chat-title {
	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	color: #656565;

	margin-bottom: 10px;
}

.chat-icon {
	width: 50px;
	height: 50px;
}

.send-message-section {
	background-color: rgba(196, 196, 196, 1);
	width: 100%;
	padding: 13px;
	border-radius: 0px 0px 10px 10px;
	border: 0.5px solid #656565;
	flex: 4;
	display: flex;
	flex-direction: column;
}

.show-message-wrapper {
	background-color: #ffffff;
	border-radius: 6px;
	margin-bottom: 15px;
	flex: 3;
}

.input-wrapper {
	position: relative;
	flex: 1;
}

.input-message {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 6px;
	border: none;
	padding: 8px;

	font-family: 'Segoe UI';
	font-weight: 600;
	font-size: 11px;
	line-height: 15px;

	color: #4c4c4c;
	resize: none;
}

.input-message:hover,
.input-message:active,
.input-message:focus {
	outline: none;
}

.show-message-wrapper {
	padding: 7px;
	width: 100%;
	word-wrap: break-word;
	overflow-y: auto;
}

.show-message {
	font-family: Segoe UI;
	font-size: 11px;
	font-weight: 600;
	line-height: 15px;
	color: #0090ff;
}

.send-button {
	position: absolute;
	bottom: 7px;
	right: 7px;
	border: none;
	background-color: transparent;
	cursor: pointer;
}

.send-icon {
	fill: #4c4c4c;
	stroke: #4c4c4c;
}

.input-message:focus + .send-button .send-icon path {
	fill: #ffffff;
}

.send-button:not(.empty):hover .send-icon path {
	fill: #787878;
	stroke: #4c4c4c;
}
