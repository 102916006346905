@import 'styles/common/variables';

.theme_switcher {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 34px;
	gap: 10px;
	padding: 0px 25px;

	font-weight: 600;
	font-size: 13px;
	line-height: 17px;
	margin-right: 30px;

	font-family: 'Segoe UI', sans-serif;
	color: $colors-text-primary;

	&:hover,
	&:focus {
		color: rgb(133, 129, 129);
	}
	&:active {
		color: rgb(164, 155, 155);
	}
	@media screen and (min-width: $tablet) {
		margin-right: 25px;
		padding: 0;
		width: 125px;
	}
}

.theme_switcher.dark {
	color: $colors-white;

	&:hover,
	&:focus {
		color: rgb(235, 232, 232);
	}
	&:active {
		color: rgb(219, 214, 214);
	}
}

img.light {
	background-color: $colors-text-primary;
}
