@import 'styles/common/variables';

.drawerTopRow {
  contain: strict;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;

  height: 40px;

  padding-right: 16px;
  padding-left: 10px;

  @media screen and (min-width: $tablet) {
    padding-right: 0px;
  }
}
