.gallery {
	display: flex;
	position: absolute;
	flex-direction: column;
	align-items: flex-end;
	right: 14px;

	@media (min-width: 320px) and (max-width: 767px) {
		position: relative;
		margin: 0 auto;
		right: 0;
	}
}

.content_wrapper {
	&.smallS {
		margin-right: 12px;
	}

	&.mediumS {
		margin-right: 32px;
	}

	&.treeCollums {
		margin-right: 10px;
	}
}

.big__photo {
	&.smallS {
		width: 162px;
		height: 162px;
		border-radius: 7px;
	}

	&.mediumS {
		width: 218px;
		height: 218px;
		border-radius: 5px;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 118px;
			height: 112px;
		}
	}
}

.photo_gallery {
	margin-top: 11px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 8px;

	&.smallS {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 8px;
	}

	&.treeCollums {
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 8px;
	}
}

.small_photo {
	width: 75px;
	height: 75px;
	cursor: pointer;
	border-radius: 7px;

	@media (min-width: 320px) and (max-width: 767px) {
		width: 36px;
		height: 36px;
	}

	&.treeCollums {
		width: 75px;
		height: 75px;

		@media (min-width: 320px) and (max-width: 767px) {
			width: 36px;
			height: 36px;
		}
	}
}
.small_photo:hover {
	filter: brightness(0.7);
}
.small_photo:active {
	filter: brightness(0.5);
}
