@import 'styles/common/breakpoints';

.threeBedRooms__section {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 233px;
	padding: 5px 22px 14px 22px;
	margin: 0 auto 30px auto;
	border-radius: 5em/0.5em;

	background: #ffffff;
	// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px 3px rgba(0, 107, 190, 0.3);

	z-index: 5;
	@media screen and (min-width: $tablet) {
		width: auto;
		position: absolute;
		top: 93%;
		left: -1%;
		margin: 0;
	}
}

.threeBedRooms__group {
	display: flex;
	justify-content: space-between;
	width: 600px;
	flex-direction: column;
	align-items: center;
	@media screen and (min-width: $tablet) {
		flex-direction: row;
	}
}

// .threeBedRooms__div:not(:last-child) {
// 	margin-right: 28px;
// }

.threeBedRooms__text {
	text-align: center;
	margin-bottom: 9px;
	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 17px;
	line-height: 23px;
	color: #787878;
	@media screen and (min-width: $tablet) {
		text-align: left;
	}
}

.threeBedRooms__block {
	padding: 25px;

	width: 195.8px;
	height: 130.9px;

	background: #ffffff;
	border: 1px solid #787878;
	// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 5px;

	&--active {
		border: 3px solid rgba(0, 144, 255, 0.5);
	}
}

.threeBedRooms__selected__block {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.threeBedRooms__selected__bed {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	// margin-left: 20px;
	// margin-top: 22px;
	// margin-bottom: 22px;
	// &--modif {
	// 	margin-bottom: 0;
	// }
}

.threeBedRooms__icon {
	width: 30px;
	height: 25px;
	fill: #787878;

	&--single {
		width: 30px;
		height: 23px;
	}
}

.threeBedRooms__btn__start {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26.37px;
	height: 26.37px;
	border: 1px solid rgba(120, 120, 120, 0.1);
	background: #ffffff;
	border-radius: 50%;
	fill: rgba(120, 120, 120, 0.1);

	// &--double {
	// 	margin-left: 32.9px;
	// }

	// &--single {
	// 	margin-left: 37px;
	// }
}

.threeBedRooms__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26.37px;
	height: 26.37px;
	border: 1px solid #787878;
	background: #ffffff;
	border-radius: 50%;
	cursor: pointer;
	fill: #787878;
	transition: border 250ms, outline 250ms, fill 250ms;
	// &--double {
	// 	margin-left: 32.9px;
	// }

	// &--single {
	// 	margin-left: 37px;
	// }

	&:hover {
		border: 0.5px solid #787878;
		outline: 0.5px solid #787878;
		fill: rgba(120, 120, 120, 0.5);
	}

	&:focus {
		border: 0.1px solid rgba(120, 120, 120, 0.65);
		fill: rgba(120, 120, 120, 0.5);
	}
}

.threeBedRooms__decrement {
	width: 11.88px;
	height: 11.88px;
}

.threeBedRooms__increment {
	width: 11.88px;
	height: 11.88px;
}

.threeBedRooms__counter {
	// margin-left: 12.01px;
	// margin-right: 12px;
	width: 20px;
	text-align: center;
	font-family: 'Segoe UI';
	font-weight: 400;
	font-size: 16.5003px;
	line-height: 22px;
	color: #4c4c4c;
}

.threeBedRooms__confirmBtn {
	margin-top: 16px;
	padding: 8px 34px;
	width: 119px;
	height: 37px;
	background: rgba(0, 144, 255, 0.5);
	// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	border: none;

	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;
}

.threeBedRooms__confirmBtn__eneble {
	outline: none;
	background: rgba(0, 144, 255, 0.75);
	// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
}

.threeBedRooms__confirmBtn__active {
	transition: background-color 250ms;
	&:hover,
	&:focus {
		background-color: #0090ff;
		// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
	}
}

//first message

.threeBedRooms__firstDoubleBedRoom__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: -74px;
	top: 26px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__firstDoubleBedRoom__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__firstDoubleBedRoom__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}

.threeBedRooms__firstSingleBed__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: -74px;
	top: 86px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__firstSingleBed__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__firstSingleBed__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}

//second message

.threeBedRooms__secondDoubleBedRoom__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: 144px;
	top: 26px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__secondDoubleBedRoom__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__secondDoubleBedRoom__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}

.threeBedRooms__secondSingleBed__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: 144px;
	top: 86px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__secondSingleBed__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__secondSingleBed__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}
//third message

.threeBedRooms__thirdDoubleBedRoom__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: 370px;
	top: 26px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__thirdDoubleBedRoom__ {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__thirdDoubleBedRoom__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}

.threeBedRooms__thirdSingleBed__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: 370px;
	top: 86px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.threeBedRooms__thirdSingleBed__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .threeBedRooms__thirdSingleBed__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}
