@import 'styles/common/variables';

.hotelierSettingsList {
	background-color: $colors-white;

	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.hotelierSettingsItem {
	&:last-of-type {
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;

		& .hotelierLink {
			border-bottom-right-radius: 4px;
			border-bottom-left-radius: 4px;
		}
	}
}

.hotelierLink {
	display: flex;
	align-items: center;
	gap: 15px;

	padding: 11px 0 12px 11px;

	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	font-family: $font-segoe;
	color: $colors-text-primary;

	text-decoration: none;
	word-break: break-word;

	&:hover,
	&:focus,
	&:active {
		font-weight: 700;
		color: $colors-white;
		background-color: $colors-text-accent;
	}
}

.hotelierLogOutButton {
	@extend %button-reset;
	@extend .hotelierLink;
	width: 100%;
}

.hotelierLinkIconWrapper {
	width: 14px;
	height: 14px;

	flex-shrink: 0;
}

.hotelierMobileIcon {
	width: 100%;
	height: 100%;

	.hotelierLink:focus & {
		color: $colors-white;
		background-color: $colors-text-accent;
	}
	.hotelierLink:hover & {
		color: $colors-white;
		background-color: $colors-text-accent;
	}
}
.hotelierMobileIcon.dark {
	.hotelierLink:focus & {
		background-color: $colors-text-accent;
	}
	.hotelierLink:hover & {
		background-color: $colors-text-accent;
	}
}

.gear,
.arrowUpChevron {
	color: $border-table;
}

.eyeTriangle,
.star,
.sliders,
.karma,
.exit {
	color: $colors-text-primary;
}

.loading {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(153, 149, 149, 0.5);
	z-index: 1000;
}

.dark {
	background-color: $colors-bg-dark;
	color: $colors-white;
}
