@import 'styles/common/breakpoints';

.mobileOverlay {
  &Base {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 2000;

    display: flex;
    overflow-y: auto;

    opacity: 0;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &AfterOpen {
    opacity: 1;
  }

  &BeforeClose {
    opacity: 0;
  }
}

.drawer {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;

  display: flex;

  min-height: 100%;
  background-color: #292828;
  outline: none;

  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &AfterOpen {
    transform: translateY(100%);
  }

  &BeforeClose {
    transform: translateY(0);
  }
}
