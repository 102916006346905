@import 'styles/common/variables';

.avaImg {
	width: 23px;
	height: 23px;
	margin-right: 5px;
	border-radius: 3px;
}

.subheaderAgent {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.relativeContainer {
	position: relative;

	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	border: 0.5px solid transparent;
	border-radius: 5px;
}

.agentCard {
	display: flex;
	align-items: flex-end;
	gap: 6px;
}

.agentNameWrapper {
	font-size: 11px;
	line-height: 15px;
	font-family: $font-segoe;
	color: $colors-black;
}

.agentSettingList {
	background-color: inherit;

	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.agentSettingsItem {
	display: flex;
	flex-direction: column;
}

.iconDollar {
	color: $colors-brand;
}

.toggleAgentSettingsButton {
	@extend %button-reset;

	display: flex;
	align-items: end;
	justify-content: space-between;
	gap: 12px;

	@media screen and (min-width: $tablet) {
		width: 209px;
		padding: 3px 19px 3px 4px;
	}

	background-color: inherit;
	border: 1px solid transparent;
	border-radius: 4px;

	&.overlayOpen,
	&:hover {
		border: 1px solid #d0d0d0;
	}
}

.agentSettingsDropdownOverlay {
	position: absolute;
	z-index: 2;

	display: flex;
	flex-direction: column;
	gap: 4px;

	width: 209px;

	background-color: $colors-white;
	border-top: none;
	border-bottom-left-radius: 4px;
	border-bottom-left-radius: 4px;
	box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);

	@media screen and (max-width: $pre-tablet) {
		top: 105%;
		left: -107px;
	}

	@media screen and (min-width: $tablet) {
		top: 98%;
		left: 0;
	}
}

%settingsIcon {
	width: 24px;
	height: 25px;
}

.iconThumb,
.houseSmall,
.twoPersons,
.dollar,
.computer,
.exit {
	@extend %settingsIcon;
}

.iconThumb {
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.houseSmall,
.dollar {
	color: $colors-brand;
}

.exit {
	width: 20px;
}

.settingsButton {
	--color-second-person: #8894ff;
	--color-first-person: #6aff79;
	@extend %button-reset;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 15px;

	min-width: 147px;
	padding: 6px 12px 6px 6px;

	font-weight: 600;
	font-size: 12px;
	line-height: 1.3;
	font-family: $font-segoe;
	color: $colors-city;

	&:hover,
	&:focus,
	&:active {
		font-weight: 700;
		color: $colors-white;
		background-color: $colors-brand;
	}

	&:hover %settingsIcon,
	&:focus %settingsIcon {
		color: currentColor;
		mix-blend-mode: lighten;
		--color-first-person: #fff;
		--color-second-person: #fff;
	}
}

.buttonTextWrapper {
	display: inline-flex;
	flex-grow: 1;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
}

.bold {
	font-size: 12px;
	line-height: 16px;
}

.alignLeft {
	text-align: left;
}

.agentCardMobile {
	display: flex;
	align-items: center;
	gap: 6px;

	padding: 6px 12px;
}

.loading {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(153, 149, 149, 0.5);
	z-index: 1000;
}
.dark {
	background-color: $colors-bg-dark;
	color: $colors-white;
}
