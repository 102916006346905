@import 'styles/common/breakpoints';

.gbvcss__section {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 270px;
	padding: 23px 22px 33px 20px;
	margin: 0 auto 30px auto;
	border-radius: 5em/0.5em;
	background: #ffffff;
	// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	box-shadow: 0px 0px 10px 3px rgba(0, 107, 190, 0.3);

	z-index: 5;
	@media screen and (min-width: $tablet) {
		position: absolute;
		top: 93%;
		left: -1%;
		margin: 0;
	}
}

.gbvcss__block {
	padding: 0px 25px;
	width: 195.8px;
	height: 130.9px;

	background: #ffffff;
	border: 1px solid #787878;
	// box-shadow: 0px 0px 5.5001px rgba(0, 0, 0, 0.3);
	border-radius: 5px;

	&--active {
		border: 3px solid rgba(0, 144, 255, 0.5);
	}
}

.gbvcss__selected__block {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.gbvcss__bed {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// margin-left: 20px;
	// margin-top: 54px;
	// margin-bottom: 54px;
}

.gbvcss__icon {
	width: 30px;
	height: 25px;
	fill: #787878;

	&--single {
		width: 30px;
		height: 23px;
	}
}

.gbvcss__btn__start {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26.37px;
	height: 26.37px;
	border: 1px solid rgba(120, 120, 120, 0.1);
	background: #ffffff;
	border-radius: 50%;
	fill: rgba(120, 120, 120, 0.1);

	// &--single {
	// 	margin-left: 37px;
	// }
}

.gbvcss__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 26.37px;
	height: 26.37px;
	border: 1px solid #787878;
	background: #ffffff;
	border-radius: 50%;
	cursor: pointer;
	fill: #787878;
	transition: border 250ms, outline 250ms, fill 250ms;
	// &--single {
	// 	margin-left: 37px;
	// }

	&:hover {
		border: 0.5px solid #787878;
		outline: 0.5px solid #787878;
		fill: rgba(120, 120, 120, 0.5);
	}

	&:focus {
		border: 0.1px solid rgba(120, 120, 120, 0.65);
		fill: rgba(120, 120, 120, 0.5);
	}
}

.gbvcss__decrement {
	width: 11.88px;
	height: 11.88px;
}

.gbvcss__increment {
	width: 11.88px;
	height: 11.88px;
}

.gbvcss__counter {
	// margin-left: 12.01px;
	// margin-right: 12px;
	width: 20px;
	text-align: center;
	font-family: 'Segoe UI';
	font-weight: 400;
	font-size: 16.5003px;
	line-height: 22px;
	color: #4c4c4c;
}

.gbvcss__confirmBtn {
	margin-top: 12.94px;
	padding: 8px 34px;
	background: rgba(0, 144, 255, 0.5);
	// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
	border-radius: 3px;
	border: none;

	width: 119px;
	height: 37px;

	font-family: 'Segoe UI';
	font-weight: 700;
	font-size: 15px;
	line-height: 20px;
	text-align: center;

	color: #ffffff;
}

.gbvcss__confirmBtn__eneble {
	outline: none;
	background: rgba(0, 144, 255, 0.75);
	// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
}

.gbvcss__confirmBtn__active {
	transition: background-color 250ms;
	&:hover,
	&:focus {
		background-color: #0090ff;
		// box-shadow: 0px 0px 13px 1px rgba(0, 0, 0, 0.25);
	}
}
.gbvcss__overlay {
	position: absolute;
	display: flex;
	padding: 4px 8px;
	z-index: 4;
	align-items: center;
	justify-content: center;
	text-align: center;

	width: 171px;
	height: 32px;

	background: #000000;
	color: #ffffff;

	border-radius: 3px;

	font-family: 'Segoe UI', sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;

	left: -74px;
	top: 46px;
	cursor: default;
	opacity: 0;
	transition: opacity 0.5s;
	visibility: hidden;
	transition-property: opacity, visibility;
}

.gbvcss__hover {
	transition: opacity 250ms, visibility 250ms;
	&:hover .gbvcss__overlay {
		opacity: 1;
		visibility: visible;
		transition-delay: 0.5s;
	}
}
