@import 'styles/common/variables';

.hotelNameAndRating {
	display: flex;
	flex-direction: column;
	gap: 3px;
}

.hotelName {
	font-size: 11px;
	line-height: 1.29;
	font-family: $font-segoe;
	color: $colors-black;
}

.hotelRatingBox {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 21px;
	height: 13px;

	background-color: $colors-white;
	border-radius: 3px;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}

.hotelRating {
	font-weight: 700;
	font-size: 7px;
	line-height: 1.29;
	font-family: $font-segoe;
	color: $colors-brand;
	text-align: center;
}
.dark {
	color: $colors-white;
}
