.drawerPickers {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 41px;

  min-height: 24px;
  padding-right: 18px;
  padding-left: 24px;
}
