@import 'styles/common/breakpoints';
$color-border-hover: #0090ff;
$color-border: #a5a5a5;
$color-text-input-location: #4c4c4c;
$color_hover_background: #e5e5e5;
$color_active_background: rgba(229, 229, 229, 0.6);
$color-point-location-def: #aeafbc;
$color-point-location-active: #006bbe;
$color-input-location: rgba(76, 76, 76, 1);

%text-region-list-style {
	font-family: 'Segoe UI', serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	color: $color-text-input-location;
}
%text-location-list-style {
	font-family: 'Segoe UI', serif;
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	color: #626367;
}

%body__border-hover-input {
	transition: border 250ms, outline 250ms;
	&:hover {
		outline: none;
		border: 1px solid $color-border-hover;
	}

	&:focus {
		border: 1px solid $color-border-hover;
		border-radius: 0;
		border-top-right-radius: 20px;
		border-top-left-radius: 20px;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
}
%scroll-container {
	overflow-y: scroll;
	overflow-x: hidden;
	scroll-snap-type: y mandatory;
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(183, 189, 198);
		height: 15px;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		-ms-border-radius: 6px;
		-o-border-radius: 6px;
	}
	&::-webkit-scrollbar-track {
		background: inherit;
	}
	&::-webkit-scrollbar-track-piece:start {
		margin-top: 10px;
	}
	&::-webkit-scrollbar-track-piece:end {
		margin-bottom: 10px;
	}
}

.location__body {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	width: 260px;
	height: 100%;
	background: #ffffff;

	// box-shadow: 0px 0px 4.27972px 1.06993px rgba(0, 0, 0, 0.27);
	overflow: hidden;
	animation: 0.3s ease-in-out 0s 1 normal none running animation-madalWindow;
	box-sizing: border-box;
	-webkit-animation: 0.3s ease-in-out 0s 1 normal none running animation-madalWindow;

	z-index: 100;
}
@keyframes animation-madalWindow {
	0% {
		transform: scale(0.95);
	}
	66% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
.location__container {
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-top: 14px;
}
.input_container {
	position: relative;
	width: 100%;
	height: 39px;
	padding: 0 12px;
	box-sizing: border-box;

	&::before {
		content: '';
		background-image: url('../../assets/images/search/search 90.svg');
		background-repeat: no-repeat;
		position: absolute;
		width: 20px;
		height: 20px;
		left: 30px;
		top: 11px;
	}
}

.input {
	width: 100%;
	height: 100%;

	padding: 11px 55px;

	outline: none;

	border: 1px solid $color-border;
	border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	@extend %text-location-list-style;
	@extend%body__border-hover-input;
	background-color: #ffffff;

	&::placeholder {
		color: rgba(98, 99, 103, 0.5);
	}
}
.list_container {
	display: flex;
	flex-direction: column;

	width: 260px;
	height: 279px;

	padding-bottom: 24px;

	background: #ffffff;

	// box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
	overflow-y: auto;
	animation: 0.3s ease-in-out 0s 1 normal none running animation-modalWindow;
	box-sizing: border-box;
	-webkit-animation: 0.3s ease-in-out 0s 1 normal none running animation-modalWindow;

	@extend%scroll-container;

	z-index: 20;
}
@keyframes animation-modalWindow {
	0% {
		transform: scale(0.95);
	}
	66% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
}
.list {
	display: flex;
	flex-direction: column;
	justify-content: center;

	width: 100%;

	box-sizing: border-box;
	list-style-type: none;
}

.list_item {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	gap: 5px;

	padding: 0 12px 0 24px;

	box-sizing: border-box;
	margin: 5px 0px 0px 0px;
	overflow: hidden;
	height: 52px;
	cursor: pointer;
	scroll-snap-align: start;
	scroll-snap-stop: always;
	transition: background-color 250ms;
	&:hover,
	&:active,
	&:focus {
		background-color: $color_active_background;
	}
	// @extend%point-location-icon;
}

.location_text {
	@extend %text-location-list-style;
	margin-right: 10px;
}
.region_text {
	@extend %text-region-list-style;
	margin-right: 10px;
}
.country_text {
	@extend %text-region-list-style;
}
.custom_select_box {
	position: relative;
	width: 307px;
	border-radius: 10px;
	margin-bottom: 11px;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	@media screen and (min-width: $tablet) {
		position: relative;
		width: 300px;
		height: 40px;
		margin-bottom: 0;
		box-shadow: none;
	}
}

.custom_input_wrapper {
	width: 100%;
	background-color: rgba(255, 255, 255, 1);
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	padding-top: 20px;
	padding-bottom: 10px;
}

.custom_input_select {
	width: 265px;
	height: 28px;
	border: 1px solid rgba(0, 144, 255, 1);
	border-radius: 3px;
	outline: none;
	font-family: 'Segoe UI', serif;
	font-size: 15px;
	line-height: 1.32;
	color: $color-input-location;
	white-space: nowrap; /* Забороняє перенос тексту на новий рядок */
	overflow: hidden; /* Обрізає зайвий текст, який не вміщується в контейнер */
	text-overflow: ellipsis;
	display: block;
	margin: 0 auto;
	@media screen and (min-width: $tablet) {
		width: 270px;
		height: 40px;
		text-align: left;
		border: none;
		border-radius: 0px;
		margin: 0;
		font-size: 17px;
	}
}

.select_options {
	width: 100%;
	max-height: 325px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	padding-bottom: 10px;
	background-color: rgba(255, 255, 255, 1);
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		background-color: rgba(0, 144, 255, 1);
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
		top: 0;
		left: 0;
		right: 0;
		height: 15px;
	}
	@media screen and (min-width: $tablet) {
		position: absolute;
		top: 120%;
		left: -50px;
		z-index: 20;
		width: 335px;
		max-height: 400px;
		padding: 12px;
		border-radius: 10px;
		box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	}
}

.select_scroll_box {
	width: 100%;
	max-height: 325px;
	overflow: auto;
	@media screen and (min-width: $tablet) {
		border-radius: 10px;
		max-height: 388px;
	}
}

.icon_arrow_down {
	position: absolute;
	right: 5%;
	top: 40%;
	width: 13px;
	height: 13px;
}

.icon_arrow_up {
	position: absolute;
	right: 5%;
	top: 35%;
	width: 12px;
	height: 12px;
	transform: rotate(180deg);
}

.icon_location {
	position: absolute;
	left: -28.6px;
	top: -2px;
	width: 15px;
	height: 35px;
	fill: rgba(0, 107, 190, 1);
}

.icon_input {
	position: absolute;
	left: 0;
	top: 6px;
	fill: rgba(0, 107, 190, 1);
}

.icon_placeholder {
	position: absolute;
	left: 0;
	top: 6px;
	fill: rgba(0, 107, 190, 1);
	width: 19px;
	height: 19px;
}

.icon_placeholder_mobile {
	position: absolute;
	left: 26px;
	top: 24px;
	fill: rgba(165, 165, 165, 1);
	width: 21px;
	height: 21px;
}

.icon_placeholder_focused_mobile {
	position: absolute;
	left: 30px;
	top: 24px;
	fill: rgba(165, 165, 165, 1);
	width: 19px;
	height: 19px;
}

.location_wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
}

.location_list_item:first-child {
	margin-top: 10px;
}
.location_list_item:last-child {
	margin-bottom: 10px;
}

.location_list_item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 31px;
	// padding-top: 5px;
	// padding-bottom: 5px;
	min-height: 45px;
	transition: background-color 250ms ease;
	&:hover {
		background-color: rgba(229, 229, 229, 0.6);
		.icon_list_item_location {
			fill: rgba(0, 107, 190, 1);
		}
	}
	&:active {
		background-color: rgba(229, 229, 229, 1);
		.icon_list_item_location {
			fill: rgba(0, 107, 190, 1);
		}
	}
	@media screen and (min-width: $tablet) {
		border-radius: 10px;
		padding: 10px;
		height: auto;
	}
}

.icon_list_item_location {
	width: 15px;
	height: 35px;
	margin-right: 20px;
	flex-shrink: 0;
	fill: rgba(174, 175, 188, 1);
	transition: fill 225ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.icon_location_mobile {
	position: absolute;
	left: 30px;
	top: 50%;
	transform: translateY(-50%);
	fill: rgba(0, 107, 190, 1);
	width: 19px;
	height: 19px;
}