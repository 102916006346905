%text-style4 {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 141%;
	color: #4c4c4c;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-appearance: textfield;
}
input[type='number']:hover,
input[type='number']:focus {
	-appearance: number-input;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.amount__container {
	position: absolute;
	z-index: 1;
	top: 95px;
	left: 50%;
	transform: translateX(-50%);
	width: 483px;
	background: #ffffff;
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 34px 62px 40px 62px;

	@media screen and (max-width: 768px) {
		max-width: 96%;
	}
}

.arrow__btn {
	position: absolute;
	top: 25px;
	left: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 52px;
	width: 52px;
	cursor: pointer;
	border-radius: 50%;
	fill: #787878;
	background: inherit;
	border: none;
	&:hover,
	&:focus {
		background: #ededed;
		fill: #6e6d6d;
	}
}
.icon__arrow-btn {
	width: 29px;
	height: 17px;
}

.title__text1 {
	margin-bottom: 10px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 141%;
	color: #4c4c4c;
	text-align: center;
}

.title__text2 {
	margin-bottom: 20px;
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	line-height: 141%;
	color: #4c4c4c;
	text-align: center;
}

.dot_wrapper {
	display: flex;
	gap: 5px;
	align-items: center;
}

.amount__input-wrapper {
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	margin-left: 60px;
	gap: 10px;
}

.amount__input {
	padding: 10px;
	display: block;
	box-sizing: border-box;
	width: 171px;
	height: 28px;
	outline: none;
	background: #ffffff;
	border: 1px solid #aeafbc;
	border-radius: 5px;

	&::placeholder {
		font-family: Segoe UI;
		font-size: 11px;
		font-weight: 400;
		line-height: 16px;
		padding-left: 20px;
	}
}

.blue__dot {
	content: '';
	width: 5px;
	height: 5px;
	margin-top: 5px;
	border-radius: 50%;
	background-color: #0090ff;
	transform: translateY(-50%);
}



.currency-text__wrapper {
	display: flex;
	gap: 5px;
}

.currency-code,
.currency-text {
	@extend%text-style4;
	color: #0090ff;
}

.date__wrapper {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	gap: 10px
}

.date__text {
	@extend%text-style4;
}

.date__calendar-img {
	width: 20px;
	height: 20px;
}

.date__detail {
	word-break: break-word;
	white-space: pre-line;
	@extend%text-style4;
}

.options__link-wrapper {
	margin: 20px 0px 0px 0px;
}
.options__click-text {
	@extend%text-style4;
}

.options__link-text {
	padding: 0;
	border: none;
	background-color: transparent;
	@extend%text-style4;
	font-weight: 600;
	margin: 0px 0px 0px 4px;
	text-decoration: underline;

	cursor: pointer;
}
.options__button {
	padding: 12px 30px;
	cursor: pointer;
	border: none;
	box-sizing: border-box;
	width: 298px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	background: rgba(0, 144, 255, 1);
	border-radius: 7px;
	transition: background-color 250ms ease, color 250ms ease;
	.options__button-text1,
	.options__button-text2 {
		color: #ffffff;
	}
	&:hover {
		box-shadow: none;
		background: rgba(0, 107, 190, 1);
		// 	.options__button-text1 {
		// 		color: #ffffff;
		// 	}
		// 	.options__button-text2 {
		// 		color: #ffffff;
		// 	}
		// }
		// &:active {
		// 	background: rgba(0, 107, 190, 1);
	}
}

.icon-vector-eyes-left {
	display: inline-block;
	stroke-width: 0;
	stroke: currentColor;
	fill: currentColor;
}
// .options__button:hover .options__button-img {
// 	transform: rotate(180deg);
// 	-webkit-transform: rotate(180deg);
// 	-moz-transform: rotate(180deg);
// 	-ms-transform: rotate(180deg);
// 	-o-transform: rotate(180deg);
// }
.options__button:active .options__button-img {
	transform: scale(1.15);
	// transform: rotate(180deg);
	-webkit-transform: scale(1.15);
	-moz-transform: scale(1.15);
	-ms-transform: scale(1.15);
	-o-transform: scale(1.15);
}
.options__button-img {
	display: flex;
	align-items: center;
	justify-content: center;
	// transition: 0.3s ease-in-out;
	// transform-origin: 50%, 50%;
}

.options__button-text1 {
	width: 61px;
	@extend%text-style4;
	font-weight: 600;
	line-height: 16px;
	cursor: pointer;
}
.options__button-text2 {
	overflow: hidden;

	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 24px;

	color: #656565;
}