@import 'styles/common/variables';

.burgerButton {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 39px;
	height: 33px;

	font-size: 18px;
	line-height: 1.3;
	color: $colors-text-accent;

	background-color: inherit;
	border: none;
	border-radius: 10px;
	outline: none;

	// &:hover {
	//   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.45));
	// }

	// &:focus {
	//   box-shadow: $box-shadow-modal;
	// }

	@media screen and (min-width: $tablet) {
		width: 48px;
		height: 41px;
	}
}

.burgerIcon {
	width: 100%;
	height: 100%;
}
// @import './burger-button';

// .burgerButton {
// 	@extend %burgerButton;

// 	background-color: $colors-white;
// }

// .burgerIcon {
// 	@extend %burgerIcon;
// }
