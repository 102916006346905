@import '../MainPage/Assents/variables.scss';
@import 'styles/common/breakpoints';

$color-text: #4c4c4c;
$animation-x: 100px;
$animation-y: -90px;
$animationSuggest-x: 195px;
$animationSuggest-y: -34px;
$animationWork-x: 335px;
$animationWork-y: -130px;
$marginLeftInCalendar: 225px;
$marginRightInCalendarCheckBox1: 140px;

%text-style-checkbox {
	font-family: 'Segoe UI';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #ffffff;
}

.block_checkbox_work {
	display: flex;
	align-items: center;
	width: 197px;
	position: relative;
	justify-content: flex-start;
	margin: 0 auto;

	@media screen and (max-width: $tablet) {
		margin-bottom: 12px;
		margin-top: 12px;
	}
	@media screen and (min-width: $tablet) {
		justify-content: flex-end;
		width: 145px;
		height: 34px;
		margin-right: 0;
		margin-left: 0;
	}
	@media screen and (min-width: $desktop) {
		margin-right: 65px;
	}
}

@keyframes moveVertical {
	from {
		transform: translate(0);
	}
	to {
		transform: translate($animationWork-x, $animationWork-y);
	}
}

.vertical-block {
	@media screen and (min-width: $desktop) {
		animation: moveVertical 700ms forwards;
		width: 170px;
	}
}

.checkbox_input {
	appearance: none;
	-webkit-appearance: none;
}

.checkbox_label_work {
	@extend%text-style-checkbox;
	cursor: pointer;
	outline: none;
	&::before {
		content: '';
		position: absolute;
		top: 50%;
		right: 0px;
		transform: translateY(-50%);
		background: #edf3f7;
		border: 0.5px solid #000000;
		border-radius: 3px;
		width: 18px;
		height: 18px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		-ms-border-radius: 3px;
		-o-border-radius: 3px;
		transition: border 0.1s linear;
		-webkit-transition: border 0.1s linear;
		-moz-transition: border 0.1s linear;
		-ms-transition: border 0.1s linear;
		-o-transition: border 0.1s linear;
		@media screen and (min-width: $tablet) {
			width: 24px;
			height: 24px;
			right: -32px;
			border-radius: 5px;
		}
	}

	&:after {
		content: '';
		opacity: 0;
		position: absolute;
		top: 50%;
		right: 4.5px;
		transform: translateY(-50%);
		width: 9px;
		height: 9px;
		border-radius: 50%;
		background: #4c4c4c;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		transition: opacity 0.1s linear;
		-webkit-transition: opacity 0.1s linear;
		-moz-transition: opacity 0.1s linear;
		-ms-transition: opacity 0.1s linear;
		-o-transition: opacity 0.1s linear;
		@media screen and (min-width: $tablet) {
			right: -25px;
			width: 10px;
			height: 10px;
		}
	}
}

.animate {
	animation: colorChange 700ms forwards;
	font-weight: 500;
}

@keyframes colorChange {
	from {
		color: $color-text;
	}
	to {
		color: $text-color;
	}
}
.checkbox_input:checked + .checkbox_label_work {
	&:before {
		background: #edf3f7;
		border: 3px solid #4c4c4c;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		-ms-border-radius: 5px;
		-o-border-radius: 5px;
	}
	&:after {
		opacity: 1;
	}
}
